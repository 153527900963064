import { useEffect, useState } from 'react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import { withBoards } from 'imports/job-tracking/api/context/boards.context';

import Flex from '/imports/core/ui/atoms/Flex';
import { getAllBoardJob } from '/imports/job-tracking/api/helper';
import JobCard from '/imports/job-tracking/ui/components/JobCard';
import { useSearchStore } from 'zustand/SearchStore';

const Cards = ({ isSkeleton = false, onClickCard }) => {
  if (isSkeleton) {
    return (
      <StyledFlex wrap="wrap">
        <Title fullWidth isSkeleton>
          <Skeleton />
        </Title>
        <CardWrapper>
          {[...Array(4)].map((k, i) => (
            <JobCard isSkeleton key={i} />
          ))}
        </CardWrapper>
      </StyledFlex>
    );
  }

  const { jobsData } = useSearchStore();
  const {
    state: { boards },
  } = withBoards();

  const [data, setData] = useState({
    today: jobsData?.jobs,
    thisWeek: [],
    thisMonth: [],
  });
  useEffect(() => {
    const today = [];
    const thisWeek = [];
    const thisMonth = [];
    jobsData?.jobs?.map((k) => {
      const date = moment().diff(k.createdAt, 'days');
      if (date < 1) {
        today.push(k);
      } else if (date < 7) {
        thisWeek.push(k);
      } else {
        thisMonth.push(k);
      }
    });
    setData({ today, thisWeek, thisMonth });
  }, [jobsData]);

  const savedData = (card) => {
    const boardData = getAllBoardJob(boards);
    const data = boardData.find((k) => k.esId === card._id);
    return !!data ? data : null;
  };

  const renderEl = (title, data) => {
    return (
      <>
        {/* <Title fullWidth>{title}</Title> */}
        <CardWrapper>
          {data.map((e, i) => (
            <JobCard border={savedData(e)?.colorCode || null} job={e} key={i} onClickCard={onClickCard} searchPage />
          ))}
        </CardWrapper>
      </>
    );
  };
  return (
    <Flex wrap="wrap">
      {data?.today?.length > 0 && renderEl('Today', data.today)}
      {data?.thisWeek?.length > 0 && renderEl('This Week', data.thisWeek)}
      {data?.thisMonth?.length > 0 && renderEl('This Month', data.thisMonth)}
    </Flex>
  );
};

export default Cards;

const StyledFlex = styled(Flex)`
  min-width: 370px;
`;

const CardWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(319.3px, 1fr));
  gap: 24px;
`;

const Title = styled(Flex)`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '22', 'var(--dark-values-black)', '1.45')};
  margin-top: 32px;
  margin-bottom: 24px;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      display: block;
    `}
`;
