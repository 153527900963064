import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import MoreOptionsIcon from '../assets/MoreOptionsIcon';
import nookies from 'nookies';
import nProgress from 'nprogress';
import qs from 'qs';
import styled from 'styled-components';
import { replaceString } from 'imports/core/ui/helpers';

import { DeleteIconStyled } from '/imports/job-tracking/ui/assets/DeleteIcon';
import DropdownInner from '/imports/job-tracking/ui/atoms/DropdownInner';
import DropdownMenuItemWrapper from '/imports/job-tracking/ui/atoms/DropdownMenuItemWrapper';
import DropdownMenuItmes from '/imports/job-tracking/ui/atoms/DropdownMenuItems';
import DropdownOption from '/imports/job-tracking/ui/atoms/DropdownOption';
import Flex from '/imports/core/ui/atoms/Flex';
import { getTotalBoardJobs } from '/imports/job-tracking/api/helper';
import InputField from '/imports/job-tracking/ui/atoms/InputField';
import Logo from '/imports/job-tracking/ui/atoms/LogoImage';
import NotificationDot from '/imports/job-tracking/ui/atoms/NotificationDot';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';
import { Push } from '/components/Link';
import { updateBoard } from '/imports/job-tracking/api/api';
import useIntl from '/imports/core/api/useIntl';

const BoardItem = ({ board, disableAdd, agence, boardsDispatch, deleteBoardFunc }) => {
  const { locale, t } = useIntl();
  const { token } = nookies.get({});
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(board.boardName);
  const actionRef = useRef(null);

  const redirectToBoard = (boardId) => (e) => {
    if (disableAdd || (actionRef.current && actionRef.current.contains(e.target))) return;
    const queryString = {
      template: 'tracking',
      id: boardId,
    };
    Push(`/app?${qs.stringify(queryString)}`, locale, `/app/tracking/${boardId}`);
  };

  const getLogos = () => {
    if (!board.columns) return [];
    let data = getTotalBoardJobs(board).map((k) => k.companyInfo?.logo);
    data = data.filter((k) => k?.length > 1);
    return data;
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleKeyDown = async (e) => {
    if (e.key === 'Enter' && value.trim() != '') {
      nProgress.start();
      const resp = await updateBoard({ boardName: value.trim() }, board.boardId, token);
      boardsDispatch({ type: 'BOARD_RENAME', payload: JSON.parse(resp) });
      nProgress.done();
      setOpen(false);
    }
  };
  const handleClickOutside = (e) => {
    if (actionRef.current && !actionRef.current.contains(e.target)) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (!open) return;
    document.getElementById('input-el').focus();
    setValue(board.boardName);
  }, [open]);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <BoardWrapper direction="column" onClick={redirectToBoard(board.boardId)}>
      <Header fullWidth direction="column">
        <Flex alignItems="center" fullWidth justifyContent="space-between">
          <BoardName>{board.boardName}</BoardName>
          <ActionWrapper
            onClick={() => {
              if (!agence) setOpen((state) => !state);
            }}
            ref={actionRef}
          >
            {!agence ? (
              <MoreOptionWrapper>
                <MoreOptionsIcon vertical={true} />
              </MoreOptionWrapper>
            ) : (
              <RecuriterWrapper>
                <RecruiterImage />
              </RecuriterWrapper>
            )}
            {open && (
              <Dropdown>
                <DropdownMenuItmes>
                  <DropdownInner>
                    <Flex direction="column">
                      <InputField
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onClick={(e) => e.stopPropagation()}
                        id="input-el"
                      />
                    </Flex>
                  </DropdownInner>
                  <DropdownOption fullWidth noMargin withBackground>
                    <DeleteWrap fullWidth onClick={deleteBoardFunc(board.boardId, board.boardName)}>
                      <DeleteIconStyled />
                      {t('jobtrack_job_card_delete_option_text')}
                    </DeleteWrap>
                  </DropdownOption>
                </DropdownMenuItmes>
              </Dropdown>
            )}
          </ActionWrapper>
        </Flex>
        <BoardTrackInfo>
          {replaceString(t('jobtrack_board_tracked_offers_text'), {
            TOTAL_V: board?.total,
          })}
        </BoardTrackInfo>
        <CreatedBy>
          {replaceString(t('jobtrack_board_createdby_text'), {
            CREATED_BY_V: agence ? board.createdBy : t('jobtrack_you_text'),
          })}
        </CreatedBy>
        <LogoWrapper>{getLogos().map((el, i) => i < 5 && <Logo key={i} logo={el} />)}</LogoWrapper>
      </Header>
      <Footer fullWidth alignItems="center">
        {moment(board.createdAt).fromNow()}
        {board.hasPendingTask && (
          <StyledFlex alignItems="center">
            <Bullet />
            <PendingTask>{t('jobtrack_board_pending_tasks_text')}</PendingTask>
          </StyledFlex>
        )}
      </Footer>
    </BoardWrapper>
  );
};

export default BoardItem;

const RecuriterWrapper = styled.div``;

const RecruiterImage = styled.div`
  border-radius: 20px;
  border: solid 1px var(--transparents-opacity-12);
  background-size: contain;
  background-image: url('https://picsum.photos/50/50');
  background-position: center;
  height: 32px;
  width: 32px;
`;
const MoreOptionWrapper = styled.div`
  display: none;
`;

const CreatedBy = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n400)', '1.54')};
`;

const StyledFlex = styled(Flex)`
  margin-left: auto;
`;
const LogoWrapper = styled(Flex)`
  margin-top: 48px;
`;

const Bullet = styled(NotificationDot)`
  margin: 0 4px 0 0 !important;
`;

const PendingTask = styled(Flex)`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--primary-default-b400)', '1.54')};
`;

const BoardTrackInfo = styled.div`
  margin-top: 13px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--dark-values-black)', '1.54')};
`;

const BoardName = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '1.47')};
  ${OneLineCss}
  word-break: break-all;
`;

const Header = styled(Flex)`
  padding: 16px;
`;

const Footer = styled(Flex)`
  padding: 0 16px 16px;
  margin-top: auto;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--mid-values-gray-light)', '1.54')};
`;

const BoardWrapper = styled(Flex)`
  overflow: hidden;
  min-width: 100%;
  max-width: 100%;
  height: 228px;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--light-values-light-extra);
  &:hover {
    box-shadow: 0 4px 16px 0 var(--transparents-opacity-8);
    background-color: var(--light-values-white);
    ${MoreOptionWrapper} {
      display: flex;
    }
  }
  @media (min-width: 768px) {
    min-width: 314px;
    max-width: 314px;
  }
  @media (min-width: 992px) {
    min-width: 201px;
    max-width: 201px;
  }
  @media (min-width: 1200px) {
    min-width: 206px;
    max-width: 206px;
  }
  @media (min-width: 1440px) {
    min-width: 232px;
    max-width: 232px;
  }
`;
const ActionWrapper = styled(Flex)`
  flex-shrink: 0;
  display: flex;
  margin-left: auto;
  position: relative;
  top: 0px;
  bottom: 0;
  left: 0;
`;
const Dropdown = styled(DropdownMenuItemWrapper)`
  top: 18px;
  padding-top: 4px;
  right: -4px;
  display: flex;
  @media (min-width: 992px) {
    right: -2px;
    width: 144px;
  }
  @media (min-width: 1200px) {
    right: -4px;
    width: 200px;
  }
  ${DropdownMenuItmes} {
    display: flex;
  }
`;

const DeleteWrap = styled(Flex)`
  padding: 8px 12px;
  gap: 12px;
`;
