import { useEffect, useState, useRef } from 'react';
import dynamic from 'next/dynamic';
import nProgress from 'nprogress';
import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';
import useTracking from '/imports/core/hooks/useTracking';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import useFeedStore from 'zustand/feedStore';
import { useGeneralStore } from 'zustand/generalStore';
import searchApi from 'imports/job-tracking/api/searchApi';

nProgress.configure({ showSpinner: false });

const EmojiPicker = dynamic(() => import('emoji-picker-react'), {
  ssr: false,
});

const EditableFeedName = ({ feedId }) => {
  const [value, setValue] = useState('');
  const { trackEvent } = useTracking();

  const {
    state: { boardAddModal },
  } = withBoards();

  const ref = useRef(null);
  const emojiRef = useRef(null);
  const { setjobTrackEmojiPicker, jobTrackEmoji, setcloseEmojiPicker } = useGeneralStore();
  const { feeds } = useFeedStore();
  const { useUpdateFeedApi } = searchApi();
  const { mutate } = useUpdateFeedApi();
  const feedIcon = feeds && feeds.find((f) => f.feedId === feedId);

  useEffect(() => {
    if (boardAddModal && jobTrackEmoji) {
      setcloseEmojiPicker();
    }
  }, [boardAddModal]);
  const handleClick = (e) => {
    if (jobTrackEmoji) {
      if (emojiRef.current && !emojiRef.current.contains(e.target)) {
        setcloseEmojiPicker();
      }
    }
  };
  useEffect(() => {
    if (jobTrackEmoji) {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [jobTrackEmoji]);

  useEffect(() => {
    setValue(feedIcon);
  }, [feedIcon]);

  const onChange = (e) => setValue({ ...value, feedName: e.target.value });

  const onSave = async () => {
    if (value.feedName !== feedIcon.feedName) {
      nProgress.start();
      const isFeedName = true;
      mutate({ feedName: value.feedName, id: feedIcon.feedId, isFeedName });
      trackEvent('feed_name_update');
      nProgress.done();
    }
  };

  const emojiClose = (e) => {
    if (jobTrackEmoji) {
      if (e.key === 'Escape') setcloseEmojiPicker();
    }
  };

  useEffect(() => {
    if (jobTrackEmoji) {
      document.addEventListener('keydown', emojiClose);
      return () => {
        document.removeEventListener('keydown', emojiClose);
      };
    }
  }, [jobTrackEmoji]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') ref.current.blur();
  };

  const handleEmoji = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      if (jobTrackEmoji) return setcloseEmojiPicker();
      setjobTrackEmojiPicker(feedIcon.feedId);
    }
  };

  const onEmojiClick = async (event) => {
    nProgress.start();
    const isEmojiPicker = true;
    mutate({ icon: event.emoji, feedName: value.feedName, id: feedIcon.feedId, isEmojiPicker });
    nProgress.done();
  };

  return (
    <Flex alignItems="center" fullWidth>
      {feedIcon?.icon && (
        <EmojiWrapper ref={emojiRef} onClick={handleEmoji} alignItems="center" justifyContent="center">
          {feedIcon.icon}
          {jobTrackEmoji === feedIcon?.feedId && (
            <EmojiPicker onEmojiClick={onEmojiClick} suggestedEmojisMode={false} />
          )}
        </EmojiWrapper>
      )}
      <StyledInput
        type="text"
        onChange={onChange}
        defaultValue={value?.feedName ?? ''}
        onBlur={onSave}
        onKeyDown={handleKeyDown}
        ref={ref}
      />
    </Flex>
  );
};

export default EditableFeedName;

const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  ${OneLineCss}
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '50', 'var(--dark-values-black)', '1.2')};
  letter-spacing: -0.25px;
  flex: 1;
  margin-left: 12px;
`;

const EmojiWrapper = styled(Flex)`
  position: relative;
  cursor: pointer;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  align-items: center;
  .EmojiPickerReact.epr-main {
    position: absolute !important;
    z-index: 999 !important;
    top: 60px !important;
    left: 0px !important;
  }
  .epr-search-container .epr-icn-search {
    top: 67% !important;
  }
  .epr-search,
  .epr-emoji-category-label {
    font-size: 15px !important;
  }
  .EmojiPickerReact {
    --epr-emoji-size: 22px !important;
  }
  .epr-preview,
  .epr-skin-tones,
  .epr-horizontal {
    display: none !important;
  }
  .epr-category-nav {
    padding: 10px !important;
  }
  .epr-header-overlay {
    padding-top: 0px !important;
  }
  &:hover {
    background-color: var(--light-values-light-regular);
    border-radius: 20px;
  }
  .epr-emoji-category-label {
    height: 20px !important;
    font-size: 15px !important;
  }
  .epr-header {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .epr-main {
    height: 350px !important;
  }
  .EmojiPickerReact .epr-search-container input.epr-search {
    ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '1.36')};
  }
  .epr-body {
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: gray;
      border-radius: 3px;
    }
  }
`;
