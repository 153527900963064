import { replaceString } from 'imports/core/ui/helpers';
import { addJobTrackingData, createNewBoard } from 'imports/job-tracking/api/api';
import { stringToNull } from 'imports/job-tracking/api/constants';
import { getRandomEmoji } from 'lib/helpers';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import nProgress from 'nprogress';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import WishlistToaster from '../atoms/WishlistToaster';
import DetailBrief from './details/DetailBrief';
import DetailCompany from './details/DetailCompany';
import DetailLeftSection from './details/DetailLeftSection';
import SkeletonComponent from './details/DetailsSkeleton';
import { savedData } from '/imports/job-tracking/api/helper';

import searchApi from 'imports/job-tracking/api/searchApi';
import useFeedStore from 'zustand/feedStore';
import { useGeneralStore } from 'zustand/generalStore';
import { useSearchStore } from 'zustand/SearchStore';
import useIntl from '/imports/core/api/useIntl';
import useWindowSize from '/imports/core/api/useWindowSize';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import MobileCompanyDetail from '/imports/job-tracking/ui/components/MobileCompnayDetail';

nProgress.configure({ showSpinner: false });

const jobRequiredFiled = [
  'city',
  'column',
  'company',
  'companyInfo',
  'country',
  'esId',
  'importance',
  'isRemote',
  'location',
  'metadata',
  'portal',
  'saved',
  'title',
  'url',
  'zipCode',
  'shift',
  'salary',
];

const CardDetailModal = ({ nextTrigger, isSearchJob = false, onSave, updateFeedDesc, OrderByData }) => {
  const { trackEvent } = useTracking();
  const { token } = nookies.get({});
  const { t, locale } = useIntl();
  const {
    query: { text, loc, id, template, show: tipShow },
    push,
  } = useRouter();
  const {
    state: { boards },
    dispatch: boardDispatch,
  } = withBoards();
  const [scrollHeight, setScrollHeight] = useState(0);
  const [small, setSmall] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedToaster, setSelectedToaster] = useState(null);
  const { width } = useWindowSize();
  const scrollElement = useRef(null);
  const divEl = useRef(null);
  const { setIsMobileCompanyDetail, isMobileCompany, setOpenSignUpModal } = useGeneralStore();
  const { activeCardData, setIndeedJobData, setactiveCard } = useSearchStore();
  const { activeFeeds } = useFeedStore();
  const [loadDescription, setLoadDescription] = useState(false);
  const { useGetIndeedJobDetails } = searchApi();
  const { mutate, isPending } = useGetIndeedJobDetails();

  const onScroll = useCallback(() => {
    const scrollEl = scrollElement.current?.getBoundingClientRect();
    setScrollHeight(scrollEl?.y + 20);
  }, [scrollElement]);

  useEffect(() => {
    window.scrollBy(0, 1);
    if (divEl.current) {
      divEl.current.scrollTop = 0;
    }
  }, [activeFeeds]);

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, [onScroll]);

  const isMobileCompanyDetail = useMemo(() => width < 768, [width]);

  useEffect(() => {
    setIsMobileCompanyDetail(isMobileCompanyDetail);
  }, [isMobileCompanyDetail]);

  const getActiveCardDesctiption = async (data) => {
    if (data?.apiProvider !== 'search-v3') {
      setactiveCard(data);
      return;
    }
    const isActiveCardDetail = true;
    setIndeedJobData('');
    mutate({ jobId: data.id, company: data.company, isActiveCardDetail, jobData: data });
  };

  const onClickCard = (data) => () => {
    setLoadDescription(true);
    getActiveCardDesctiption(data).then(() => {
      setLoadDescription(false);
      trackEvent('job_card_clicked', data.esId);
      let query = {
        template: template,
        ctsId: data.ctsId,
      };
      if (id) {
        query = {
          ...query,
          id: id,
        };
      }
      if (text) query = { ...query, text: text };
      if (loc) query = { ...query, loc: loc };
      if (show) query = { ...query, show: show };
      const routeParams = {
        pathname: '/app',
        query,
      };
      push(routeParams, routeParams, { shallow: true, locale: locale });
      setIsMobileCompanyDetail(width < 768 ? true : false);
    });
  };

  const handleDirectJobAdd = (data, boardId, isApply) => async () => {
    if (!!!token) return setOpenSignUpModal();
    const isNew = boards.length === 0;
    nProgress.start();
    let wishListData = {};
    jobRequiredFiled.map((k) => (wishListData[k] = data[k]));
    wishListData = {
      ...wishListData,
      shift: data?.shift ? data.shift : [],
      tag: data?.tag || data?.tags,
      jobType: data?.contract?.length > 0 ? data.contract : [],
    };
    const obj = stringToNull({
      ...wishListData,
      metadata: { postDate: new Date() },
      url: data.url,
      esId: data.id,
      saved: true,
      track: true,
      column: isApply ? 1 : 0,
      tags: data.tag,
    });
    if (isNew) {
      const boardData = {
        boardName: t('jobtrack_default_board_text'),
        icon: getRandomEmoji(),
      };
      const resp = await createNewBoard(boardData, token);
      boardDispatch({ type: 'ADD_TO_BOARDS_LIST', payload: { ...resp, total: 0 } });
      const response = await addJobTrackingData(obj, resp.boardId, token);
      if (response) {
        boardDispatch({ type: 'ADD_TO_BOARDS_JOB', payload: response });
        trackEvent('board_job_added', { jobId: response.jobId });
      }
      setSelectedToaster({ title: t('jobtrack_default_board_text'), value: resp.boardId });
    } else if (boards.length === 1 || !boardId) {
      const board = boards[boards.length - 1];
      const response = await addJobTrackingData(obj, board.boardId, token);
      if (response) {
        boardDispatch({ type: 'ADD_TO_BOARDS_JOB', payload: response });
        trackEvent('board_job_added', { jobId: response.jobId });
      }
      setSelectedToaster({ title: board.boardName, value: board.boardId });
    } else if (boardId) {
      const response = await addJobTrackingData(obj, boardId, token);
      if (response) {
        boardDispatch({ type: 'ADD_TO_BOARDS_JOB', payload: response });
        trackEvent('board_job_added', { jobId: response.jobId });
      }
      setSelectedToaster({
        title: boards.find((board) => board.boardId === boardId)?.boardName || null,
        value: boardId,
      });
    }
    nProgress.done();
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  const handleScroll = (e) => {
    let height = 0;
    const currentData = savedData(boards, activeCardData);
    if (!currentData) {
      height = 180;
    } else {
      if (currentData.position[0] > 0) {
        height = 550;
      } else {
        height = 250;
      }
    }
    if (small && e.target.scrollTop < height) setSmall(false);
    if (!small && e.target.scrollTop > height) setSmall(true);
  };

  if (isMobileCompany)
    return (
      <MobileCompanyDetail
        data={!!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData}
        isSearchJob={isSearchJob}
        isBoarded={!!savedData(boards, activeCardData)}
        handleDirectJobAdd={handleDirectJobAdd}
        toasterLink={selectedToaster?.value}
        toasterTitle={selectedToaster?.title}
        show={show}
      />
    );

  if (!activeCardData) return null;
  return (
    <Flex direction="column" fullWidth>
      <Wrapper>
        <DetailLeftSection
          next={nextTrigger}
          onClickCard={onClickCard}
          onSave={onSave}
          updateFeedDesc={updateFeedDesc}
          OrderByData={OrderByData}
          handleDirectJobAdd={handleDirectJobAdd}
        />
        <RightWrapper direction="column" fullWidth ref={scrollElement} scrollHeight={scrollHeight}>
          <RightPageLayout ref={divEl} onScroll={handleScroll}>
            {loadDescription === false && !isPending ? (
              <Fragment>
                <DetailCompany
                  data={!!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData}
                  handleDirectJobAdd={handleDirectJobAdd}
                  isBoarded={!!savedData(boards, activeCardData)}
                  isSearchJob={isSearchJob}
                  small={small}
                />
                <DetailBrief
                  data={!!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData}
                  isBoarded={!!savedData(boards, activeCardData)}
                />
              </Fragment>
            ) : (
              <SkeletonComponent />
            )}
          </RightPageLayout>
        </RightWrapper>
      </Wrapper>
      <WishlistToaster
        text={replaceString(t('jobtrack_wishlist_toaster_text'), {
          TITLE_V: selectedToaster?.title,
        })}
        link={selectedToaster?.value}
        show={show}
      />
    </Flex>
  );
};
export default CardDetailModal;

const RightPageLayout = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 31, 0.12);
    border-radius: 2px;
  }
`;

const RightWrapper = styled(Flex)`
  background-color: #fff;
  border: 1px solid var(--grayscale-n300);
  border-radius: 8px;
  display: flex;
  top: 16px;
  position: sticky;
  flex-grow: 1;
  border-top-right-radius: 12px;
  overflow: hidden;
  height: fit-content;
  max-height: ${({ scrollHeight }) => `calc(100vh - ${scrollHeight}px)`};
  display: none;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const Wrapper = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  gap: 8px;
  @media (min-width: 992px) {
    gap: 16px;
  }
  @media (min-width: 1200px) {
    gap: 24px;
  }
`;
