import { memo, useEffect } from 'react';
import qs from 'qs';
import { useQuery, useMutation } from 'react-apollo';
import nookies from 'nookies';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import Container from '/imports/job-tracking/ui/atoms/Container';
import CoverLetterList from '/imports/job-tracking/ui/components/CoverLetterList';
import { CREATE_COVER_LETTER } from '/imports/dashboard/api/apollo/client/mutations';
import Flex from '/imports/core/ui/atoms/Flex';
import { isPaidUser } from '/lib/helpers';
import { LIST_COVER_LETTERS, LIST_RESUMES } from '/imports/dashboard/api/apollo/client/queries';
import { Push } from '/components/Link';
import ResumeList from '/imports/job-tracking/ui/components/ResumeList';
import ResumeListTitle from '/imports/job-tracking/ui/atoms/ResumeListTitle';
import ErrorOccured from '/imports/core/ui/components/ErrorOccured';
import { useAccount } from '/imports/core/api/accounts/accountContext';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import SavedJobSkeleton from '/imports/job-tracking/ui/skeleton/SavedJobSkeleton';

const Dashboard = (props) => {
  const { asPath } = useRouter();
  const { locale, t } = useIntl();
  const { currentUser } = useAccount();
  const { trackEvent } = useTracking();
  const { data, error, loading, refetch } = useQuery(LIST_RESUMES);
  const { agent_client_id } = nookies.get({});
  const isAgent = (currentUser && currentUser?.role === 'agent') || !!agent_client_id;

  const [createCoverLetterMutation] = useMutation(CREATE_COVER_LETTER, {
    context: {
      client: 'coverLetter',
    },
    variables: {
      settings: {
        template: 'budapest',
        color: 'black',
        language: locale,
      },
    },
    refetchQueries: [
      {
        query: LIST_COVER_LETTERS,
        context: { client: 'coverLetter' },
      },
    ],
  });

  useEffect(() => {
    trackEvent('dashboard_view');
  }, []);

  const createResume = () => {
    Push('/resume/create', locale);
  };

  const goToCheckout = () => {
    const queryString = {
      from: asPath,
    };
    Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout');
  };

  const createCoverLetter = async () => {
    const { role = {} } = currentUser;
    const { agent_client_id } = nookies.get({});
    if (!isPaidUser(role) && !agent_client_id) return goToCheckout();
    const { data } = await createCoverLetterMutation();
    if (data.create) {
      const queryString = {
        coverLetterId: data.create.id,
        view: 'edit',
        from: asPath,
      };
      Push(`/cover-letter?${qs.stringify(queryString)}`, locale, `/cover-letter/${data.create.id}/finish`);
    }
  };
  if (error) return <ErrorOccured error={error} />;
  if (typeof window === 'undefined') return null;
  return (
    <>
      <Inner fullWidth>
        <Wrapper fullWidth>
          <ResumeListTitle title={t('onboarding.loading.resume')} />
          {loading ? <SavedJobSkeleton /> : <ResumeList createNew={createResume} data={data} refetch={refetch} />}
        </Wrapper>
      </Inner>
      {!isAgent && (
        <Inner showBorder fullWidth>
          <Wrapper fullWidth>
            <ResumeListTitle margin title={t('home_cover_letters')} />
            <CoverLetterList createNew={createCoverLetter} />
          </Wrapper>
        </Inner>
      )}
    </>
  );
};

export default memo(Dashboard);

const Wrapper = styled(Container)`
  flex-direction: column;
`;

const Inner = styled(Flex)`
  padding-top: 32px;
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-top: 1px solid #e2e8f1;
    `}
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    padding-top: 48px;
  }
  @media (min-width: 992px) {
    padding-left: 25px;
  }
`;
