import styled, { css } from 'styled-components';
const Logo = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: var(--light-values-white);
  border: solid 1px rgba(29, 44, 67, 0.12);
  margin-right: 8px;
  flex-shrink: 0;
  text-align: center;
  justify-content: center;
  color: var(--light-values-white);
  ${({ logo }) =>
    logo &&
    css`
      background-image: url(${logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border-color: transparent;
    `}
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-right: 0;
    `}
  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: 50%;
    `}
  ${({ background }) =>
    background &&
    css`
      background-color: ${background};
    `}
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
  &:last-child {
    margin-right: 0;
  }
`;

export default Logo;
