import { useEffect, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';
import DropdownAutocomplete from 'imports/core/ui/atoms/DropdownAutocomplete';
import { getPromptNames, getPromptById, updatePrompt, addPrompt, getModelList } from 'imports/job-tracking/api/api';

const PromptLeftSide = ({ executePrompt }) => {
  const [promptNames, setPromptNames] = useState([]);
  const [promptNamesOption, setPromptNamesOption] = useState([]);
  const [selectedPromptOption, setSelectedPromptOption] = useState({ promptName: '', _id: '' });
  const [currentPromptData, setCurrentPromptData] = useState(null);
  const [savingPrompt, setSavingPrompt] = useState(false);
  const [modelList, setModelList] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [withoutSaveEnabled, setWithoutSaveEnabled] = useState(false);

  useEffect(() => {
    const fetchPromptData = async (promptId) => {
      const response = await getPromptById(promptId);
      if (response.data) {
        setCurrentPromptData(response.data);
        document.querySelector('#prompt-textarea').value = response.data?.promptContent || '';
      }
    };
    if (selectedPromptOption?._id) {
      fetchPromptData(selectedPromptOption?._id);
    }
  }, [selectedPromptOption]);

  const fetchNamesData = async () => {
    const response = await getPromptNames();
    if (response?.data?.length > 0) {
      setPromptNames(response.data);
      setPromptNamesOption(response.data.map((prompt) => ({ title: prompt.promptName, value: prompt })));
    }
  };
  const onPromptSelect = (option) => {
    if (option.value && option.description == undefined) {
      setSelectedPromptOption(option.value);
      document.querySelector('#prompt-name-input').value = option.value?.promptName || '';
    }
  };
  const fetchModelList = async () => {
    const response = await getModelList();
    if (response?.data?.length > 0) {
      setModelList(response.data);
      // setPromptNamesOption(response.data.map((prompt) => ({ title: prompt.promptName, value: prompt })));
    }
  };
  const onModelSelect = (option) => {
    if (option.value && option.description == undefined) {
      setSelectedModel(option.value);
    }
  };

  const handleExecudePrompt = async () => {
    const data = {};
    if (withoutSaveEnabled) {
      data.promptContent = document.querySelector('#prompt-textarea')?.value;
      data.model = selectedModel;
    } else {
      data.promptId = selectedPromptOption._id;
    }
    executePrompt(data);
  };

  useEffect(() => {
    fetchNamesData();
    fetchModelList();
  }, []);

  const handleSavePrompt = async () => {
    try {
      setSavingPrompt(true);
      const updatedPromptName = document.querySelector('#prompt-name-input')?.value?.trim();
      const updatedContent = document.querySelector('#prompt-textarea')?.value;
      if (currentPromptData?.promptName != updatedPromptName) {
        const payload = {
          promptName: updatedPromptName,
          promptContent: updatedContent,
          aiModel: currentPromptData?.aiModel || 'gpt-4o',
        };
        const resp = await addPrompt(payload);
        fetchNamesData();
      } else {
        const payload = {
          promptName: currentPromptData.promptName,
          promptContent: updatedContent,
          aiModel: currentPromptData.aiModel,
          promptId: currentPromptData._id,
          createdAt: currentPromptData.createdAt,
        };
        const resp = await updatePrompt(payload);
      }
      setSavingPrompt(false);
    } catch (e) {
      setSavingPrompt(false);
      console.error(e);
    }
  };
  return (
    <Wrapper $fullWidth $direction="column">
      <Header $fullWidth>
        <Title>Prompt Testing Tool</Title>
        <DropdownWrapper $direction="column">
          <DropdownAutocomplete
            options={promptNamesOption}
            onChange={onPromptSelect}
            placeholder="Select Prompt"
            noSearch
          />
          <StyledFlex alignItems="center">
            <StypedCheckbox
              type="checkbox"
              checked={withoutSaveEnabled}
              onClick={() => setWithoutSaveEnabled((prev) => !prev)}
            />
            <Text style={{ color: '#46466b' }} onClick={() => setWithoutSaveEnabled((prev) => !prev)}>
              Execute prompt without saving
            </Text>
          </StyledFlex>
          {withoutSaveEnabled && (
            <DropdownAutocomplete
              options={modelList.map((model) => ({ title: model, value: model }))}
              onChange={onModelSelect}
              placeholder="Select Model"
              noSearch
            />
          )}
        </DropdownWrapper>
      </Header>
      <PromptInputWrapper $fullWidth $direction="column">
        <ParametersWrapper $fullWidth>
          <span>Prompt</span>
          <Input id="prompt-name-input" />
          <Button onClick={handleSavePrompt} disabled={savingPrompt}>
            Save Version
          </Button>
          <Button $send onClick={() => handleExecudePrompt()}>
            Send Test
          </Button>
        </ParametersWrapper>
        <PromptInput placeholder="Input prompt" id="prompt-textarea" />
      </PromptInputWrapper>
    </Wrapper>
  );
};

export default PromptLeftSide;

const StyledFlex = styled(Flex)`
  gap: 8px;
`;

const StypedCheckbox = styled.input`
  cursor: pointer;
`;

const Text = styled.span`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 13px;
  line-height: normal;
  color: rgb(113, 113, 166);
  cursor: pointer;
`;

const DropdownWrapper = styled(Flex)`
  width: 40%;
  margin-left: auto;
  gap: 10px;
`;
const Button = styled.button`
  padding: 8px 12px;
  background-color: #f16262;

  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e72525;
  }

  ${({ $send }) =>
    $send &&
    css`
      background-color: #007bff;
      margin-left: auto;
      &:hover {
        background-color: #0056b3;
      }
    `}

  &:disabled {
    pointer-events: none;
    background-color: grey;
    width: 129.3px;
    display: flex;
    justify-content: center;
  }
`;

const Input = styled.input`
  font-size: 14px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 3px;
  box-shadow: none;
  width: 200px;
  border: solid 1px black;
  background-color: var(--light-values-white);

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray.light};
  }

  &:focus {
    border: solid 2px #1688fe;
  }

  ${(p) => p.theme.max('xs')`
    background: #fff;
    border-radius: 3px;
    border: solid 2px #e6e6ff;
    padding: 13px 16px 9px 33px;
  `};
`;

const PromptInput = styled.textarea`
  ${inputStyle}
  ${overrideInputStyleMixin}
  flex: 1;
  width: 100%;
  border: 1px black solid;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;

const ParametersWrapper = styled(Flex)`
  gap: 12px;
`;

const Wrapper = styled(Flex)`
  gap: 12px;
  height: 100%;
`;

const PromptInputWrapper = styled(Flex)`
  background: #e5e4e4;
  border: 1px black solid;
  flex-grow: 1;
  gap: 24px;
  padding: 16px;
`;

const Header = styled(Flex)`
  gap: 16px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
`;
