import { useState } from 'react';
import styled, { css } from 'styled-components';

import Flex from 'imports/core/ui/atoms/Flex';
import ResumeItem from '/imports/job-tracking/ui/components/ResumeItem';
import SkeletonJobs from '/imports/job-tracking/ui/skeleton/SkeletonJobs';

const PodioJobItem = ({
  data,
  originalResume,
  job,
  activeJob,
  handleItemClick,
  handleViewJob,
  handleMarkApplied,
  markAppliedStatus,
}) => {
  const [score, setScore] = useState();
  const handleScore = (params) => {
    setScore((prevScore) => ({
      before:
        params?.before !== undefined && params?.before !== null ? Math.floor(params.before) : prevScore?.before || 0, // Use the latest state
      after: params?.after !== undefined && params?.after !== null ? Math.floor(params.after) : prevScore?.after || 0, // Use the latest state
    }));
  };
  return (
    <Wrapper key={job.id} fullWidth direction="column" $noMarginTop>
      <Flex
        fullWidth
        justifyContent="space-between"
        alignItems="center"
        style={{ cursor: 'pointer', position: 'relative' }}
        onClick={() => handleItemClick(job.id)}
      >
        <Flex $direction="column">
          {job?.searchName && <Label>{job?.searchName}</Label>}
          <Title $open={'' === job.id} $withCursor>
            {job?.title}
          </Title>
          <Subtitle>{job?.companyName}</Subtitle>
          <Subtitle>{job?.location}</Subtitle>
          <Flex $direction="row" style={{ gap: 6 }}>
            <Badge color={job?.status === 'Application Sent' ? 'success' : 'gray.light'}>
              {job?.status === 'Application Sent' ? 'Applied' : 'Saved'}
            </Badge>
            {job?.status !== 'Application Sent' && (
              <Badge color={'gray.light'}>
                {(job?.resumeDetails?.length === 0 && 'Adaptation in process') || 'Ready to Apply'}
              </Badge>
            )}
          </Flex>
          {job?.resumeDetails?.length > 0 && (
            <Flex $direction="row" style={{ gap: 8, marginTop: 18 }}>
              <Score dangerouslySetInnerHTML={{ __html: `Score Before: <span>${score?.before || 0}%</span>` }} />
              <Score dangerouslySetInnerHTML={{ __html: `Score After: <span>${score?.after || 0}%</span>` }} />
            </Flex>
          )}
        </Flex>
        <Flex $direction="row" style={{ gap: 8 }}>
          <Button size={'small'} onClick={() => handleViewJob(job.jobLink, job)}>
            View job
          </Button>
          {job.status !== 'Application Sent' && (
            <Button
              $disabled={markAppliedStatus}
              size={'small'}
              onClick={() => handleMarkApplied(job)}
              color={'success'}
            >
              Mark applied
            </Button>
          )}
        </Flex>
      </Flex>
      <Accordion open={activeJob === job.id}>
        <Flex fullWidth direction="column">
          <ItemsGrid>
            {(data?.listResumes?.length > 0 &&
              job?.resumeDetails?.map((resume) => {
                const resumeDetails = data?.listResumes?.find((r) => r.id === resume.resumeId);
                if (!resumeDetails) return null;
                return (
                  <ResumeItem
                    handleScore={handleScore}
                    originalResume={originalResume}
                    key={resumeDetails.id}
                    job={job}
                    resume={resumeDetails}
                  />
                );
              })) || <SkeletonJobs />}
          </ItemsGrid>
        </Flex>
      </Accordion>
    </Wrapper>
  );
};

export default PodioJobItem;

const Accordion = ({ children, open }) => {
  return (
    <AccordionBody $open={open} direction="column" $fullwidth>
      {children}
    </AccordionBody>
  );
};

const Badge = styled.span`
  color: ${(p) => (p.textColor && p.textColor) || `#fff`};
  padding: 4px 8px;
  margin: 0px;
  font-size: 11px;
  border-radius: 8px;
  color: ${(p) => (p.textColor && p.textColor) || `#fff`};
  border: 1px solid
    ${(p) =>
      p.borderColor?.includes('var(--')
        ? p.borderColor // Use the CSS variable directly
        : p.theme.colors[p.borderColor?.split('.')[0]]?.[p.borderColor?.split('.')[1]] ||
          p.theme.colors[p.color || 'primary']};
  background-color: ${(p) =>
    p.color?.includes('var(--')
      ? p.color // Use the CSS variable directly
      : p.theme.colors[p.color?.split('.')[0]]?.[p.color?.split('.')[1]] || p.theme.colors[p.color || 'primary']};
`;

const Button = styled.button`
  color: ${(p) => (p.textColor && p.textColor) || `#fff`};
  background-color: ${({ theme, color }) =>
    color?.includes('var(--')
      ? color // Use the CSS variable directly
      : theme.colors[color?.split('.')[0]]?.[color?.split('.')[1]] || theme.colors[color || 'primary']};
  border: 1px solid
    ${({ theme, borderColor, color }) =>
      borderColor?.includes('var(--')
        ? borderColor // Use the CSS variable directly
        : borderColor
          ? theme.colors[borderColor?.split('.')[0]]?.[borderColor?.split('.')[1]] || theme.colors[borderColor]
          : theme.colors[color || 'primary']};
  padding: ${({ size }) => (size === 'small' ? '8px 12px' : '10px 20px')};
  font-size: ${({ size }) => (size === 'small' ? '0.875rem' : '1rem')};
  border-radius: 5px;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: progress;
      pointer-events: none;
      opacity: 0.4;
    `}
  &:hover {
    opacity: 0.9;
  }
`;

const Subtitle = styled.p`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 12px;
  line-height: 12px;
  margin: 0px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  margin-bottom: 6px;
  line-height: 24px;
  ${({ $open }) =>
    $open &&
    css`
      padding-bottom: 24px;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
  ${({ $jobTemplate }) =>
    $jobTemplate &&
    css`
      color: #019ade;
    `}
  ${({ $diffHover }) =>
    $diffHover &&
    css`
      &:hover {
        color: #019ade;
      }
    `}
  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      color: var(--grayscale-n400);
    `}
  ${({ $big }) =>
    $big &&
    css`
      font-size: 24px;
    `}
`;

const Wrapper = styled(Flex)`
  background-color: var(--light-values-white);
  border: 1px solid var(--neutral-values-gray-light);
  padding: 24px 24px;
  margin: 30px 0;
  border-radius: 8px;
  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      margin-top: 0;
    `}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
      padding: 0;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
`;

const AccordionBody = styled(Flex)`
  overflow-x: auto;
  margin-top: 8px;
  width: 100%;
  max-height: ${({ $open }) => ($open ? '2000px' : '0')};
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease,
    padding 0.3s ease,
    border-top 0.3s ease;
  gap: 24px;
  padding: ${({ $open }) => ($open ? '24px 0' : '0')};
  border-top: ${({ $open }) => ($open ? '1px solid var(--grayscale-n200)' : 'none')};
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
`;

const ItemsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
  overflow-y: auto;
  flex-wrap: noWrap;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

const Score = styled.div`
  color: var(--dark-values-black);
  font-size: 14px;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  & > span {
    font-size: 18px;
  }
`;

const Label = styled.div`
  border: 1px solid var(--neutral-values-gray-light);
  border-radius: 6px;
  position: absolute;
  top: -36px;
  background: white;
  padding: 2px 12px;
`;
