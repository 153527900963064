import styled, { css } from 'styled-components';

import Input from '/imports/core/ui/atoms/InputControlled';

const InputField = styled(Input)`
  border-radius: 8px;
  border: 1px solid var(--light-values-light-regular);
  color: var(--dark-values-black);
  background-color: var(--light-values-light-extra);
  padding: 8px 11px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '1.47')};
  &::placeholder {
    ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--grayscale-n400)', '1.47')};
  }
  &:hover {
    border-color: var(--mid-values-gray-light);
    background-color: var(--light-values-white);
  }
  &:focus {
    border: solid 2px #428eff;
    background-color: var(--light-values-white);
    padding: 7px 10px;
  }
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 216px;
    `}

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding: 8px 11px 8px 40px;
      &:focus {
        padding: 8px 10px 8px 39px;
      }
    `}
    ${({ dateTimePicker }) =>
    dateTimePicker &&
    css`
      width: 209px;
    `}
  ${({ ScheduleJob }) =>
    ScheduleJob &&
    css`
      width: 242px;
    `}
  ${({ isSignUp }) =>
    isSignUp &&
    css`
      width: 100%;
    `}
`;

export default InputField;
