import { useEffect } from 'react';
import styled, { css } from 'styled-components';

import BoardList from '/imports/job-tracking/ui/components/BoardList';
import Container from '/imports/job-tracking/ui/atoms/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import ResumeListTitle from '/imports/job-tracking/ui/atoms/ResumeListTitle';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';

const JobTrackingDashboard = () => {
  const { t } = useIntl();
  const {
    state: { boards },
  } = withBoards();
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('trackboard_view', {
      totalBoard: boards.length,
    });
  }, []);

  return (
    <Wrapper direction="column" fullWidth>
      <Inner direction="column" fullWidth>
        <ResumeListTitle title={t('jobtrack_board_head_text')} subTitle={t('jobtrack_board_subtitle')} />
        <BoardList boards={boards} />
      </Inner>
    </Wrapper>
  );
};

export default JobTrackingDashboard;
const Wrapper = styled(Container)`
  width: 100%;
  margin-top: 32px;
  @media (min-width: 768px) {
    margin-top: 48px;
  }
`;

const Inner = styled(Flex)`
  @media (min-width: 992px) and (max-width: 1110px) {
    padding-left: 24px;
  }
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-top: 1px solid #e2e8f1;
    `}
`;
