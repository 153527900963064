import styled, { css } from 'styled-components';

const DropdownInner = styled.div`
  padding: 8px;

  ${({ isJob }) =>
    isJob &&
    css`
      max-height: 255px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
      &:hover::-webkit-scrollbar-thumb {
        background: rgb(229, 228, 234);
        border-radius: 3px;
      }
    `}
  ${({ searchPageDropDown }) =>
    searchPageDropDown &&
    css`
      display: flex;
      flex-direction: column;
      gap: 4px;
    `}
`;

export default DropdownInner;
