import React from 'react';
import qs from 'qs';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import Flex from '/imports/core/ui/atoms/Flex';
import Link from '/components/Link';
import useIntl from '/imports/core/api/useIntl';

const WishlistToaster = ({ text, link, show }) => {
  const queryString = {
    template: 'tracking',
    id: link,
  };
  const { t } = useIntl();
  return (
    <CSSTransition appear in={show} unmountOnExit classNames="toast" timeout={500}>
      <ToastWrapper>
        <Toast alignItems="center" justifyContent="space-between">
          <ToastText>{text}</ToastText>
          <Link href={`/app?${qs.stringify(queryString)}`} as={`/app/tracking/${link}`}>
            <SeeNowBtn>{t('jobtrack_toaster_see_now_text')}</SeeNowBtn>
          </Link>
        </Toast>
      </ToastWrapper>
    </CSSTransition>
  );
};

export default WishlistToaster;

const SeeNowBtn = styled.button`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 1.47;
  color: #1688fe;
  margin-left: 15px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const ToastWrapper = styled.div`
  &.toast-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.toast-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition:
      opacity 500ms,
      transform 500ms;
  }
  &.toast-exit {
    opacity: 1;
  }
  &.toast-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition:
      opacity 500ms,
      transform 500ms;
  }
  position: fixed;
  width: 100%;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 99999;
`;

const Toast = styled(Flex)`
  display: flex;
  align-items: center;
  padding: 20px;
  min-height: 62px;
  max-width: 90%;
  border-radius: 8px;
  box-shadow: 0 12px 48px 0 rgba(20, 20, 31, 0.24);
  user-select: none;
  z-index: 9999;
  color: var(--light-values-white);
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  width: max-content;
  margin: 0 auto;
  background-color: var(--dark-values-dark-extra);
`;

const ToastText = styled.div``;
