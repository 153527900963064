import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';

import AddNewResume from '/imports/job-tracking/ui/components/AddNewResume';
import CoverLetterItem from '/imports/job-tracking/ui/components/CoverLetterItem';
import ErrorOccured from '/imports/core/ui/components/ErrorOccured';
import JobTrackListsSkeleton from '/imports/job-tracking/ui/skeleton/JobTrackListsSkeleton';
import { LIST_COVER_LETTERS } from '/imports/dashboard/api/apollo/client/queries';
import useTracking from '/imports/core/hooks/useTracking';

const CoverLettersList = ({ createNew }) => {
  const { data, error, loading } = useQuery(LIST_COVER_LETTERS, {
    context: { client: 'coverLetter' },
  });
  const loadingComp = <JobTrackListsSkeleton />;
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent('Jobtrack_Initiated');
    trackEvent('dashboard_cover_letter_view');
  }, []);

  if (error) return <ErrorOccured error={error} />;

  if (loading) return loadingComp;

  const JobTrackAddCoverLetter = () => (
    <ItemsGrid data-testid="cover-letter-item-grid">
      <AddNewResume createNew={createNew} />
    </ItemsGrid>
  );

  if (!data.list.length) return JobTrackAddCoverLetter();

  const list = data.list.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <ItemsGrid data-testid="cover-letter-item-grid">
      <AddNewResume createNew={createNew} />
      {list.map((coverLetter) => (
        <CoverLetterItem key={coverLetter.id} coverLetter={coverLetter} />
      ))}
    </ItemsGrid>
  );
};

CoverLettersList.propTypes = {
  createNew: PropTypes.func,
};

CoverLettersList.displayName = 'CoverLettersList';

const ItemsGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
  margin-top: 32px;
  padding-bottom: 64px;
  overflow-y: auto;
  flex-wrap: noWrap;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export default CoverLettersList;
