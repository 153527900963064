import { replaceString } from 'imports/core/ui/helpers';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';
import nookies from 'nookies';
import nProgress from 'nprogress';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import useFeedStore from 'zustand/feedStore';
import { useGeneralStore } from 'zustand/generalStore';
import { useSearchStore } from 'zustand/SearchStore';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';
import { deleteJobTrackingData } from '/imports/job-tracking/api/api';
import { withConfirm } from '/imports/job-tracking/api/confirm';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import { savedData } from '/imports/job-tracking/api/helper';
import FavIcon from '/imports/job-tracking/ui/assets/FavIcon';
import VirtualizedInfinteScroll from '/imports/job-tracking/ui/components/details/VirtualizedInfinteScroll';
import JobCard from '/imports/job-tracking/ui/components/JobCard.js';
import { getRandomEmoji, renderColumnName } from '/lib/helpers';

const DetailLeftSection = ({
  isBoard = false,
  loading = false,
  next,
  onClickCard,
  onSave,
  updateFeedDesc,
  boardColumnName,
  isMobileCompanyDetail,
  confirm,
  onClose,
  handleDirectJobAdd,
}) => {
  const { t } = useIntl();
  const {
    state: { boards },
    dispatch,
  } = withBoards();
  const {
    query: { template, text, loc },
  } = useRouter();
  const { trackEvent } = useTracking();
  const {
    searchTextData = '',
    cityTextData = '',
    savedFeedNameData,
    jobsData,
    setPosition,
    filter,
    activeCardData,
  } = useSearchStore();
  const { activeFeeds } = useFeedStore();
  const { token } = nookies.get({});
  const [searchName, setSearchName] = useState('');
  const openRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const isPastSearch = useMemo(() => {
    return activeFeeds?.bySearch === true;
  }, [activeFeeds]);
  const scrollDiv = useRef(null);
  const { setOpenSignUpModal } = useGeneralStore();

  const columnNameLabel = (position) => {
    return renderColumnName(boardColumnName[position], t);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    renderSearchName();
    ShowUpdateSearch();
  }, [renderSearchName, ShowUpdateSearch]);

  const renderSearchName = useCallback(() => {
    let titleText;
    if (cityTextData && searchTextData)
      titleText = replaceString(t('jobtrack_search_result_head_text4'), {
        SEARCH_TEXT_V: searchTextData,
        CITY_TEXT_V: cityTextData,
      });
    else if ((!searchTextData && cityTextData) || text)
      titleText = `${t('jobtrack_search_result_head_text1')} ${cityTextData || text}`;
    else if ((!cityTextData && searchTextData) || text) titleText = searchTextData || text;
    else if (!cityTextData && !searchTextData) titleText = t('jobtrack_search_result_head_text1');

    let newName;

    if (isPastSearch || template === 'feeds') {
      newName = titleText;
    } else if (savedFeedNameData && searchTextData === '' && cityTextData === '' && !loc && !text) {
      newName = replaceString(t('jobtrack_search_result_head_text2'), { FEED_NAME_V: savedFeedNameData });
    } else if ((searchTextData || text) && !cityTextData) {
      newName = searchTextData || text;
    } else if (!searchTextData && (cityTextData || loc)) {
      newName = replaceString(t('jobtrack_search_result_head_text3'), { CITY_TEXT_V: cityTextData || loc });
    } else if (searchTextData && cityTextData) {
      newName = replaceString(t('jobtrack_search_result_head_text4'), {
        SEARCH_TEXT_V: searchTextData,
        CITY_TEXT_V: cityTextData,
      });
    } else {
      newName = t('jobtrack_search_result_head_text1');
    }
    setSearchName(newName);
  }, [cityTextData, searchTextData, text, isPastSearch, template, savedFeedNameData, loc, t]);

  const ShowUpdateSearch = useCallback(() => {
    if (isPastSearch) return setShow(true);
    if (template === 'search') {
      return setShow(true);
    }
    if (activeFeeds) {
      const { searchText: feedSearchText = '', where = '' } = activeFeeds;
      const obj1 = {
        searchText: feedSearchText,
        where,
      };
      const obj2 = {
        searchText: searchTextData || text ? searchTextData || text : null,
        where: cityTextData || loc ? cityTextData || loc : null,
      };
      const isMatch = !isEqual(obj1, obj2);
      setShow(isMatch);
    }
  }, [isPastSearch, template, activeFeeds, searchTextData, text, cityTextData, loc, isEqual]);

  const handleScroll = () => {
    const scroll = Math.abs(scrollDiv.current?.getBoundingClientRect().top - scrollDiv.current?.offsetTop);
    setPosition(scroll);
  };

  const handleSave = () => {
    if (!!!token) return setOpenSignUpModal();
    if (activeFeeds && !isPastSearch) return setOpen(!open);
    const savefeedDataObj = {
      searchText: searchTextData ? searchTextData : '',
      where: cityTextData ? cityTextData : '',
      icon: getRandomEmoji(),
      bySearch: false,
    };
    if (isPastSearch) return updateFeedDesc(false, true);
    if (activeFeeds && !isPastSearch) return onSave(savefeedDataObj);
    return onSave();
  };

  const handleExistingSearch = () => {
    const savefeedDataObj = {
      searchText: searchTextData ? searchTextData : '',
      where: cityTextData ? cityTextData : '',
      icon: getRandomEmoji(),
      bySearch: false,
    };
    onSave(savefeedDataObj);
  };

  const handleNewSaveSearch = () => updateFeedDesc(true);

  const onClickDeleteCard = (data) => {
    dispatch({
      type: 'BOARD_COLUMN_JOB_DELETE',
      payload: {
        boardId: data.boardId,
        data,
      },
    });
    onClose();
  };

  const handleDelete = async (data) => {
    nProgress.start();
    const response = await deleteJobTrackingData(data.jobId, data.boardId, token);
    if (response) {
      trackEvent('job_deleted');
      onClickDeleteCard(data);
      nProgress.done();
    }
  };

  const handleDeleteConfirm = (data) => (e) => {
    e.stopPropagation();
    trackEvent('delete_job_modal');
    confirm({
      title: t('jobtrack_job_card_remove_option_text'),
      text: t('jobtrack_remove_modal_saved_job_text'),
      confirmText: t('jobtrack_remove_modal_remove_text'),
      enableLoaders: true,
      onConfirm() {
        handleDelete(data);
      },
    });
  };

  return (
    <Wrapper id="scrollableDiv" isBoard={isBoard} isMobileCompanyDetail={isMobileCompanyDetail} ref={scrollDiv}>
      <StyledFlex direction="column">
        {isBoard ? (
          <SectionWrapper direction="column" fullWidth>
            {jobsData.map((d, i) => (
              <Fragment key={i}>
                {d.position[1] === 0 && (
                  <ColumnName alignItems="center" fullWidth first={isBoard && i === 0}>
                    {columnNameLabel(d.position[0])}
                  </ColumnName>
                )}
                <JobCard
                  job={d}
                  key={i}
                  id={d.jobId}
                  onClickCard={onClickCard}
                  isPlain
                  selected={d.title === activeCardData.title}
                  border={d?.colorCode}
                  searchPage
                  isSaved={!!savedData(boards, d)}
                  handleDirectJobAdd={handleDirectJobAdd}
                />
              </Fragment>
            ))}
          </SectionWrapper>
        ) : (
          <SectionWrapper direction="column" fullWidth>
            {loading ? (
              <>
                {[...Array(15)].map((k, i) => (
                  <JobCard isSkeleton isPlain key={i} />
                ))}
              </>
            ) : (
              <>
                <StyleFlex fullWidth direction="column" alignItems="baseline">
                  <SaveFlex fullWidth justifyContent="space-between">
                    <NameFlex direction="column">
                      <Title>{searchName}</Title>
                      <Total isMobileCompanyDetail={isMobileCompanyDetail}>
                        {replaceString(t('jobtrack_search_result_count_text'), { TOTAL_V: jobsData.length })}
                      </Total>
                    </NameFlex>
                    {show && (template === 'search' || !!activeFeeds) && (
                      <>
                        <UpdatedFlex ref={openRef}>
                          <ButtonWrapper>
                            <ButtonBox alignItems="center" justifyContent="center" onClick={handleSave}>
                              <FavIcon />
                              <div>
                                {isPastSearch || !!!activeFeeds
                                  ? t('jobtrack_modal_save_search_button_text')
                                  : t('jobtrack_search_result_update_option_label')}
                              </div>
                            </ButtonBox>
                          </ButtonWrapper>
                          {open && (
                            <UpdateSuggestion direction="column">
                              <SuggestionText onClick={handleNewSaveSearch}>
                                {t('jobtrack_search_result_save_option_text')}
                              </SuggestionText>
                              <SuggestionText onClick={handleExistingSearch}>
                                {t('jobtrack_search_result_update_option_text')}
                              </SuggestionText>
                            </UpdateSuggestion>
                          )}
                        </UpdatedFlex>
                      </>
                    )}
                  </SaveFlex>
                </StyleFlex>
                {jobsData.length > 0 && (
                  <VirtualizedInfinteScroll
                    handleDeleteConfirm={handleDeleteConfirm}
                    handleDirectJobAdd={handleDirectJobAdd}
                    onClickCard={onClickCard}
                    next={next}
                  />
                )}
              </>
            )}
          </SectionWrapper>
        )}
      </StyledFlex>
    </Wrapper>
  );
};

export default withConfirm(DetailLeftSection);

const SuggestionText = styled.div`
  width: 100%;
  padding: 0 4px;
  border-radius: 4px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-dark-regular)', '20px')};
  cursor: pointer;
  &:hover {
    background-color: var(--grayscale-n200);
    color: var(--dark-values-dark-regular);
  }
`;

const UpdateSuggestion = styled(Flex)`
  position: absolute;
  min-width: 170px;
  background-color: var(--light-values-white);
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 6px 24px rgba(20, 20, 31, 0.12);
  gap: 8px;
  top: 34px;
  z-index: 9;
  @media (min-width: 768px) {
    width: 100%;
    flex-shrink: 0;
    right: 0px;
  }
  @media (min-width: 992px) {
    top: 33px;
    width: fit-content;
    flex-shrink: 0;
  }
`;

const SaveFlex = styled(Flex)`
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    gap: 5px;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
  }
`;

const UpdatedFlex = styled(Flex)`
  position: relative;
  @media (min-width: 768px) {
    width: 100%;
    flex-shrink: 0;
  }
  @media (min-width: 992px) {
    width: fit-content;
    flex-shrink: 0;
  }
`;

const NameFlex = styled(Flex)`
  flex: 1;
  @media (min-width: 1000px) {
    max-width: 185px;
  }
`;

const ButtonWrapper = styled(Flex)`
  flex-shrink: 0;
  @media (min-width: 768px) {
    width: 100%;
    flex-shrink: 0;
  }
  @media (min-width: 992px) {
    border: none;
    flex-shrink: 0;
    width: fit-content;
  }
`;

const ButtonBox = styled(Flex)`
  border-radius: 8px;
  cursor: pointer;
  padding: 6px 8px;
  gap: 8px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-black)', '20px')};
  background-color: var(--grayscale-n50);
  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 992px) {
    width: fit-content;
  }
  &:hover {
    background-color: var(--grayscale-n200);
  }
`;

const StyleFlex = styled(Flex)`
  gap: 4px;
  margin-bottom: 16px;
  @media (min-width: 768px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

const Total = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n400)', '20px')};
  ${({ isMobileCompanyDetail }) =>
    isMobileCompanyDetail &&
    css`
      display: none;
    `}
`;

const Title = styled.div`
  max-width: 230px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '17', 'var(--grayscale-n900)', '24px')};
`;

const ColumnName = styled(Flex)`
  align-items: center;
  padding: 13px 20px 13px 0;
  background-color: var(--light-values-white);
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '24', 'var(--dark-values-black)', '1.41')};
  ${({ first }) =>
    first &&
    css`
      padding-top: 0;
    `}
`;

const Wrapper = styled.div`
  background-color: var(--light-values-white);
  overflow: visible;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 230px;
    min-width: 230px;
  }
  @media (min-width: 992px) {
    min-width: 320px;
    max-width: 320px;
  }
  @media (min-width: 1200px) {
    min-width: 350px;
    max-width: 350px;
  }
  @media (min-width: 1440px) {
    min-width: 370px;
    max-width: 370px;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 31, 0.12);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }

  ${({ isBoard }) =>
    isBoard &&
    css`
      overflow-y: auto;
      overflow: hidden;
      min-width: 100%;
      flex-shrink: 0;
    `}

  ${({ isMobileCompanyDetail }) =>
    isMobileCompanyDetail &&
    css`
      display: none;
    `}
`;

const StyledFlex = styled(Flex)`
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--light-values-white);
`;

const InfiniteScrollSection = css`
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

const SectionWrapper = styled(Flex)`
  ${InfiniteScrollSection}
`;
