import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const DropdownmenuItemWrapper = styled(Flex)`
  background-color: transparent;
  position: absolute;
  top: 32px;
  right: 0;
  width: 200px;
  overflow: visible;
  ${({ isLeft }) =>
    isLeft &&
    css`
      right: unset;
      left: 0;
    `};
`;

export default DropdownmenuItemWrapper;
