import { useEffect, useRef, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import styled, { css } from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import { setCookie, parseCookies } from 'nookies';
import { fetchResumesTrackingData } from 'imports/job-tracking/api/api';
import { RESUMES_TRACKING_COLUMN_HEADER } from 'imports/job-tracking/api/constants';
import { inputStyle } from 'imports/core/ui/mixins';
import { useSearchParams } from 'next/navigation';
import { QuestionIcon } from 'imports/core/ui/assets';
import ExternalLinkIcon from '/imports/job-tracking/ui/assets/ExternalLinkIcon';

const ResumesTracking = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nameOrEmail, setNameOrEmail] = useState('');
  const dataRef = useRef();
  const params = useSearchParams();
  const [page, setPage] = useState(1);
  const { agent_client_id } = parseCookies();
  const fetchPreResume = async () => {
    setLoading(true);
    setError(null);
    try {
      const resp = await fetchResumesTrackingData(page);
      setData(resp?.paginatedData);
      dataRef.current = resp?.paginatedData;
      const q = params.get('query_filter');
      if (q) {
        setNameOrEmail(q);
      }
    } catch (err) {
      console.error('Error fetching resumes tracking:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = useCallback(
    debounce(async (value) => {
      try {
        const resp = await fetchResumesTrackingData(page, value);
        if (resp?.paginatedData.length > 0) {
          setData(resp?.paginatedData);
        }
      } catch (e) {
        console.error(e);
      }
    }, 500),
    [],
  );

  const handleValueChange = (e) => {
    const value = e.target.value?.trim();
    setNameOrEmail(value);
    debouncedFetch(value);
    updateParamsUrl(value);
  };

  const updateParamsUrl = (value) => {
    const url = new URL(window?.location?.href);
    if (value) {
      url.searchParams.set('query_filter', value);
    } else {
      url.searchParams.delete('query_filter');
    }
    window.history.replaceState({}, '', url);
  };

  useEffect(() => {
    fetchPreResume();
  }, [page]);

  const handleRoute =
    (resumeId, item, isOriginalResume = false) =>
    () => {
      if (agent_client_id) {
        document.cookie = 'agent_client_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'agent_client_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'agent_client_name=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      }
      setCookie(null, 'agent_client_id', isOriginalResume ? item.builderUserId : item.agentClientUserId, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
      const url = `${window.origin}/resume/${resumeId}/finish?agentClientUserId=${isOriginalResume ? item.builderUserId : item.agentClientUserId}`;
      window.open(url, '_blank');
    };

  return (
    <Container>
      <Title>RESUMES TRACKING</Title>
      <Flex $justifyContent="end" style={{ width: '100%' }}>
        <FormGroup $direction="column">
          <Label htmlFor="input">Filter by Candidate Email:</Label>
          <Input value={nameOrEmail} onChange={handleValueChange} />
        </FormGroup>
      </Flex>
      <Title>Page: {page}</Title>
      {loading && <LoadingMessage>Loading...</LoadingMessage>}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!loading && !error && data.length > 0 && (
        <Table>
          <thead>
            <Tr>
              {RESUMES_TRACKING_COLUMN_HEADER.map((column, index) => {
                return (
                  <Th $color={column.color} key={index}>
                    <Wrapper $fullWidth>
                      <Flex alignItems="center" $fullWidth>
                        {column.title}
                        {column?.infoBubble && (
                          <IconWrapper>
                            <QuestionIcon />
                          </IconWrapper>
                        )}
                      </Flex>
                      {column?.infoBubble && <Bubble>{column.infoBubble}</Bubble>}
                    </Wrapper>
                  </Th>
                );
              })}
            </Tr>
          </thead>
          <tbody>
            {data?.map((item, index) => (
              <Tr key={index}>
                <Td>{item.email}</Td>
                <Td>{item.isMultiPageResume === true ? 'Multiple page' : 'Single page'}</Td>
                <Td>
                  {item.originalResume && (
                    <StyledDiv onClick={handleRoute(item.originalResume, item, true)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.preCheckboxResume && (
                    <StyledDiv onClick={handleRoute(item.preCheckboxResume, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.resumeWithCheckboxes && (
                    <StyledDiv onClick={handleRoute(item.resumeWithCheckboxes, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.searchResumeNoQAfterModified && (
                    <StyledDiv onClick={handleRoute(item.searchResumeNoQAfterModified, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.finalResumeNoQPolished && (
                    <StyledDiv onClick={handleRoute(item.finalResumeNoQPolished, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.resizedResumeBeforeQ && (
                    <StyledDiv onClick={handleRoute(item.resizedResumeBeforeQ, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.shadowResumeV1 && (
                    <StyledDiv onClick={handleRoute(item.shadowResumeV1, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.finalResumeV1AutoReview && (
                    <StyledDiv onClick={handleRoute(item.finalResumeV1AutoReview, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.exhaustiveResumeV2 && (
                    <StyledDiv onClick={handleRoute(item.exhaustiveResumeV2, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.finalResumeV2AutoReview && (
                    <StyledDiv onClick={handleRoute(item.finalResumeV2AutoReview, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                {/* <Td>
                  {item.searchTemplateQBeforeModified && (
                    <StyledDiv onClick={handleRoute(item.searchTemplateQBeforeModified, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td> */}
                <Td>
                  {item.searchTemplateQAfterModified && (
                    <StyledDiv onClick={handleRoute(item.searchTemplateQAfterModified, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
                <Td>
                  {item.finalResumeQPolished && (
                    <StyledDiv onClick={handleRoute(item.finalResumeQPolished, item)}>
                      <ExternalLinkIcon />
                    </StyledDiv>
                  )}
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      )}
      <PaginationWrapper>
        <PageText>Page: {page}</PageText>
        {page > 1 && (
          <Button
            onClick={() => {
              if (page <= 1) {
                return;
              }
              setPage(page - 1);
            }}
          >
            Previous page
          </Button>
        )}

        <Button onClick={() => setPage(page + 1)}>Next page</Button>
      </PaginationWrapper>
      {!loading && !error && data.length === 0 && <NoDataMessage>No resumes to display.</NoDataMessage>}
    </Container>
  );
};

export default ResumesTracking;

const FormGroup = styled(Flex)``;

const PaginationWrapper = styled(Flex)`
  margin-top: 12px;
  align-items: center;
  gap: 34px;
`;
const PageText = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const Button = styled.button`
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    pointer-events: none;
    background-color: grey;
    width: 129.3px;
    display: flex;
    justify-content: center;
  }
`;

const Input = styled.input`
  ${inputStyle}
  font-size: 14px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 3px;
  box-shadow: none;
  min-width: 320px;
  border: solid 2px #e6e6ff;
  background-color: var(--light-values-white);

  ${({ showIcon }) =>
    !showIcon &&
    css`
      padding: 13px 16px 9px;
    `}

  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.gray.light};
  }

  &:focus {
    border: solid 2px #1688fe;
  }

  ${(p) =>
    p.light &&
    css`
      background: #fff;
    `}
  ${(p) =>
    p.expanded &&
    p.dark &&
    css`
      z-index: 99;
      border: 1px solid #dbe0e5;
    `}

  ${(p) =>
    p.showIcon &&
    css`
      padding-left: 33px;
    `}

  ${(p) => p.theme.max('xs')`
    background: #fff;
    border-radius: 3px;
    border: solid 2px #e6e6ff;
    padding: 13px 16px 9px 33px;
  `};
`;
const Label = styled.label`
  margin-bottom: 8px;
  display: block;
  font-size: 14px;
  font-family: 'Gilroy Medium';
  color: #01132c;
`;
const StyledDiv = styled(Flex)`
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  cursor: pointer;
`;

const Container = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: 25px;
  height: 100vh;
  max-width: 100%;
  overflow-x: auto;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-top: 20px;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #f4f4f4;
  ${({ $color }) =>
    $color &&
    css`
      background-color: ${$color};
    `}
`;

const Tr = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 10px;
`;

const Link = styled.a`
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LoadingMessage = styled.p`
  font-size: 1rem;
  color: #666;
`;

const ErrorMessage = styled.p`
  font-size: 1rem;
  color: red;
`;

const NoDataMessage = styled.p`
  font-size: 1rem;
  color: #666;
`;

const Loader = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #007bff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 0.5s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Bubble = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: 6px;
  padding: 6px 12px;
  transform: translateX(-50%);
  z-index: 99;

  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
  display: none;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out;

  max-width: 400px;
  width: max-content;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

const Wrapper = styled(Flex)`
  font-size: 14px;
  position: relative;
  display: inline-block;
  &:hover ${Bubble} {
    display: block;
  }
`;

const IconWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  svg {
    stroke: none;
    width: 20px;
    height: 20px;
  }
`;
