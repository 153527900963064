import { withSearch } from 'imports/job-tracking/api/context/search.context';
import { getCountry } from '/imports/checkout/api/utils';
import useFeedStore from 'zustand/feedStore';
import { useSearchStore } from 'zustand/SearchStore';
import { useCallback } from 'react';

export const useFeedData = () => {
  const {
    state: { orderBy },
  } = withSearch();
  const {
    setSearchText,
    searchTextData,
    cityTextData,
    setCityTextData,
    filter,
    setFilter,
    regionCodeData,
    setRegionCode,
  } = useSearchStore();
  const { feeds } = useFeedStore();

  const feedDataBind = useCallback(
    async (feedId) => {
      const activeFeed = feeds?.find((k) => k.feedId === feedId);
      if (!activeFeed) return;
      const country = getCountry();
      const {
        searchText = '',
        where = '',
        postedDate = '',
        remote = '',
        educationLevels = [],
        salary = '',
        jobType = [],
        skills = [],
        locations = [],
        employers = [],
        allowedJobProviders = [],
        distance,
        apiProvider,
        isEasyApply = '',
      } = activeFeed;
      let data = filter;
      data = {
        datePost: postedDate,
        remote: remote,
        educationLevel: educationLevels,
        salary: salary,
        jobType: jobType,
        skills: skills,
        location: locations,
        company: employers,
        allowedJobProviders: allowedJobProviders,
        distance: distance,
        apiProvider: apiProvider,
        isEasyApply: isEasyApply,
      };
      setSearchText(searchText ? searchText : '');
      setCityTextData(where ? where : '');
      setFilter(data);
      setRegionCode(country);

      return { searchTextData, cityTextData, filter, regionCodeData, data, searchText, where, country };
    },
    [
      feeds,
      orderBy,
      setSearchText,
      setCityTextData,
      setFilter,
      setRegionCode,
      searchTextData,
      cityTextData,
      filter,
      regionCodeData,
    ],
  );

  return { feedDataBind };
};
