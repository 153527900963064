import JobDescription from '../common/JobDescription';
import { useEffect } from 'react';
import nookies from 'nookies';
import { savedData } from 'imports/job-tracking/api/helper';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import Flex from '/imports/core/ui/atoms/Flex';
import { getJobAdditionalInfo } from '/imports/job-tracking/api/api';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import { useSearchStore } from 'zustand/SearchStore';

const DetailBrief = ({ isBoarded = false, $noPadding = false, isMobileCompnayDetail = false }) => {
  const { activeCardData } = useSearchStore();
  const data = !!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData;
  const {
    state: { boards },
    dispatch: boardDispatch,
  } = withBoards();
  const { token } = nookies.get({});
  const {
    query: { template },
  } = useRouter();
  const isTracking = template === 'tracking';
  const getTrackInfo = async () => {
    const resp = await getJobAdditionalInfo(data.jobId, data.boardId, token);
    boardDispatch({ type: 'SET_TASKS', payload: resp });
  };

  useEffect(() => {
    if (!isBoarded) return;
    boardDispatch({ type: 'FLUSH_TASKS', payload: { tasks: [] } });
    getTrackInfo();
  }, [data?.id]);

  return (
    <StyledFlex isNewDesign={isTracking}>
      <JobDescription
        $noPadding={$noPadding}
        isMobileCompnayDetail={isMobileCompnayDetail}
        isSaved={!!savedData(boards, data)}
      />
    </StyledFlex>
  );
};

export default DetailBrief;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  ${({ isNewDesign }) =>
    isNewDesign &&
    css`
      margin-top: 24px;
      gap: 24px;
      flex-direction: row-reverse;
      @media (max-width: 620px) {
        flex-direction: column;
      }
    `}
`;
