import nookies from 'nookies';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import styled, { css } from 'styled-components';

import Flex from 'imports/core/ui/atoms/Flex';
import DropdownAutocomplete from 'imports/core/ui/atoms/new-ui/DropdownAutoComplete';
import { listPodioSavedJobs, updatePodioJob } from 'imports/job-tracking/api/api';
import { useQuery } from 'react-apollo';
import useFeedStore from 'zustand/feedStore';
import PodioJobItem from './PodioJobItem';
import { LIST_RESUMES } from '/imports/dashboard/api/apollo/client/queries';

export default function NewFlowSavedJobsList() {
  const { token, agent_client_id, agent_client_email } = nookies.get({});
  const [markAppliedStatus, setMarkAppliedStatus] = useState(false);
  const [activeJob, setActiveJob] = useState('');
  const [jobSearchName, setJobSearchName] = useState('');
  const [searchOptions, setSearchOptions] = useState([]);
  const [podioJobList, setPodioJobList] = useState([]);
  const [activeButtonSearch, setActiveButtonSearch] = useState('still-adapted');
  const [originalResume, setOriginalResume] = useState([]);
  const podioJobListRef = useRef([]);
  const [loading, setLoading] = useState(false);
  const { feeds } = useFeedStore();
  const { data, error, refetch } = useQuery(LIST_RESUMES, {
    fetchPolicy: 'no-cache',
  });
  const kpi = useMemo(() => {
    const total = podioJobListRef.current && podioJobListRef.current?.length;
    const applied =
      podioJobListRef.current && podioJobListRef.current?.filter((j) => j.status === 'Application Sent').length;
    const readyToApply =
      podioJobListRef.current &&
      podioJobListRef.current?.filter((j) => j?.resumeDetails?.length > 0 && j?.status !== 'Application Sent')?.length;
    const stillAdapted =
      podioJobListRef.current &&
      podioJobListRef.current?.filter((j) => j?.resumeDetails?.length === 0 && j?.status !== 'Application Sent')
        ?.length;
    return {
      total,
      applied,
      readyToApply,
      stillAdapted,
    };
  }, [podioJobListRef.current]);
  useEffect(() => {
    refetchPodioList();
  }, [jobSearchName]);

  useEffect(() => {
    getDynamicFields();
  }, [podioJobListRef.current]);

  useEffect(() => {
    const defaultResume = data?.listResumes?.find((resume) => ['v1', 'v2'].includes(resume.saVersion));
    setOriginalResume(defaultResume);
  }, [data]);

  const getDynamicFields = () => {
    if (!feeds || !Array.isArray(feeds)) {
      console.error('Feeds data is missing or not in the correct format');
      return;
    }
    const searchNameOptions = feeds
      .map((s) => {
        if (!s?.feedName) return null;
        const currentSearchData = podioJobListRef.current?.filter((t) => t.searchName === s.feedName) || [];
        const appliedCount = currentSearchData.filter((t) => t.status === 'Application Sent').length || 0;
        let count = '';
        if (currentSearchData.length > 0 || appliedCount > 0) {
          count = `(${appliedCount}/${currentSearchData.length})`;
        }
        return {
          title: `${s?.icon || ''} ${s?.feedName || ''} ${count}`,
          value: s.feedName,
        };
      })
      .filter(Boolean);
    const all = { title: 'All', value: 'all' };
    setSearchOptions([all, ...searchNameOptions]);
  };

  const refetchPodioList = async () => {
    setLoading(true);
    const podioJobList = await listPodioSavedJobs(token, jobSearchName == 'all' ? '' : jobSearchName);
    podioJobListRef.current = podioJobList;
    setPodioJobList(podioJobList);
    setLoading(false);
  };

  const handleViewJob = (jobLink, job) => {
    let url = new URL(jobLink);
    if (job?.jobId) {
      url.searchParams.set('saJobId', job.jobId);
    }
    url.searchParams.set('agentClientUserId', encodeURIComponent(job?.agentClientId || agent_client_id));
    url.searchParams.set('saemail', agent_client_email);
    if (url.origin.includes('indeed')) {
      url.protocol = 'https';
      url.hostname = 'fr.indeed.com';
    } else if (url.origin.includes('linkedin')) {
      url.protocol = 'https';
      url.hostname = 'fr.linkedin.com';
    }
    window.open(url.toString(), '_blank');
  };

  const handleMarkApplied = (job) => {
    setMarkAppliedStatus(true);
    let data = {
      isApply: true,
      title: job.title,
      jobId: job.jobId,
      location: job.location,
      jobLink: job.jobLink,
    };
    updatePodioJob(data, token).finally(() => {
      refetchPodioList();
      setMarkAppliedStatus(false);
    });
  };

  useEffect(() => {
    const filterData = () => {
      switch (activeButtonSearch) {
        case 'total-saved':
          setPodioJobList(podioJobListRef.current);
          break;
        case 'ready':
          setPodioJobList(
            podioJobListRef.current?.filter((p) => p.resumeDetails.length > 0 && p.status !== 'Application Sent'),
          );
          break;
        case 'applied':
          setPodioJobList(podioJobListRef.current?.filter((p) => p.status === 'Application Sent'));
          break;
        case 'still-adapted':
          setPodioJobList(
            podioJobListRef.current?.filter((p) => p.status !== 'Application Sent' && p.resumeDetails?.length === 0),
          );
          break;
        default:
          setPodioJobList(podioJobListRef.current);
      }
    };
    filterData();
  }, [podioJobListRef.current, activeButtonSearch]);

  const handleItemClick = (id) => {
    setActiveJob(id !== activeJob ? id : '');
  };

  return (
    <Fragment>
      <Flex
        $direction="row"
        style={{ gap: 16, width: '100%' }}
        $alignItems={'flex-end'}
        $justifyContent={'space-between'}
      >
        {searchOptions?.length > 0 && (
          <>
            <DropdownAutocomplete
              style={{ maxWidth: 250 }}
              options={searchOptions}
              placeholder={'Job search'}
              defaultValue={'all'}
              onSelect={(e) => {
                setJobSearchName(e.value);
              }}
            />

            <Button
              $disabled={activeButtonSearch === 'still-adapted'}
              onClick={() => {
                setActiveButtonSearch('still-adapted');
              }}
              color={'var(--grayscale-n50)'}
              borderColor={'var(--grayscale-n200)'}
              textColor={'var(--dark-values-black)'}
            >
              Adaptation Progress ({kpi?.stillAdapted || 0}){/* Total Saved ({kpi?.total || 0}) */}
            </Button>
            <Button
              $disabled={activeButtonSearch === 'ready'}
              onClick={() => {
                setActiveButtonSearch('ready');
              }}
              color={'var(--grayscale-n50)'}
              borderColor={'var(--grayscale-n200)'}
              textColor={'var(--dark-values-black)'}
            >
              Total Ready To Apply ({kpi?.readyToApply || 0})
            </Button>
            <Button
              $disabled={activeButtonSearch === 'applied'}
              onClick={() => {
                setActiveButtonSearch('applied');
              }}
              color={'var(--grayscale-n50)'}
              borderColor={'var(--grayscale-n200)'}
              textColor={'var(--dark-values-black)'}
            >
              Total Applied ({kpi?.applied || 0})
            </Button>
          </>
        )}
      </Flex>
      {podioJobList?.length > 0 && (
        <GapFlex fullWidth direction="column" $withMarginTop>
          <Wrapper fullWidth direction="column" $noMarginTop $noBorder>
            {podioJobList
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map((job) => (
                <PodioJobItem
                  data={data}
                  handleItemClick={handleItemClick}
                  markAppliedStatus={markAppliedStatus}
                  handleViewJob={handleViewJob}
                  handleMarkApplied={handleMarkApplied}
                  originalResume={originalResume}
                  activeJob={activeJob}
                  job={job}
                  key={job.id}
                />
              ))}
          </Wrapper>
        </GapFlex>
      )}
      {loading && (
        <DataDisplaySection $fullWidth direction="column">
          {[...Array(4)].map((_, index) => (
            <ExtraBlock $fullWidth justifyContent="space-between" key={index}>
              <Block $size={300}>
                <Skeleton />
              </Block>
              <Block $size={100}>
                <Skeleton />
              </Block>
            </ExtraBlock>
          ))}
        </DataDisplaySection>
      )}
    </Fragment>
  );
}

const Block = styled.div`
  ${({ $size }) =>
    $size &&
    css`
      min-width: ${$size}px;
    `}
`;

const DataDisplaySection = styled(Flex)`
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-values-gray-light);
  padding: 16px;
`;

const ExtraBlock = styled(Flex)`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-values-gray-light);
  margin-bottom: 16px;
  height: 100px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Button = styled.button`
  color: ${(p) => (p.textColor && p.textColor) || `#fff`};
  background-color: ${({ theme, color }) =>
    color?.includes('var(--')
      ? color // Use the CSS variable directly
      : theme.colors[color?.split('.')[0]]?.[color?.split('.')[1]] || theme.colors[color || 'primary']};
  border: 1px solid
    ${({ theme, borderColor, color }) =>
      borderColor?.includes('var(--')
        ? borderColor // Use the CSS variable directly
        : borderColor
          ? theme.colors[borderColor?.split('.')[0]]?.[borderColor?.split('.')[1]] || theme.colors[borderColor]
          : theme.colors[color || 'primary']};
  padding: ${({ size }) => (size === 'small' ? '8px 12px' : '10px 20px')};
  font-size: ${({ size }) => (size === 'small' ? '0.875rem' : '1rem')};
  border-radius: 5px;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: progress;
      pointer-events: none;
      opacity: 0.4;
    `}
  &:hover {
    opacity: 0.9;
  }
`;

const GapFlex = styled(Flex)`
  gap: 8px;
  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 30px;
    `}
  ${({ $withBorderTop }) =>
    $withBorderTop &&
    css`
      border-top: 1px solid var(--grayscale-n200);
      padding-top: 24px;
    `}
  ${({ $withMarginBottom }) =>
    $withMarginBottom &&
    css`
      margin-bottom: 24px;
    `}
`;

const Wrapper = styled(Flex)`
  background-color: var(--light-values-white);
  border: 1px solid var(--neutral-values-gray-light);
  padding: 24px 24px;
  margin: 30px 0;
  border-radius: 8px;
  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      margin-top: 0;
    `}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
      padding: 0;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
`;

NewFlowSavedJobsList.propTypes = {
  podioJobList: PropTypes.arrayOf(PropTypes.object),
  refetchPodioList: PropTypes.func,
};
