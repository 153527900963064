const DeleteIcon = () => (
  <svg width="13px" height="15px">
    <path
      fillRule="evenodd"
      fill="#de696a"
      d="M12.227,3.947 L11.505,14.263 L1.494,14.263 L0.772,3.947 L-0.000,3.947 L-0.000,2.473 L3.611,2.473 L3.611,0.263 L9.389,0.263 L9.389,2.473 L13.000,2.473 L13.000,3.947 L12.227,3.947 ZM7.944,1.736 L5.055,1.736 L5.055,2.473 L7.944,2.473 L7.944,1.736 ZM2.219,3.947 L2.838,12.789 L10.161,12.789 L10.780,3.947 L2.219,3.947 ZM7.222,5.421 L8.666,5.421 L8.666,11.315 L7.222,11.315 L7.222,5.421 ZM4.333,5.421 L5.778,5.421 L5.778,11.315 L4.333,11.315 L4.333,5.421 Z"
    />
  </svg>
);

export const DeleteIconStyled = ({ dark }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a1 1 0 0 0-1 1h-.172C5.018 1 3.281 1.72 2 3h12a6.828 6.828 0 0 0-4.828-2H9a1 1 0 0 0-1-1zm6 4H2l.167 2 .68 8.166A2 2 0 0 0 4.84 16h6.32a2 2 0 0 0 1.993-1.834L13.833 6 14 4zm-2.174 2H4.174l.666 8h6.32l.666-8z"
      fill={dark ? '#14141f' : '#8C98AA'}
      className="styled-fill"
    />
  </svg>
);

export default DeleteIcon;
