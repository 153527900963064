import compose from 'lodash.flowright';
import moment from 'moment';
import { withRouter } from 'next/router';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import qs from 'qs';
import { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import styled from 'styled-components';

import { TemplateCont } from '/imports/dashboard/ui/atoms/Item';
// import DownloadOptionsDropdown from '/imports/generator/ui/components/DownloadOptionsDropdown';
import { Push } from '/components/Link';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withConfirm } from '/imports/core/api/confirm';
import { withIntl } from '/imports/core/api/useIntl';
import EditableTitleAtom from '/imports/core/ui/atoms/EditableTitle';
import Flex from '/imports/core/ui/atoms/Flex';
import {
  DUPLICATE_COVER_LETTER as DS,
  REMOVE_COVER_LETTER as RS,
} from '/imports/dashboard/api/apollo/client/mutations';
import { LIST_COVER_LETTERS } from '/imports/dashboard/api/apollo/client/queries';
import { UPDATE_COVER_LETTER_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import DuplicateIcon from '/imports/job-tracking/ui/assets/DuplicateIcon';
import IconDelete from '/imports/job-tracking/ui/assets/IconDelete';
import IconDownload from '/imports/job-tracking/ui/assets/IconDownload';
import IconEdit from '/imports/job-tracking/ui/assets/IconEdit';
import DownloadOptionsDropdown from '/imports/job-tracking/ui/components/DownloadOptionsDropdown';
import { isPaidUser, staticFile } from '/lib/helpers';

@withIntl
@withConfirm
@withAccount
@withRouter
@compose(
  graphql(DS, { name: 'duplicate' }),
  graphql(RS, { name: 'remove' }),
  graphql(UPDATE_COVER_LETTER_DETAIL, { name: 'updateDetail' }),
)
class CoverLetterItem extends PureComponent {
  static propTypes = {
    coverLetter: PropTypes.object,
    remove: PropTypes.func,
    duplicate: PropTypes.func,
    updateDetail: PropTypes.func,
  };

  _dateFormat = 'DD MMMM, HH:mm';

  state = {
    show: false,
    isLoading: false,
  };

  goTo = (e) => {
    e.stopPropagation();
    const {
      currentUser: { role },
      locale,
    } = this.props;
    const { agent_client_id } = nookies.get({});
    if (!isPaidUser(role) && !agent_client_id) return this.goToCheckout();

    Push(`/cover-letter/${this.props.coverLetter.id}/finish`, locale);
  };

  goToCheckout = () => {
    const {
      router: { asPath },
      locale,
    } = this.props;
    const queryString = {
      from: asPath,
    };
    Push(`/checkout?${qs.stringify(queryString)}`, locale, '/checkout');
  };

  updateTitle = (value) => {
    const {
      coverLetter: { id: docId },
      updateDetail,
    } = this.props;

    updateDetail({
      variables: {
        docId,
        path: 'name',
        value,
      },
      context: {
        client: 'coverLetter',
      },
    });
  };

  remove = (e) => {
    if (e) e.stopPropagation();

    const {
      remove,
      coverLetter: { id },
      confirm,
      t,
    } = this.props;

    confirm({
      title: t('dashboard_cover_letter_item_confirm_title'),
      text: t('dashboard_cover_letter_item_confirm_text'),
      confirmText: t('delete'),
      onConfirm() {
        remove({
          variables: {
            id,
          },
          context: {
            client: 'coverLetter',
          },
          refetchQueries: [
            {
              query: LIST_COVER_LETTERS,
              context: { client: 'coverLetter' },
            },
          ],
        });
      },
    });
  };

  duplicate = async (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isLoading: true,
    });

    const {
      duplicate,
      coverLetter: { id },
    } = this.props;
    const dupesCountIncremented = await this.incrementDupesCount();
    1;

    if (dupesCountIncremented) {
      duplicate({
        variables: {
          id,
        },
        context: {
          client: 'coverLetter',
        },
        refetchQueries: [
          {
            query: LIST_COVER_LETTERS,
            context: { client: 'coverLetter' },
          },
        ],
      }).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  incrementDupesCount = () => {
    const {
      coverLetter: { id: docId, dupesCount },
      updateDetail,
    } = this.props;

    const value = dupesCount === null ? 1 : dupesCount + 1;

    return updateDetail({
      variables: {
        docId,
        path: 'dupesCount',
        value,
      },
      context: {
        client: 'coverLetter',
      },
    });
  };

  // Gets either the updated or creation date.
  get date() {
    const {
      coverLetter: { updatedAt, createdAt },
      locale,
    } = this.props;
    return moment(updatedAt || createdAt)
      .locale(locale)
      .format(this._dateFormat);
  }

  onMouseEnter = () => {
    this.setState({
      show: true,
    });
  };

  toggleOffDropdown = () => {
    this.setState({
      show: false,
    });
  };
  toggleDropdown = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    const {
      coverLetter,
      coverLetter: {
        name,
        settings: { template },
      },
      currentUser: { role },
      t,
    } = this.props;
    const { agent_client_id } = nookies.get({});
    const isAgent = !!agent_client_id;
    return (
      <Wrapper direction="column">
        <CoverLetterWrapper>
          <TemplateCont template={staticFile(`img/cover-letter/${template}.jpg`)} />
          <ControlElements alignItems="center">
            <Flex fullWidth justifyContent="center">
              <ButtonWrapper direction="column">
                <ButtonInner justifyContent="center" alignItems="center" onClick={this.goTo}>
                  <IconEdit />
                </ButtonInner>
                <ButtonText onClick={this.goTo}>{t('edit')}</ButtonText>
              </ButtonWrapper>
              {!isAgent && (
                <ButtonWrapper direction="column">
                  <StyledButtonInner onClick={this.duplicate} disabled={this.state.isLoading}>
                    <DuplicateIcon />
                  </StyledButtonInner>
                  <ButtonText>{t('duplicate')}</ButtonText>
                </ButtonWrapper>
              )}
              {(isPaidUser(role) || isAgent) && (
                <ButtonWrapper
                  direction="column"
                  onMouseOver={this.onMouseEnter}
                  onClick={this.toggleDropdown}
                  onMouseLeave={this.toggleOffDropdown}
                >
                  <ButtonInner justifyContent="center" alignItems="center">
                    <IconDownload />
                  </ButtonInner>
                  <DownloadOptionsDropdown show={this.state.show} source={coverLetter} isCoverLetter />
                </ButtonWrapper>
              )}
              {!isAgent && (
                <ButtonWrapper direction="column">
                  <ButtonInner justifyContent="center" alignItems="center" onClick={this.remove}>
                    <IconDelete />
                  </ButtonInner>
                  <ButtonText>{t('delete')}</ButtonText>
                </ButtonWrapper>
              )}
            </Flex>
          </ControlElements>
        </CoverLetterWrapper>
        <ResumeTitleWrapper direction="column" fullWidth>
          <EditableTitle
            onSave={this.updateTitle}
            onClick={this.goTo}
            defaultvalue={name}
            visibleIcon={1}
            isShortTitle
          />
          <ControlDescription>
            {t('dashboard.updated')} {this.date}
          </ControlDescription>
        </ResumeTitleWrapper>
      </Wrapper>
    );
  }
}

const ButtonText = styled.p`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--light-values-white)', '1.54')};
  text-align: center;
  margin: 0 auto;
  display: none;
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ButtonWrapper = styled(Flex)`
  margin: 0 6px;
  position: relative;
  &:hover {
    ${ButtonText} {
      display: block;
    }
    .styled-stroke {
      stroke: #1d2c43;
    }
    .styled-fill {
      fill: #1d2c43;
    }
  }
`;

const StyledButtonInner = styled.button`
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 8px 24px 0 rgba(29, 44, 67, 0.12);
  background-color: var(--light-values-white);
  &:hover {
    background-color: var(--light-values-light-medium);
    #svg-dynamic-fill {
      fill: var(--dark-values-black);
    }
  }
  #svg-dynamic-fill {
    fill: var(--dark-values-dark-regular);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
const ButtonInner = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 8px 24px 0 rgba(29, 44, 67, 0.12);
  background-color: var(--light-values-white);
  &:hover {
    background-color: var(--light-values-light-medium);
    #svg-dynamic-fill {
      fill: var(--dark-values-black);
    }
  }
  #svg-dynamic-fill {
    fill: var(--dark-values-dark-regular);
  }
`;

const ControlDescription = styled.p`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--mid-values-gray-light)', '1.54')};
  font-weight: 500;
  margin: 0;
`;

const ResumeTitleWrapper = styled(Flex)`
  padding: 16px;
`;

const ControlElements = styled(Flex)`
  position: absolute;
  inset: 0 0 0 0;
  background-color: var(--transparents-opacity-48);
  border: solid 1px var(--transparents-opacity-12);
  display: none;
  z-index: 1000;
`;

const CoverLetterWrapper = styled(Flex)`
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  width: 100%;
  height: 350px;
  position: relative;
  border: solid 1px var(--transparents-opacity-12);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const Wrapper = styled(Flex)`
  overflow: hidden;
  min-width: 288px;
  max-width: 288px;
  height: 498px;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--light-values-light-extra);
  &:hover {
    box-shadow: 0 4px 16px 0 var(--transparents-opacity-8);
    background-color: var(--light-values-white);
    ${ControlElements} {
      display: flex;
    }
  }
  @media (min-width: 768px) {
    min-width: 314px;
    max-width: 314px;
  }
  @media (min-width: 992px) {
    min-width: 201px;
    max-width: 201px;
    height: 364px;
  }
  @media (min-width: 1200px) {
    min-width: 206px;
    max-width: 206px;
  }
  @media (min-width: 1440px) {
    min-width: 232px;
    max-width: 232px;
    height: 360px;
  }
`;

export const EditableTitle = styled(EditableTitleAtom)`
  position: relative;
  margin: 0 0 0 -5px;
  font-size: 19px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '1.47')};
  button {
    opacity: 1;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export default CoverLetterItem;
