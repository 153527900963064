import React from 'react';

const IconEdit = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="red" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#dyw0vn020a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m3.414 14 9.379-9.379-1.414-1.414L2 12.586V14h1.414zm.829 2L15.62 4.621 11.38.38 0 11.757V16h4.243z"
          fill="#4C4C55"
          id="svg-dynamic-fill"
        />
      </g>
      <defs>
        <clipPath id="dyw0vn020a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconEdit;
