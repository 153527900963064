import React, { useEffect, useCallback, useState, useMemo } from 'react';
import nookies from 'nookies';
import nProgress from 'nprogress';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import CardDetailModal from '/imports/job-tracking/ui/components/CardDetailModal';
import Container from '/imports/job-tracking/ui/atoms/Container';
import EditableFeedName from '/imports/job-tracking/ui/components/feed/EditableFeedName';
import FilterTagSuggestion from '/imports/job-tracking/ui/components/FilterTags';
import Flex from '/imports/core/ui/atoms/Flex';
import { isJobTrackEnable } from '/lib/helpers';
import NoJobIcon from '/imports/job-tracking/ui/assets/NoJobIcon';
import { Push } from '/components/Link';
import SearchHeader from '/imports/job-tracking/ui/components/SearchHeader';
import SearchSekeleton from '/imports/job-tracking/ui/skeleton/SearchSekeleton';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';
import { withSearch } from '/imports/job-tracking/api/context/search.context';
import useFeedStore from 'zustand/feedStore';
import { useSearchStore } from 'zustand/SearchStore';
import { useFeedData } from '/imports/job-tracking/ui/components/hooks/useFeedData';
import { useOnSave } from '/imports/job-tracking/ui/components/hooks/useOnSave';
import { useSavedFeed } from '/imports/job-tracking/ui/components/hooks/useSavedFeed';
import { useUpdateFeed } from '/imports/job-tracking/ui/components/hooks/useUpdateFeed';
import { useFilterData } from '/imports/job-tracking/ui/components/hooks/useFilterData';

nProgress.configure({ showSpinner: false });

const Search = ({ feedId, loading }) => {
  const { token, builder_id } = nookies.get({});
  const { locale, t } = useIntl();
  const { isMobile } = useResponsive();
  const [searchLoading, setSearchLoading] = useState(false);
  const { trackEvent } = useTracking();
  const router = useRouter();
  const withHeader = isJobTrackEnable() && !isMobile;
  const { setActiveFeeds, feeds } = useFeedStore();

  useEffect(() => {
    if (router?.query.show === 'true') setIsToolTip(true);
    else setIsToolTip(false);
  }, [router.query?.show]);
  const {
    jobsData,
    setSearchText,
    searchTextData,
    setCityTextData,
    cityTextData,
    setsavedFeedNameData,
    savedFeedNameData,
    isLoading,
    filter,
    regionCodeData,
    indexData,
    setIndex,
    position,
  } = useSearchStore();
  const {
    state: { orderBy },
    handleUpdateState,
  } = withSearch();
  const { feedDataBind } = useFeedData();
  const [isToolTip, setIsToolTip] = useState(false);
  const isSearchPage = useMemo(() => router.query.template === 'search', [router.query.template]);
  const { onSave } = useOnSave(feedId, token, isSearchPage);
  const { saveFeedCallback } = useSavedFeed(token);
  const { updateFeedCallBack } = useUpdateFeed(feedId, token);
  const { getFilterData } = useFilterData(token, locale);
  const fetchActiveFeed = feeds?.find((k) => k.feedId === feedId, [feedId, feeds]);
  useEffect(() => {
    fetchActiveFeed && setActiveFeeds(fetchActiveFeed);
  }, [fetchActiveFeed]);

  useEffect(() => {
    let obj = {};
    if (router.query.origin) obj.origin = router.query.origin;
    if (feedId && fetchActiveFeed?.bySearch === false) {
      trackEvent('feed_view', fetchActiveFeed?.feedId);
    } else if (feedId) {
      trackEvent('pastsearch_view', fetchActiveFeed?.feedId);
    } else {
      if (router.query.origin) {
        trackEvent('search_view_by_email_click');
        trackEvent('Jobtrack_Initiated');
      }
      trackEvent('search_view', obj);
    }
  }, [feedId]);

  useEffect(() => {
    if (!withHeader) {
      if (!!feedId && !loading) {
        feedDataBind(feedId);
      } else {
        window.scrollTo({ top: position });
      }
    }
  }, [feedId, loading]);

  useEffect(() => {
    const FetchFeedData = async () => {
      if (feedId) {
        const result = await feedDataBind(feedId);
        if (!result) return;
        const { data, searchText, where, country } = result;
        getFilterData(false, false, true, searchText, where, data, orderBy, country);
      }
    };

    FetchFeedData();
  }, [feeds, feedId]);

  const changeText = async (searchValue, cityValue) => {
    setSearchLoading(true);
    setsavedFeedNameData('');
    setSearchText(searchValue);
    setCityTextData(cityValue);
    getFilterData(false, false, true, searchValue, cityValue, filter, orderBy, regionCodeData);
    const queryString = {
      template: router.query.template,
      search: searchValue,
      location: cityValue,
    };
    if (isSearchPage) {
      Push(
        `/app?${qs.stringify(queryString)}`,
        locale,
        `/app/${router.query.template}?search=${searchValue}&location=${cityValue}`,
      );
    }
    setSearchLoading(false);
  };

  useEffect(() => {
    if (indexData > 0)
      getFilterData(
        true,
        true,
        false,
        searchTextData,
        cityTextData,
        filter,
        orderBy,
        regionCodeData,
        savedFeedNameData,
        indexData + 1,
      );
  }, [indexData]);

  const nextTrigger = useCallback(() => {
    setIndex(indexData + 1);
  });

  const handleData = (filter) => {
    getFilterData(false, true, true, searchTextData, cityTextData, filter, orderBy, regionCodeData);
  };

  const updateFeedDesc = (isNew, isPastSearch) => {
    let TitleText = '';
    if (searchTextData && cityTextData) TitleText = `${searchTextData},${cityTextData}`;
    else if (searchTextData && !cityTextData) TitleText = searchTextData;
    else if (!searchTextData && cityTextData) TitleText = cityTextData;
    isSearchPage || isNew ? saveFeedCallback(TitleText) : updateFeedCallBack(TitleText);
    if (isPastSearch) trackEvent('feed_update', { isPastSearch: true });
  };

  const OrderByData = (data) => {
    handleUpdateState('orderBy', data);
    getFilterData(false, false, true, searchTextData, cityTextData, filter, data, regionCodeData);
  };

  return (
    <SearchWrap>
      <Wrapper>
        {feedId && (
          <Title isSkeleton={loading}>{loading ? <p>loading</p> : <EditableFeedName feedId={feedId} />}</Title>
        )}
        <SearchHeader changeText={changeText} searchLoading={searchLoading} />
        {isToolTip && <TooltipWhiteSpace />}
        <TagsFlex>
          <FilterTagSuggestion handleData={handleData} />
        </TagsFlex>
        {isLoading && <SearchSekeleton />}
        {jobsData && jobsData.length === 0 && (
          <StyledFlex fullWidth justifyContent="center" alignItems="center" direction="column">
            <NoJobIcon />
            <Text>{t('jobtrack_no_job_available')}</Text>
          </StyledFlex>
        )}
        {!isLoading && (
          <CardDetailModal
            nextTrigger={nextTrigger}
            isSearchJob
            onSave={onSave}
            updateFeedDesc={updateFeedDesc}
            OrderByData={OrderByData}
          />
        )}
      </Wrapper>
    </SearchWrap>
  );
};

export default Search;

const TooltipWhiteSpace = styled.div`
  margin-top: 0px;
  @media (min-width: 768px) {
    margin-top: 60px;
  }
  @media (min-width: 1200px) {
    margin-top: 40px;
  }
`;
const TagsFlex = styled(Flex)`
  margin-bottom: 32px;
  ${({ isToolTip }) =>
    isToolTip &&
    css`
      margin-top: 0px;
      @media (min-width: 768px) {
        margin-top: 60px;
      }
      @media (min-width: 1200px) {
        margin-top: 40px;
      }
    `}
`;

const Text = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '20px', 'var(--grayscale-n400)', '44px')};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  min-height: 80vh;
`;

const Title = styled.h1`
  margin: 0;
  padding: 32px 0 8px;
  display: flex;
  width: 100%;
  > span {
    width: 200px;
  }
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '34px', 'var(--dark-values-black)', '44px')};
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      display: block;
      margin-bottom: 32px;
      height: 50px;
      width: 200px;
      @media (min-width: 768px) {
        width: 500px;
      }
    `}
  @media (min-width: 768px) {
    font-size: 50px;
    padding-top: 48px;
    width: auto;
    > span {
      width: 500px;
    }
  }
  letter-spacing: -0.25px;
`;

const Wrapper = styled(Container)`
  width: 100%;
  padding: 0;
  scroll-behavior: smooth;
  flex-direction: column;
  * {
    user-select: text;
  }
  @media (min-width: 768px) {
    max-width: 90%;
  }
  @media (min-width: 992px) and (max-width: 1110px) {
    width: 100%;
    padding: 0px 24px;
    max-width: 876px;
  }
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 912px;
  }
  @media (min-width: 1440px) {
    max-width: 1000px;
  }
  @media (min-width: 1920px) {
    max-width: 1392px;
  }
`;

const SearchWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1110px) {
    display: block;
  }
`;
