import React, { useContext, createContext, useReducer } from 'react';

import Redu from '/imports/job-tracking/api/reducers/tasks.reducer';

const TaskContext = createContext();
export const Provider = TaskContext.Provider;
export const TaskConsumer = TaskContext.Consumer;

const TaskProvider = ({ children, tasks = [] }) => {
  const [state, dispatch] = useReducer(Redu, tasks);
  return (
    <Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </Provider>
  );
};

export const withTasks = () => useContext(TaskContext);

export default TaskProvider;
