import { create } from 'zustand';

export const useGeneralStore = create((set) => ({
  Boards: null,
  jobTrackEmoji: null,
  layoutEmoji: null,
  isMobileCompany: false,
  SignUpModal: false,
  closeEmojiPicker: null,

  setboards: (boardsList) =>
    set(() => ({
      Boards: boardsList,
    })),

  setjobTrackEmojiPicker: (boardId) =>
    set(() => ({
      jobTrackEmoji: boardId,
      layoutEmoji: null,
    })),

  setlayoutEmojiPicker: (id) =>
    set(() => ({
      layoutEmoji: id,
      jobTrackEmoji: null,
    })),

  setcloseEmojiPicker: () =>
    set(() => ({
      jobTrackEmoji: null,
      layoutEmoji: null,
    })),

  setIsMobileCompanyDetail: (isMobile) =>
    set(() => ({
      isMobileCompany: isMobile,
    })),

  setOpenSignUpModal: () =>
    set({
      SignUpModal: true,
    }),
}));
