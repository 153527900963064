import React from 'react';

const DuplicateIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#8r75p9ln3a)" fill="#4C4C55" id="svg-dynamic-fill">
        <path d="M13 10h1V2H6v1H4V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-1v-2z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h8zm0 2v8H2V6h8z"
        />
      </g>
      <defs>
        <clipPath id="8r75p9ln3a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DuplicateIcon;
