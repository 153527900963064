import React from 'react';
import Container from '../atoms/Container';
import Flex from 'imports/core/ui/atoms/Flex';
import HeaderSkeleton from 'imports/dashboard/ui/skeleton/HeaderSkeleton';
import SearchIcon from '../assets/SearchIcon';
import SearchSekeleton from './SearchSekeleton';
import styled, { ThemeProvider } from 'styled-components';

import Theme from '/imports/job-tracking/api/theme';
import useIntl from '/imports/core/api/useIntl';
import useMountedState from '/imports/core/api/useMountedState';

const HeaderLayoutSkeleton = () => {
  const { t } = useIntl();
  const { renderIfMounted } = useMountedState();
  const invertTheme = (theme) => {
    return {
      ...theme,
      isJobTrack: true,
      jobTrack: Theme,
    };
  };
  return (
    <ThemeProvider theme={invertTheme}>
      {renderIfMounted(
        <>
          <HeaderSkeleton isJobTrackHeader />
          <Wrapper direction="column">
            <StickyHeader fullWidth>
              <HeaderWrapper fullWidth>
                <StyledFlex fullWidth alignItems="center">
                  <SearchInputFlex fullWidth>
                    <SearchInputWrapper>
                      <FieldLabel>{t('jobtrack_search_keyword_input_label')}</FieldLabel>
                      <Input placeholder={t('jobtrack_search_keyword_input_placeholder')} />
                    </SearchInputWrapper>
                  </SearchInputFlex>
                  <SearchInputFlex fullWidth>
                    <SearchInputWrapper>
                      <FieldLabel>{t('jobtrack_search_location_input_label')}</FieldLabel>
                      <Input placeholder={t('jobtrack_search_location_input_placeholder')} />
                    </SearchInputWrapper>
                  </SearchInputFlex>
                </StyledFlex>
                <SearchButton disabled={true}>
                  <SvgWrapper>
                    <SearchIcon />
                  </SvgWrapper>
                  {t('jobtrack_search_keyword_input_label')}
                </SearchButton>
              </HeaderWrapper>
              <SearchSekeleton />
            </StickyHeader>
          </Wrapper>
        </>,
      )}
    </ThemeProvider>
  );
};

export default HeaderLayoutSkeleton;

const SvgWrapper = styled.div`
  height: 16px;
  width: 16px;
  svg > path {
    fill: var(--grayscale-n300);
  }
`;

const SearchButton = styled(Flex)`
  padding: 18px 24px;
  gap: 12px;
  background-color: #1688fe;
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 18px;
  color: var(--grayscale-n300);
  line-height: 24px;
  border-radius: 4px;
  pointer-events: none;
  justify-content: center;
  width: 100%;
  background-color: var(--grayscale-n50);
  @media (min-width: 768px) {
    width: fit-content;
  }
`;
const FieldLabel = styled.p`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 13px;
  color: var(--dark-values-dark-regular);
  line-height: 1.54;
  margin: 0;
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;
  position: relative;
  gap: 8px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const HeaderWrapper = styled(Flex)`
  width: 100%;
  gap: 8px;
  flex-direction: column;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const StickyHeader = styled(Flex)`
  z-index: 99;
  border-radius: 4px;
  gap: 16px;
  flex-direction: column;
  background-color: var(--light-values-white);
`;
const Input = styled.input`
  border: none;
  width: 100%;
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  font-size: 15px;
  color: var(--dark-values-black);
  line-height: 1.47;
  border: none;
  &::placeholder {
    color: var(--grayscale-n300);
  }
  &:focus {
    outline: none;
  }
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  height: 60px;
  padding: 9px 12px;
  border-radius: 4px;
  background-color: var(--light-values-white);
  border: 1px solid var(--grayscale-n300);
`;

const SearchInputFlex = styled(Flex)`
  border-radius: 4px;
  position: relative;
  background-color: var(--light-values-white);
  @media (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Wrapper = styled(Container)`
  width: 100%;
  padding: 0;
  margin-top: 32px;
  margin-bottom: 16px;
  scroll-behavior: smooth;
  * {
    user-select: text;
  }
  @media (min-width: 768px) {
    max-width: 90%;
  }
  @media (min-width: 992px) {
    width: 90%;
    max-width: 876px;
  }
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 912px;
  }
  @media (min-width: 1440px) {
    max-width: 1000px;
  }
  @media (min-width: 1920px) {
    max-width: 1392px;
  }
`;
