import {
  setTask,
  pushTask,
  updateTask,
  deleteTask,
  pushMultipleTask,
} from '/imports/job-tracking/api/library/tasks.lib';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TASK':
      return setTask(action);
    case 'PUSH_TASK':
      return pushTask(state, action);
    case 'UPDATE_TASK':
      return updateTask(state, action);
    case 'DELETE_TASK':
      return deleteTask(state, action);
    case 'PUSH_MULTIPLE_TASK':
      return pushMultipleTask(state, action);
    default:
      return state;
  }
};

export default Reducer;
