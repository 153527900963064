import React from 'react';
import styled, { css } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';
import Flex from 'imports/core/ui/atoms/Flex';

const CardSkeleton = ({ isPlain, isReadOnly }) => (
  <Card direction="column" fullWidth isPlain={isPlain} isSkeleton isReadOnly={!isReadOnly}>
    <Flex direction="column" fullWidth>
      <Box></Box>
      <CardTextPrimary isSkeleton>
        <Skeleton />
      </CardTextPrimary>
      <CardTextSecondary isSkeleton>
        <Skeleton />
      </CardTextSecondary>
      {!isReadOnly && (
        <CompanyName isSkeleton>
          <Skeleton />
        </CompanyName>
      )}
      <LocationText isSkeleton>
        <Skeleton />
      </LocationText>
    </Flex>
    {!isReadOnly && (
      <CardFooter justifyContent="space-between" fullWidth alignItems="center">
        <Period isSkeleton>
          <Skeleton />
        </Period>
      </CardFooter>
    )}
  </Card>
);

export default CardSkeleton;

const LocationText = styled.div`
  margin-left: 16px;
  ${OneLineCss}
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n400)', '1.54')};
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 80%;
      margin-left: 0;
    `}
`;

const CompanyName = styled(LocationText)`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--dark-values-black)', '1.54')};
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Period = styled(Flex)`
  padding-right: 8px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n400)', '1.54')};
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 10%;
    `}
`;

const CardFooter = styled(Flex)`
  margin-top: auto;
`;

const CardTextSecondary = styled.div`
  user-select: none !important;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '18', 'var(--dark-values-black)', '1.47')};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 100%;
    `}
`;

const MoreOptions = styled(Flex)`
  position: absoulte;
  right: 12px;
  display: none;
`;

const CardTextPrimary = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n500)', '1.54')};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 100%;
    `}
`;

const Box = styled(Flex)`
  width: 32px;
  height: 32px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 8px;
  background-color: var(--light-values-white);
  ${({ logo }) =>
    logo &&
    css`
      background-image: url(${logo});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    `}
`;

const Card = styled(Flex)`
  position: relative;
  width: 100%;
  direction: ltr;
  padding: 12px;
  min-height: auto;
  border-radius: 8px;
  border: 1px solid var(--grayscale-n300);
  ${({ isPlain }) =>
    isPlain &&
    css`
      margin-bottom: 1px;
    `}
  background-color: var(--light-values-white);
  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #1688fe;
    `}
  cursor: pointer;
  &:hover {
    background-color: var(--light-values-white);
    ${MoreOptions} {
      display: flex;
    }
  }
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      pointer-events: none;
      width: 370px;
    `}
  ${({ searchPage }) =>
    searchPage &&
    css`
      margin-bottom: 8px;
    `}
`;
