import cloneDeep from 'lodash/cloneDeep';

export const setTask = (action) => action.payload;

export const pushTask = (state, action) => [action.payload, ...state];

export const updateTask = (state, action) => {
  state.splice(
    state.findIndex((x) => x.taskId === action.payload.taskId),
    1,
    action.payload,
  );
  return [...state];
};

export const deleteTask = (state, action) => {
  let tasks = cloneDeep(state);
  tasks = tasks.filter((k) => k.taskId !== action.payload);
  return [...tasks];
};

export const pushMultipleTask = (state, action) => {
  let tasks = cloneDeep(state);
  return [...tasks, ...action.payload];
};
