import Flex from 'imports/core/ui/atoms/Flex';
import { savedData } from 'imports/job-tracking/api/helper';
import React from 'react';
import styled from 'styled-components';
import { useSearchStore } from 'zustand/SearchStore';
import RoundedButton from '/imports/job-tracking/ui/atoms/RoundedButton';
import { withBoards } from 'imports/job-tracking/api/context/boards.context';
import { useRouter } from 'next/router';
import useIntl from '/imports/core/api/useIntl';
import Chip from '/imports/job-tracking/ui/atoms/Chip';

const BottomSectionDetails = ({ onClick, isMobileCompnayDetail, mainTitle, isSaved }) => {
  const {
    state: { boards },
  } = withBoards();
  const {
    query: { template },
  } = useRouter();
  const { t } = useIntl();
  const { activeCardData } = useSearchStore();
  let data = !!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData;
  const isTracking = template === 'tracking';

  const handlePortalClick = (link) => {
    if (!link) return;
    window.open(link, '_blank');
  };

  return (
    <StyledFlex fullWidth direction="column">
      {data?.shift?.length > 0 && data?.shift !== null && (
        <ReqTag direction="column">
          <SubTitle>{t('jobtrack_job_description_shift_text')}</SubTitle>
          <ReqTag>
            {data?.shift?.map((k, i) => (
              <Chip colorCode={3} alignItems="center" value={k} fullWidthkey={i} key={i} />
            ))}
          </ReqTag>
        </ReqTag>
      )}
      {data.benefits?.length > 0 && data.benefits !== null && (
        <ReqTag direction="column">
          <SubTitle>{t('jobtrack_job_description_benefits_text')}</SubTitle>
          <StyledFlexBenefit fullWidth wrap="wrap">
            {data?.benefits?.map((k, i) => (
              <Chip colorCode={3} alignItems="center" value={k} fullWidth key={i} />
            ))}
          </StyledFlexBenefit>
        </ReqTag>
      )}
      <InternalTagWrapper
        direction="row"
        fullWidth
        isMobileCompnayDetail={isMobileCompnayDetail}
        isSaved={isTracking && isSaved}
      >
        <Flex direction="column">
          <SubTitle>{t('jobtrack_job_description_provider_text')}</SubTitle>
          <StyledFlexBenefit fullWidth wrap="wrap">
            {data.portals?.map((portal, i) => (
              <ClickableBadge onClick={() => handlePortalClick(portal.url)} key={i}>
                <Chip colorCode={3} alignItems="center" value={portal.jobProvider} fullWidth />
              </ClickableBadge>
            ))}
            {data.portal && (!data.portals || data.portals?.length == 0) && (
              <Chip colorCode={3} alignItems="center" value={data?.portal} fullWidth />
            )}
          </StyledFlexBenefit>
        </Flex>

        {data.cleanedTitle && (
          <ReqTag direction="column">
            <SubTitle>{t('jobtrack_job_description_clean_title_text')}</SubTitle>
            <Chip colorCode={3} alignItems="center" value={data?.cleanedTitle} fullWidth />
          </ReqTag>
        )}
        {(data.mainTitle || mainTitle) && (
          <ReqTag direction="column">
            <SubTitle>{t('jobtrack_job_description_main_title_text')}</SubTitle>
            <Chip colorCode={3} alignItems="center" value={data?.mainTitle || mainTitle} fullWidth />
          </ReqTag>
        )}
        {!data.mainTitle && data.isManualCheck && !mainTitle && (
          <StyledRoundedButton onClick={onClick} borderOnlyBtn>
            {t('jobtrack_add_mainTitle_button')}
          </StyledRoundedButton>
        )}
      </InternalTagWrapper>
    </StyledFlex>
  );
};

export default BottomSectionDetails;

const StyledFlexBenefit = styled(Flex)`
  flex-shrink: 0;
  gap: 4px;
`;

const ReqTag = styled(Flex)`
  gap: 4px;
`;

const ClickableBadge = styled.span`
  cursor: pointer;
`;

const StyledFlex = styled(Flex)`
  gap: 16px;
`;

const SubTitle = styled.div`
  user-select: text;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '22px')};
`;

const InternalTagWrapper = styled(Flex)`
  background-color: var(--light-values-white);
  gap: 16px;
  user-select: text;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-left: 20px;
  @media (min-width: 768px) {
    width: fit-content;
  }
`;
