import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { capitalizeFirstLetter } from '/imports/job-tracking/api/helper';
import { CHIP_LIST } from '/imports/job-tracking/api/constants';
import Flex from '/imports/core/ui/atoms/Flex';
import JobCompanyIcon from '/imports/job-tracking/ui/assets/JobCompanyIcon';
import MarketAvgIcon from '/imports/job-tracking/ui/assets/MarketAvgIcon';
import MoneyIcon from '/imports/job-tracking/ui/assets/MoneyIcon';
import TimeIcon from '/imports/job-tracking/ui/assets/TimeIcon';

const Chip = ({
  value,
  colorCode,
  fullWidth = false,
  withMarginBottom = false,
  withSalary = false,
  withJob = false,
  withShift = false,
  marketAvg = false,
}) => {
  const [chipText, setChipText] = useState(value);
  useEffect(() => {
    setChipText(capitalizeFirstLetter(value));
  }, [value]);
  return (
    <Wrapper color={CHIP_LIST[colorCode]} fullWidth={fullWidth} withMarginBottom={withMarginBottom} alignItems="center">
      <StyledFlex alignItems="center">
        {withSalary && (
          <SVGWrapper>
            <MoneyIcon />
          </SVGWrapper>
        )}
        {marketAvg && (
          <SVGWrapper>
            <MarketAvgIcon />
          </SVGWrapper>
        )}
        {withJob && (
          <SVGWrapper withJob>
            <JobCompanyIcon />
          </SVGWrapper>
        )}
        {withShift && (
          <SVGWrapper withJob>
            <TimeIcon />
          </SVGWrapper>
        )}
        <ChipText>{chipText}</ChipText>
      </StyledFlex>
    </Wrapper>
  );
};

export default Chip;

const StyledFlex = styled(Flex)`
  gap: 8px;
`;

const Wrapper = styled(Flex)`
  padding: 0 4px;
  min-height: 24px;
  border-radius: 4px;
  width: fit-content;
  flex-shrink: 0;
  background-color: ${({ color }) => color.background};
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--dark-values-black)', '1.54')};
  margin-right: 2px;
  ${({ withMarginBottom }) =>
    withMarginBottom &&
    css`
      margin-bottom: 4px;
    `}
  max-width: 75px;
  word-break: break-word;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      max-width: unset;
    `}
`;

const ChipText = styled.div``;

const SVGWrapper = styled(Flex)`
  min-width: 16px;
  cursor: pointer;
  ${({ withJob }) =>
    withJob &&
    css`
      height: 14px;
      min-width: 14px;
    `}
`;
