import React from 'react';

const IconDownload = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.114 8.29a1 1 0 1 0-1.228-1.58L9 8.956V1a1 1 0 0 0-2 0v7.955L4.114 6.711a1 1 0 1 0-1.228 1.578l4.5 3.5.614.478.614-.478 4.5-3.5zM3 14a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H3z"
        fill="#4C4C55"
        id="svg-dynamic-fill"
      />
    </svg>
  );
};

export default IconDownload;
