import React from 'react';

const MarketAvgIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.02925 10.1888L7.69244 10.1354C7.32356 10.0768 7.06935 9.7921 7.11371 9.48708C7.16117 9.16047 7.54798 8.92117 7.94059 8.97294C8.27386 9.01762 8.52792 9.25025 8.54468 9.52617C8.55397 9.67783 8.68446 9.79295 8.83584 9.78393C8.98739 9.7747 9.10279 9.64442 9.09361 9.49288C9.06265 8.98406 8.65398 8.55613 8.10755 8.44439V8.17097C8.10755 8.0191 7.98447 7.89602 7.8326 7.89602C7.68073 7.89602 7.55765 8.0191 7.55765 8.17097V8.44452C7.05321 8.54707 6.63979 8.92453 6.56949 9.40803C6.48137 10.0141 6.93675 10.5722 7.60619 10.6784L7.94301 10.7319C8.3049 10.7894 8.56867 11.0473 8.55655 11.3318C8.55 11.4847 8.4747 11.6276 8.34447 11.734C8.17537 11.8723 7.93592 11.9329 7.70335 11.896C7.37582 11.844 7.13046 11.6082 7.11993 11.3353C7.11408 11.1834 6.98863 11.0678 6.83462 11.071C6.68291 11.0768 6.5646 11.2046 6.57046 11.3563C6.59027 11.8723 7.00187 12.3137 7.55765 12.4271V12.7019C7.55765 12.8538 7.68073 12.9769 7.8326 12.9769C7.98447 12.9769 8.10755 12.8538 8.10755 12.7019V12.4304C8.32247 12.3888 8.52403 12.2975 8.69251 12.1598C8.94577 11.9527 9.09258 11.667 9.1059 11.3552C9.13002 10.7916 8.66717 10.2901 8.02925 10.1888Z"
        fill="black"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M14.0598 1.33333H10.9463C10.7944 1.33333 10.6713 1.45642 10.6713 1.60828V3.17109H7.83381C7.68193 3.17109 7.55886 3.29418 7.55886 3.44604V4.65581H4.7214C4.56952 4.65581 4.44645 4.7789 4.44645 4.93076V6.11638H1.60893C1.45707 6.11638 1.33398 6.23946 1.33398 6.39133V12.4743C1.33398 12.6261 1.45707 12.7492 1.60893 12.7492H4.29437C5.05075 13.9027 6.35439 14.6667 7.83381 14.6667C7.83378 14.6667 7.83383 14.6667 7.83381 14.6667C7.83383 14.6667 7.83399 14.6667 7.83401 14.6667C9.31342 14.6667 10.6171 13.9027 11.3735 12.7492H14.0598C14.2117 12.7492 14.3347 12.6261 14.3347 12.4743V1.60828C14.3347 1.45642 14.2117 1.33333 14.0598 1.33333ZM8.10876 4.93076V3.72099H10.6713V7.30122C9.98392 6.67853 9.09141 6.27909 8.10876 6.21573V4.93076ZM4.99635 6.39133V5.20571H7.55886V6.21575C6.57623 6.27915 5.68372 6.67863 4.99635 7.30134V6.39133ZM1.88388 12.1993V6.66628H4.44645V7.90477C3.91723 8.61109 3.60339 9.48768 3.60339 10.4362C3.60339 11.0653 3.74246 11.6622 3.9898 12.1993H1.88388ZM7.83391 14.1168C5.80454 14.1167 4.1535 12.4656 4.1535 10.4362C4.1535 8.40686 5.80454 6.7558 7.83391 6.75574C9.86328 6.7558 11.5143 8.40686 11.5143 10.4362C11.5143 12.4656 9.86328 14.1167 7.83391 14.1168ZM13.7848 12.1993H11.678C11.9254 11.6622 12.0644 11.0653 12.0644 10.4362C12.0644 9.48757 11.7505 8.61093 11.2212 7.90457V3.44604V1.88323H13.7848V12.1993Z"
        fill="black"
      />
      <path
        d="M7.83381 14.6667C6.35439 14.6667 5.05075 13.9027 4.29437 12.7492H1.60893C1.45707 12.7492 1.33398 12.6261 1.33398 12.4743V6.39133C1.33398 6.23946 1.45707 6.11638 1.60893 6.11638H4.44645V4.93076C4.44645 4.7789 4.56952 4.65581 4.7214 4.65581H7.55886V3.44604C7.55886 3.29418 7.68193 3.17109 7.83381 3.17109H10.6713V1.60828C10.6713 1.45642 10.7944 1.33333 10.9463 1.33333H14.0598C14.2117 1.33333 14.3347 1.45642 14.3347 1.60828V12.4743C14.3347 12.6261 14.2117 12.7492 14.0598 12.7492H11.3735C10.6171 13.9027 9.31342 14.6667 7.83401 14.6667C7.83399 14.6667 7.83383 14.6667 7.83381 14.6667ZM7.83381 14.6667C7.83383 14.6667 7.83378 14.6667 7.83381 14.6667ZM8.10876 4.93076V3.72099H10.6713V7.30122C9.98392 6.67853 9.09141 6.27909 8.10876 6.21573V4.93076ZM4.99635 6.39133V5.20571H7.55886V6.21575C6.57623 6.27915 5.68372 6.67863 4.99635 7.30134V6.39133ZM1.88388 12.1993V6.66628H4.44645V7.90477C3.91723 8.61109 3.60339 9.48768 3.60339 10.4362C3.60339 11.0653 3.74246 11.6622 3.9898 12.1993H1.88388ZM7.83391 14.1168C5.80454 14.1167 4.1535 12.4656 4.1535 10.4362C4.1535 8.40686 5.80454 6.7558 7.83391 6.75574C9.86328 6.7558 11.5143 8.40686 11.5143 10.4362C11.5143 12.4656 9.86328 14.1167 7.83391 14.1168ZM13.7848 12.1993H11.678C11.9254 11.6622 12.0644 11.0653 12.0644 10.4362C12.0644 9.48757 11.7505 8.61093 11.2212 7.90457V3.44604V1.88323H13.7848V12.1993Z"
        stroke="black"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default MarketAvgIcon;
