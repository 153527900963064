import NewFlowSavedJobsList from '../components/NewFlowSavedJobsList';
import { memo, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import styled, { css } from 'styled-components';

import Container from '/imports/job-tracking/ui/atoms/Container';
import Flex from '/imports/core/ui/atoms/Flex';
import { LIST_RESUMES } from '/imports/dashboard/api/apollo/client/queries';
import { Push } from '/components/Link';
import useTracking from '/imports/core/hooks/useTracking';
import SavedJobsList from '/imports/job-tracking/ui/components/SavedJobsList';
import intlHook from '/imports/core/api/useIntl';
import ErrorOccured from '/imports/core/ui/components/ErrorOccured';

const SavedJobs = () => {
  const { locale, t } = intlHook();
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('dashboard_view');
  }, []);

  const createResume = () => {
    Push('/resume/create', locale);
  };

  if (typeof window === 'undefined') return null;
  return (
    <Inner fullWidth>
      <Wrapper fullWidth>
        <NewFlowSavedJobsList />
      </Wrapper>
    </Inner>
  );
};

export default memo(SavedJobs);

const Wrapper = styled(Container)`
  flex-direction: column;
`;

const Inner = styled(Flex)`
  padding-top: 32px;
  ${({ showBorder }) =>
    showBorder &&
    css`
      border-top: 1px solid #e2e8f1;
    `}
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    padding-top: 48px;
  }
  @media (min-width: 992px) {
    padding-left: 25px;
  }
`;
