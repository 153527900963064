import {
  addToFeeds,
  setFeeds,
  updateToFeeds,
  deleteFeed,
  renameFeedName,
  setIcon,
  setActiveFeed,
} from '/imports/job-tracking/api/library/feeds.lib';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FEEDS':
      return setFeeds(state, action);
    case 'ADD_TO_FEEDS_LIST':
      return addToFeeds(state, action);
    case 'UPDATE_TO_FEEDS_LIST':
      return updateToFeeds(state, action);
    case 'DELETE_FEED':
      return deleteFeed(state, action);
    case 'RENAME_FEED_NAME':
      return renameFeedName(state, action);
    case 'SET_ICON':
      return setIcon(state, action);
    case 'SET_ACTIVE_FEED':
      return setActiveFeed(state, action);
    default:
      return state;
  }
};

export default Reducer;
