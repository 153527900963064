import { cloneDeep } from 'lodash';
import moment from 'moment';
import nookies from 'nookies';
import nProgress from 'nprogress';
import { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import Loading from '/imports/core/ui/components/Loading';
import searchApi from 'imports/job-tracking/api/searchApi';
import { useRouter } from 'next/router';
import { useGeneralStore } from 'zustand/generalStore';
import { useSearchStore } from 'zustand/SearchStore';
import env from '/env';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import Flex from '/imports/core/ui/atoms/Flex';
import { updateJobTrackPosition } from '/imports/job-tracking/api/api';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import {
  alreadyApplied,
  capitalizeAllFirstLetter,
  getCityCountry,
  getTotalBoardJobs,
  isFoundJob,
  isSavedPodioJob,
  processJobDataForPortal,
} from '/imports/job-tracking/api/helper';
import WishlistHeartIcon from '/imports/job-tracking/ui/assets/WishlistHeartIcon';
import DropdownMenuItmes from '/imports/job-tracking/ui/atoms/DropdownMenuItems';
import DropdownMenuItemWrapper from '/imports/job-tracking/ui/atoms/DropdownMenuItemWrapper';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';
import RoundedButton from '/imports/job-tracking/ui/atoms/RoundedButton';
import { removeDuplicateUrls } from '/lib/helpers';

nProgress.configure({ showSpinner: false });

const CompanyLayout = ({ children, isBoarded = false, isSearchJob = false, small }) => {
  const { t } = useIntl();
  const {
    state: { boards },
    dispatch,
  } = withBoards();
  const { searchTextData, cityTextData, podioJobsData, filter, candidateSearchs, activeCardData } = useSearchStore();
  const { query } = useRouter();
  const isFeed = query?.template === 'feeds';
  const savedPodioJob = isSavedPodioJob(podioJobsData, activeCardData);
  const isProd = env.NODE_ENV === 'production';
  const { isMobileCompany = false } = useGeneralStore();
  const [saveLoading, setSaveLoading] = useState(false);
  const { trackEvent } = useTracking();
  const isWishlist = useMemo(() => isBoarded && activeCardData.position?.[0] === 0, [activeCardData]);
  const hasSalary = useMemo(() => (activeCardData?.salary ? true : false), [activeCardData]);
  const { token } = nookies.get({});
  const [isApplied, setisAlreadyAppliedJob] = useState();
  const { usesavedJobsOnPodio } = searchApi();
  const { mutate } = usesavedJobsOnPodio(setSaveLoading);

  useEffect(() => {
    const isApplied = alreadyApplied(podioJobsData, activeCardData);
    setisAlreadyAppliedJob(isApplied);
  }, [activeCardData]);

  const handleStartApplication = async () => {
    const boardsData = cloneDeep(boards);
    let board = boardsData.find((k) => k.boardId === activeCardData.boardId);
    let jobs = getTotalBoardJobs(board);
    if (isWishlist) {
      nProgress.start();
      let count = jobs.filter((k) => k.position[0] === 1).length;
      dispatch({ type: 'JOB_START_APPLICATION', payload: activeCardData });
      const res = await updateJobTrackPosition(token, activeCardData.jobId, activeCardData.boardId, [1, count]);
      dispatch({ type: 'UPDATE_JOB', payload: res });
      trackEvent('start_my_application');
      nProgress.done();
    } else {
      nProgress.start();
      let count = jobs.filter((k) => k.position[0] === activeCardData.position[0] + 1).length;
      const res = await updateJobTrackPosition(token, activeCardData.jobId, activeCardData.boardId, [
        activeCardData.position[0] + 1,
        count,
      ]);
      trackEvent('job_status_next_cta');
      dispatch({
        type: 'DRAG_JOB_BY_INDEX',
        payload: {
          boardId: activeCardData.boardId,
          c1I: activeCardData.position[0],
          c2I: activeCardData.position[0] + 1,
          sI: activeCardData.position[1],
          dI: count,
          jobId: activeCardData.jobId,
        },
      });
      dispatch({ type: 'UPDATE_JOB', payload: res });
      nProgress.done();
    }
  };

  const isOfferRejected = () => activeCardData.position[0] === 6;
  const uniqurProvidedUrl = removeDuplicateUrls(activeCardData?.providersUrls || []);

  const goToIndeedLink = async () => {
    const baseUrl = 'https://fr.indeed.com/';
    window.open(`${baseUrl}${activeCardData?.jobUrl}`, '_blank');
  };

  const goToJobLink = async () => {
    const url = activeCardData?.reDirectionUrl ? activeCardData?.reDirectionUrl : activeCardData?.externalLink;
    window.open(`${url}`, '_blank');
  };

  const handleJobSaved = async () => {
    const isFound = isFoundJob(candidateSearchs, searchTextData, cityTextData);
    setSaveLoading(true);
    let obj = {
      title: activeCardData.title,
      jobLink: activeCardData.externalLink ? activeCardData.externalLink : activeCardData.url,
      location: activeCardData.location,
      jobId: activeCardData.id,
      jobSearchId: activeCardData.id,
      jobProvider: activeCardData.portal,
      companyName: activeCardData?.activeCardData,
      jobDescription: activeCardData?.description,
      salaryRange: activeCardData?.salaryRange,
      employmentType: activeCardData?.employmentType,
      isHunter: true,
    };
    if (isFound) {
      obj.podioItemId = isFound.podioItemId;
    }
    obj = processJobDataForPortal(obj, activeCardData?.portals || []);

    mutate(obj);
  };

  const handleRedirect = () => {
    window.open(activeCardData.url, '_blank');
    // handleUpdateState('openJobStatusModal', true);
  };

  return (
    <>
      <CompanyWrapper
        fullWidth
        direction="column"
        isSearchJob={isSearchJob}
        isBoarded={isBoarded}
        isMobileCompanyDetail={isMobileCompany}
      >
        <StyledFlex>
          <CompanyTitle isSearchJob={isSearchJob} isBoarded={isBoarded}>
            {capitalizeAllFirstLetter(activeCardData.title)}
          </CompanyTitle>
          <CompanyDetailWrapper fullWidth isBoarded={isBoarded}>
            <DetailInner fullWidth>
              <StyledFlexCompany fullWidth>
                <CompanyInfo>
                  {activeCardData.company +
                    '. ' +
                    (activeCardData.city ? activeCardData.city : '') +
                    '  (' +
                    (filter?.remote === 'Remote' ? t('jobtrack_remote_text') : t('jobtrack_on_site_text')) +
                    ') '}
                </CompanyInfo>
                <PostedDate>
                  <div>{activeCardData.datePosted}</div>
                </PostedDate>
              </StyledFlexCompany>
              {uniqurProvidedUrl.length > 0 ? (
                (isProd ? [uniqurProvidedUrl[0]] : uniqurProvidedUrl).map((item, k) => (
                  <UrlFlex alignItems="center" key={k}>
                    {!isProd && <PortalName>{capitalizeAllFirstLetter(item?.portal)}</PortalName>}
                    <UrlWrapper>
                      <Link href={item.length > 0 ? item[0].url : item?.url} target="_blank">
                        {activeCardData.activeCardData}
                      </Link>
                    </UrlWrapper>
                  </UrlFlex>
                ))
              ) : (
                <UrlWrapper>
                  <Link href={activeCardData?.url} target="_blank">
                    {activeCardData.activeCardData}
                  </Link>
                </UrlWrapper>
              )}
              {(activeCardData.city || activeCardData.country) && (
                <CardTextPrimary noMargin>
                  {getCityCountry(activeCardData.city, activeCardData.country)}
                </CardTextPrimary>
              )}
              {activeCardData?.location && <TagWrapper>{activeCardData?.location}</TagWrapper>}
              {hasSalary && <TagWrapper>{activeCardData.salary}</TagWrapper>}
            </DetailInner>
          </CompanyDetailWrapper>
        </StyledFlex>
        <Flex fullWidth direction="column">
          {children}
        </Flex>
      </CompanyWrapper>
      {small && (
        <SmallCompanyWrapper fullWidth>
          <SmallBox fullWidth>
            <Box direction="column">
              <SmallTitle>{activeCardData.title}</SmallTitle>
              <SmallTitle isBoarded alignItems="flex-start">
                <CompanyFlex isBoarded>
                  {activeCardData.company +
                    '. ' +
                    (activeCardData.city ? activeCardData.city : '') +
                    ' (' +
                    (activeCardData.isRemote ? t('jobtrack_remote_filter_text') : t('jobtrack_on_site_text')) +
                    ') '}
                </CompanyFlex>
                <PostedDate small>{activeCardData.datePosted}</PostedDate>
              </SmallTitle>
              {uniqurProvidedUrl.length > 0 ? (
                (isProd ? [uniqurProvidedUrl[0]] : uniqurProvidedUrl).map((item, k) => (
                  <UrlFlex alignItems="center" key={k}>
                    {!isProd && <PortalName>{capitalizeAllFirstLetter(item?.portal)}</PortalName>}
                    <UrlWrapper>
                      <Link href={item.length > 0 ? item[0].url : item?.url} target="_blank">
                        {activeCardData.activeCardData}
                      </Link>
                    </UrlWrapper>
                  </UrlFlex>
                ))
              ) : (
                <UrlWrapper>
                  <Link href={activeCardData?.url} target="_blank">
                    {activeCardData.activeCardData}
                  </Link>
                </UrlWrapper>
              )}
              {activeCardData?.location && <TagWrapper>{activeCardData?.location}</TagWrapper>}
              <TagWrapper small fullWidth alignItems="center" hasSalary={hasSalary}>
                {hasSalary && <div>{activeCardData.salary}</div>}
                {(activeCardData.city || activeCardData.country) && (
                  <CardTextPrimary hasSalary={hasSalary}>
                    {getCityCountry(activeCardData.city, activeCardData.country)}
                  </CardTextPrimary>
                )}
              </TagWrapper>
            </Box>
            <Box>
              <ActionWrapper isButton alignItems="center">
                {!isFeed && <PodioSavedJob>Save the search to save the job</PodioSavedJob>}
                {!savedPodioJob && isFeed ? (
                  <StyledRoundedButtonContainer>
                    <StyleRoundedButton onClick={handleJobSaved} $loading={saveLoading}>
                      <HeartFlex alignItems="center">
                        {saveLoading ? (
                          <LoadingContainer>
                            <Loading />
                          </LoadingContainer>
                        ) : (
                          <WishlistHeartIcon />
                        )}
                        Save Job
                      </HeartFlex>
                    </StyleRoundedButton>
                    <StyleRoundedButton onClick={goToIndeedLink} nobackground={true}>
                      <HeartFlex alignItems="center">Indeed</HeartFlex>
                    </StyleRoundedButton>
                    {!activeCardData?.isEasyApply ? (
                      <StyleRoundedButton onClick={goToJobLink} nobackground={true}>
                        <HeartFlex alignItems="center">{t('jobtrack_link_button')}</HeartFlex>
                      </StyleRoundedButton>
                    ) : (
                      <StyleRoundedButton nobackground={true} disabled={true}>
                        <HeartFlex alignItems="center">{t('jobtrack_easyapply_button')}</HeartFlex>
                      </StyleRoundedButton>
                    )}
                  </StyledRoundedButtonContainer>
                ) : (
                  isFeed && <CompanyTitle>{t('saved_podio')}</CompanyTitle>
                )}
                {isApplied && isFeed && <CompanyTitle>{t('applied_job')}</CompanyTitle>}
                {/*** hide the job apply button because of the new ui
                isFeed && (
                  <StyleRoundedButton onClick={handleRedirect} borderOnlyBtn id="job_apply_cta">
                    {isApplied ? 'Check Job' : t('jobtrack_apply_button')}
                  </StyleRoundedButton>
                )*/}
              </ActionWrapper>
            </Box>
          </SmallBox>
        </SmallCompanyWrapper>
      )}
      {small && isBoarded && (
        <SmallCompanyWrapper fullWidth>
          <SmallBox fullWidth isBoarded>
            <SmallTitleBox direction="column" isBoarded>
              <SmallTitle>{activeCardData.title}</SmallTitle>
              <Wrapper alignItems="center" direction="column" isBoarded>
                <StarFlex>
                  <SmallTitle isBoarded alignItems="flex-start">
                    <CompanyFlex isBoarded>
                      {activeCardData.company + '. ' + (activeCardData.city ? activeCardData.city : '')}{' '}
                      <span>
                        {' (' +
                          (activeCardData.isRemote ? t('jobtrack_remote_filter_text') : t('jobtrack_on_site_text')) +
                          ') '}
                      </span>
                    </CompanyFlex>
                    <PostedDate small>{moment(activeCardData.createdAt).fromNow()}</PostedDate>
                  </SmallTitle>
                </StarFlex>
                {hasSalary && (
                  <TagWrapper small hasSalary={hasSalary}>
                    {activeCardData.salary}
                  </TagWrapper>
                )}
              </Wrapper>
            </SmallTitleBox>
          </SmallBox>
        </SmallCompanyWrapper>
      )}
    </>
  );
};

export default CompanyLayout;

const PodioSavedJob = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '20', 'var(--dark-values-black)', '30px')};
`;

const LoadingContainer = styled(Flex)`
  & > div {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    background-color: transparent !important;
    position: relative !important;
    margin-top: 5px;
  }
  & picture img,
  & picture source {
    margin: 0px !important;
    width: 20px !important;
    height: 20px !important;
  }
  & > * {
    filter: brightness(0) invert(1) !important;
  }
`;

const Dropdown = styled(DropdownMenuItemWrapper)`
  top: 24px;
  right: -22px;
  left: unset;
  padding-top: 4px;
  display: none;
  ${({ addToWishlist }) =>
    addToWishlist &&
    css`
      right: unset;
      top: 41px;
      left: 0;
    `}
  ${({ isApplyed }) =>
    isApplyed &&
    css`
      top: 41px;
      right: 0px;
    `}
`;

const ActionWrapper = styled(Flex)`
  flex-shrink: 0;
  display: flex;
  position: relative;
  ${({ isButton }) =>
    isButton &&
    css`
      flex-wrap: wrap;
      flex-direction: column;
      gap: 12px;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      > * {
        margin-left: 0;
        &:first-child {
          margin-left: 0;
        }
      }
    `}
`;

const StyledRoundedButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StyleRoundedButton = styled(RoundedButton)`
  justify-content: center;
  &:hover {
    ${DropdownMenuItmes} {
      display: flex !important;
    }
    ${Dropdown} {
      display: flex !important;
    }
  }
  ${({ $loading }) =>
    $loading &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
  ${({ nobackground }) =>
    nobackground &&
    css`
      background-color: transparent;
      color: #1688fe;
      border: 1px solid #1688fe;
      width: 40% !important;
      &:hover {
        background-color: #f0f8ff;
      }
      &:active {
        background-color: #e6f2ff;
      }
    `}
  &:disabled {
    background-color: #f0f0f0;
    color: #b0b0b0;
    border-color: #d0d0d0;
    pointer-events: none;
  }
`;

const PortalName = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '22px')};
`;

const UrlFlex = styled(Flex)`
  gap: 5px;
`;

const CardTextPrimary = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n500)', '1.54')};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 5px 0;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 100%;
    `}
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin-top: 5px;
    `}
  ${({ hasSalary }) =>
    !hasSalary &&
    css`
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    `}
`;

const TooltipWrap = styled(Flex)`
  display: none;
  position: absolute;
  bottom: 130%;
  width: 100%;
  border-radius: 4px;
  background-color: var(--grayscale-n800);
`;

const TagWrapper = styled(Flex)`
  position: relative;
  cursor: pointer;
  overflow: visible;
  max-width: 418px;
  width: 100%;
  margin-top: 5px;
  gap: 10px;
  &:hover {
    ${TooltipWrap} {
      display: flex;
    }
  }
  ${({ small }) =>
    small &&
    css`
      cursor: default;
    `}
  ${({ hasSalary }) =>
    !hasSalary &&
    css`
      margin-top: 0 !important;
    `}
`;

const Wrapper = styled(Flex)`
  width: 100%;
  gap: 16px;
  ${({ isBoarded }) =>
    isBoarded &&
    css`
      align-items: start;
    `}
`;

const StarFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Link = styled.a``;

const UrlWrapper = styled(Flex)``;

const StyledFlexCompany = styled(Flex)`
  max-width: 100%;
  flex-direction: column;
  @media (min-width: 568px) {
    flex-direction: row;
    gap: 10px;
  }
`;

const CompanyFlex = styled(Flex)`
  flex-shrink: 0;
  margin-right: 8px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-black)', '20px')}
  ${({ isBoarded }) =>
    isBoarded &&
    css`
      flex-direction: column;
      @media (min-width: 1200px) {
        flex-direction: row;
        span {
          margin-left: 5px;
        }
      }
    `}
`;

const HeartFlex = styled(Flex)`
  gap: 10px;
  @media (min-width: 992px) {
    gap: 5px;
    flex-shrink: 0;
  }
`;

const SmallTitle = styled(Flex)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 17px;
  color: var(--dark-values-black);
  line-height: 24px;
  width: 100%;
  ${OneLineCss}
  ${({ small, theme }) =>
    small &&
    css`
      gap: 5px;
      ${theme.jobTrack.renderFonts('Medium', '13', 'var(--dark-values-black)', '20px')};
      @media (min-width: 1440px) {
        flex-direction: row !important;
      }
    `}
  @media(min-width: 1440px) {
    flex-direction: column;
    display: flex;
  }
  ${({ isBoarded }) =>
    isBoarded &&
    css`
      display: flex;
      gap: 5px;
      @media (min-width: 1440px) {
        flex-direction: row;
      }
    `}
`;

const Box = styled(Flex)`
  flex: 1;
  @media (min-width: 1440px) {
    justify-content: end;
  }
  ${({ isBoarded }) =>
    isBoarded &&
    css`
      gap: 4px;
    `}
`;

const SmallTitleBox = styled(Box)`
  width: 100%;
`;

const SmallCompanyWrapper = styled(Flex)`
  pointer-events: none;
  z-index: 9999;
  height: 100%;
  position: sticky;
  top: 0;
`;

const SmallBox = styled(Flex)`
  min-height: 70px;
  width: 100%;
  padding: 12px 24px;
  gap: 8px;
  border-bottom: 1px solid var(--grayscale-n300);
  background-color: #fff;
  flex-direction: column;
  pointer-events: all;
  ${({ isBoarded }) =>
    isBoarded &&
    css`
      @media (min-width: 992px) {
        flex-direction: column;
        align-items: start;
      }
    `}
`;

const StyledFlex = styled(Flex)`
  flex-direction: column;
`;

const CompanyInfo = styled.div`
  user-select: text;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '22px')};
  span {
    margin: 0 4px;
    color: var(--grayscale-n400);
  }
  ${OneLineCss}
  display: inline-block;
  max-width: 450px;
`;

const PostedDate = styled(Flex)`
  gap: 8px;
  margin-left: 5px;
  flex-grow: 1;
  ${OneLineCss}
  display: inline-block;
  word-break: break-word;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--grayscale-n400)', '22px')};
  ${({ small }) =>
    small &&
    css`
      margin-left: 0;
      width: 100%;
      @media (min-width: 768px) {
        display: none;
      }
      @media (min-width: 1200px) {
        display: flex;
        margin-left: 0;
        flex-shrink: 0;
      }
    `}
`;

const DetailInner = styled(Flex)`
  flex-shrink: 0;
  flex-direction: column;
  @media (min-width: 1200px) {
    display: flex;
  }
`;

const CompanyDetailWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 8px;
  flex-direction: column;
  @media (min-width: 1440px) {
    flex-direction: row;
  }
`;

const CompanyTitle = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '24', 'var(--dark-values-black)', '30px')};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  user-select: text;
`;

const CompanyWrapper = styled(Flex)`
  border-top-right-radius: 12px;
  padding: 12px 24px;
  ${({ isMobileCompanyDetail }) =>
    isMobileCompanyDetail &&
    css`
      padding: 0 0px 20px;
    `}
  background-color: var(--light-values-white);
  ${({ isBoarded, isSearchJob }) =>
    isBoarded &&
    !isSearchJob &&
    css`
      padding-top: 24px;
      padding-right: 24px;
      padding-left: 24px;
    `}
`;
