import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import { inputStyle, overrideInputStyleMixin } from '/imports/core/ui/mixins';

const formatPromptOutput = (data) => {
  try {
    return typeof data === 'object' ? JSON.stringify(data, null, 4) : data;
  } catch (e) {
    return data;
  }
};
const PromptRightSide = ({ output, loading }) => {
  return (
    <Wrapper $fullWidth $direction="column">
      <Header $fullWidth $alignItems="center" $justifyContent="center">
        <Title>Output</Title>
        <Button>Send resume in builder</Button>
      </Header>
      {loading && <Title>Task processor loading...</Title>}
      {!loading && output?.response && (
        <>
          <StyledText>Model: {output?.model}</StyledText>
          <StyledText>Input tokens count: {output?.inputToken}</StyledText>
          <StyledText>
            Input Cost: USD {parseFloat(output?.inputCost).toFixed(3)} ({output?.inputCost})
          </StyledText>
          <StyledText>Output tokens count: {output?.outputToken}</StyledText>
          <StyledText>
            Output Cost: USD {parseFloat(output?.outputCost).toFixed(3)} ({output?.outputCost})
          </StyledText>
          <StyledText>Total token: {output?.totalToken}</StyledText>
          <StyledText>
            Total Cost: USD {parseFloat(output?.totalCost).toFixed(3)} ({output?.totalCost})
          </StyledText>
        </>
      )}

      <PromptInput readOnly placeholder="Output will be displayed here" value={formatPromptOutput(output?.response)} />
    </Wrapper>
  );
};

export default PromptRightSide;

const StyledText = styled.span`
  font-family: ${({ theme }) => theme.font.family.websiteBold};
  font-size: 16px;
`;

const Button = styled.button`
  padding: 8px 12px;

  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  background-color: #007bff;
  margin-left: auto;
  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    pointer-events: none;
    background-color: grey;
    width: 129.3px;
    display: flex;
    justify-content: center;
  }
`;

const PromptInput = styled.textarea`
  ${inputStyle}
  ${overrideInputStyleMixin}
  flex: 1;
  width: 100%;
  border: 1px black solid;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;

const Wrapper = styled(Flex)`
  gap: 12px;
  height: 100%;
`;

const Header = styled(Flex)`
  gap: 16px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-weight: bold;
`;
