import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import useIntl from '/imports/core/api/useIntl';

const ResumeListTitle = ({ title, subTitle }) => {
  const { t } = useIntl();
  return (
    <ResumeTitleWrapper direction="column" fullWidth>
      <ResumeTitle>{title}</ResumeTitle>
      <ResumeSubTitle>{subTitle ? subTitle : t('jobtrack_templates_resumes_subtitle')}</ResumeSubTitle>
    </ResumeTitleWrapper>
  );
};

export default ResumeListTitle;

const ResumeTitleWrapper = styled(Flex)`
  margin-top: 8px;
  @media (min-width: 420px) {
    align-items: center;
  }
  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const ResumeTitle = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '24', 'var(--dark-values-black)', '30px')};
  font-weight: 600;
  ${({ theme }) =>
    theme.isRTL &&
    css`
      padding-right: 0;
      padding-left: 45px;
    `}
  @media (min-width: 420px) {
    text-align: center;
  }
  @media (min-width: 768px) {
    text-align: start;
  }
`;

const ResumeSubTitle = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-dark-regular)', '1.47')};
  margin-top: 8px;
  @media (min-width: 420px) {
    text-align: center;
  }
  @media (min-width: 768px) {
    text-align: start;
  }
`;
