import nookies from 'nookies';
import { useEffect } from 'react';

import { UPDATE_RESUME_DETAIL } from 'imports/generator/api/apollo/client/mutations';
import { mkResumeToStr } from 'lib/helpers';
import { useMutation } from 'react-apollo';
import ResumeListItem from '/imports/job-tracking/ui/components/ResumeListItem';
import styled, { css } from 'styled-components';
import Flex from 'imports/core/ui/atoms/Flex';

const ResumeItem = ({ resume, job, originalResume, handleScore }) => {
  const [updateResumeDetail] = useMutation(UPDATE_RESUME_DETAIL);
  const { token } = nookies.get({});
  useEffect(() => {
    let before = resume?.optimizedScoreBefore;
    let after = resume?.optimizedScoreAfter;
    handleScore({ before, after });
  }, [resume]);

  useEffect(() => {
    const getCurrentResumeScore = () => {
      const resumeToStr = mkResumeToStr(resume);
      if (resume?.optimizedScoreAfter > 0) return;
      if (!resumeToStr) return;
      fetch('https://api.resumedone-staging.com/v2/meta/skills-matching-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authozization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          jobDescription: job.jobDescription,
          jobTitle: job.title,
          resume: resumeToStr,
          resumeTitle: resume?.details?.title,
        }),
      })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          const score = data?.soft?.precScore + data?.hard?.precScore + data?.title;
          if (!score) return;
          handleScore({ after: score });
          updateResumeDetail({
            variables: {
              docId: resume.id,
              path: 'optimizedScoreAfter',
              value: score,
            },
          });
          getOriginalResumeScore();
        });
    };
    const getOriginalResumeScore = () => {
      const resumeToStr = mkResumeToStr(originalResume);
      if (!resumeToStr) return;
      if (resume?.optimizedScoreBefore > 0) return;
      fetch('/api/skills-matching-score', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobDescription: job.jobDescription,
          resume: resumeToStr,
          jobTitle: job.title,
          resumeTitle: originalResume?.details?.title,
        }),
      })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
          const score = data?.soft?.precScore + data?.hard?.precScore + data?.title;
          if (!score) return;
          handleScore({ before: score });
          updateResumeDetail({
            variables: {
              docId: resume.id,
              path: 'optimizedScoreBefore',
              value: score,
            },
          });
        });
    };
    if (!resume?.optimizedScoreAfter) {
      getCurrentResumeScore();
    } else {
      getOriginalResumeScore();
    }
  }, []);
  return (
    <GapFlex key={resume?.id} $fullwidth direction="column">
      <Title>Resume Language: {resume?.settings?.language?.toUpperCase()}</Title>
      <ResumeListItem resume={resume} />
    </GapFlex>
  );
};

export default ResumeItem;

const GapFlex = styled(Flex)`
  gap: 8px;
  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 30px;
    `}
  ${({ $withBorderTop }) =>
    $withBorderTop &&
    css`
      border-top: 1px solid var(--grayscale-n200);
      padding-top: 24px;
    `}
  ${({ $withMarginBottom }) =>
    $withMarginBottom &&
    css`
      margin-bottom: 24px;
    `}
`;

const Title = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  margin-bottom: 6px;
  line-height: 24px;
  ${({ $open }) =>
    $open &&
    css`
      padding-bottom: 24px;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
  ${({ $jobTemplate }) =>
    $jobTemplate &&
    css`
      color: #019ade;
    `}
  ${({ $diffHover }) =>
    $diffHover &&
    css`
      &:hover {
        color: #019ade;
      }
    `}
  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      color: var(--grayscale-n400);
    `}
  ${({ $big }) =>
    $big &&
    css`
      font-size: 24px;
    `}
`;
