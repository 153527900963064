import compose from 'lodash.flowright';
import moment from 'moment';
import { withRouter } from 'next/router';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { PureComponent } from 'react';
import { graphql } from 'react-apollo';
import styled, { css } from 'styled-components';

import DownloadOptionsDropdown from '/imports/job-tracking/ui/components/DownloadOptionsDropdown';
import { A4_HEIGHT, A4_WIDTH } from '/imports/pdf/core/api/constants';
// import DownloadOptionsDropdown from '/imports/generator/ui/components/DownloadOptionsDropdown';
import { getIsAgent } from 'imports/generator/api/helpers';
import { Push } from '/components/Link';
import { withAccount } from '/imports/core/api/accounts/accountContext';
import { withConfirm } from '/imports/core/api/confirm';
import { withGlobalContext } from '/imports/core/api/global.context';
import { withResponsiveContext } from '/imports/core/api/responsiveContext';
import { withIntl } from '/imports/core/api/useIntl';
import EditableTitleAtom from '/imports/core/ui/atoms/EditableTitle';
import Flex from '/imports/core/ui/atoms/Flex';
import { DUPLICATE_RESUME, REMOVE_RESUME } from '/imports/dashboard/api/apollo/client/mutations';
import { LIST_RESUMES } from '/imports/dashboard/api/apollo/client/queries';
import { UPDATE_RESUME_DETAIL } from '/imports/generator/api/apollo/client/mutations';
import DuplicateIcon from '/imports/job-tracking/ui/assets/DuplicateIcon';
import IconDelete from '/imports/job-tracking/ui/assets/IconDelete';
import IconDownload from '/imports/job-tracking/ui/assets/IconDownload';
import IconEdit from '/imports/job-tracking/ui/assets/IconEdit';
import { cdnFile, isPaidUser } from '/lib/helpers';

@withResponsiveContext
@withIntl
@withConfirm
@withAccount
@withGlobalContext
@withRouter
@compose(
  graphql(DUPLICATE_RESUME, { name: 'duplicate' }),
  graphql(REMOVE_RESUME, { name: 'remove' }),
  graphql(UPDATE_RESUME_DETAIL, { name: 'updateResumeDetail' }),
)
class ResumeItem extends PureComponent {
  static propTypes = {
    resume: PropTypes.object,
    remove: PropTypes.func,
    duplicate: PropTypes.func,
    updateResumeDetail: PropTypes.func,
    confirm: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.resumeItem = React.createRef();
    this.downloadWrapper = React.createRef();
  }

  get clientWidth() {
    return this.resumeItem.current?.clientWidth;
  }

  _dateFormat = 'DD MMMM, HH:mm';

  state = {
    confirm: null,
    initialWidth: null,
    show: false,
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ width: this.clientWidth });
  }

  goTo = (e) => {
    const {
      disableEdit,
      router,
      context: { handleUpdateState },
      langauges,
      resume,
      currentUser,
    } = this.props;
    if (disableEdit) return;
    if (this.downloadWrapper.current && this.downloadWrapper.current.contains(e.target)) {
      return;
    }
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    const { locale } = this.props;
    const step = resume.currentStep || 'start';
    const queryString = {
      resumeId: resume.id,
      step: step,
      from: router.asPath,
      template: resume.settings.template,
      userId: currentUser?.id,
    };
    const obj = {
      id: resume.id,
      langauges,
    };
    handleUpdateState('langauges', langauges);
    typeof localStorage !== 'undefined' && localStorage.setItem('filteroutLanguages', JSON.stringify(obj));
    Push(`/resume?${qs.stringify(queryString)}`, locale, `/resume/${resume.id}/${step}?userId=${currentUser?.id}`);
  };

  updateTitle = (value) => {
    const {
      resume: { id: docId },
      updateResumeDetail,
    } = this.props;
    updateResumeDetail({
      variables: {
        docId,
        path: 'name',
        value,
      },
    });
  };

  remove = (e) => {
    if (e) e.stopPropagation();

    const {
      remove,
      resume: { id: resumeId },
      confirm,
      t,
    } = this.props;

    confirm({
      title: t('dashboard_resume_item_confirm_title'),
      text: t('dashboard_resume_item_confirm_text'),
      confirmText: t('delete'),
      onConfirm() {
        remove({
          variables: {
            resumeId,
          },
          refetchQueries: [{ query: LIST_RESUMES }],
        });
      },
    });
  };

  duplicate = async (e) => {
    if (e) e.stopPropagation();
    this.setState({
      isLoading: true,
    });
    const {
      duplicate,
      resume: { id: resumeId },
    } = this.props;
    const dupesCountIncremented = await this.incrementDupesCount();

    if (dupesCountIncremented) {
      duplicate({
        variables: {
          resumeId,
        },
        refetchQueries: [{ query: LIST_RESUMES }],
      }).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  incrementDupesCount = () => {
    const {
      resume: { id: docId, dupesCount },
      updateResumeDetail,
    } = this.props;

    const value = dupesCount === null ? 1 : dupesCount + 1;

    return updateResumeDetail({
      variables: {
        docId,
        path: 'dupesCount',
        value,
      },
    });
  };

  // Gets either the updated or creation date.
  get date() {
    const {
      resume: { updatedAt, createdAt },
      locale,
    } = this.props;
    return moment(updatedAt || createdAt)
      .locale(locale)
      .format(this._dateFormat);
  }

  handleGenerateWebsite = () => {
    const { source: { hasWebsite } = {} } = this.props;
    if (hasWebsite) return;
  };

  onMouseEnter = () => {
    this.setState({
      show: true,
    });
  };

  toggleOffDropdown = () => {
    this.setState({
      show: false,
    });
  };

  toggleDropdown = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    const {
      resume: {
        id,
        name,
        currentStep,
        hasWebsite,
        settings: { template },
      },
      resume,
      currentUser: { role },
      t,
      variant,
      showIcons,
      disableEdit,
    } = this.props;
    const { initialWidth } = this.state;
    const { agent_client_id } = nookies.get({});
    const width = this.clientWidth || initialWidth;
    const scale = width / A4_WIDTH;
    const isAgent = !!agent_client_id;
    const isPaid = isPaidUser(role) || getIsAgent();

    return (
      <Wrapper direction="column">
        <ResumePreviewWrapper scale={scale} ref={this.resumeItem} onClick={this.goTo} disableEdit={disableEdit}>
          <Template template={resume.settings.template} />

          <ControlElements alignItems="center">
            <Flex fullWidth justifyContent="center">
              {!disableEdit && (
                <ButtonWrapper direction="column">
                  <ButtonInner justifyContent="center" alignItems="center" onClick={this.goTo}>
                    <IconEdit />
                  </ButtonInner>
                  <ButtonText>{t('edit')}</ButtonText>
                </ButtonWrapper>
              )}
              {(!isAgent || showIcons) && (
                <ButtonWrapper direction="column">
                  <StyledButtonInner onClick={this.duplicate} disabled={this.state.isLoading}>
                    <DuplicateIcon />
                  </StyledButtonInner>
                  <ButtonText>{t('duplicate')}</ButtonText>
                </ButtonWrapper>
              )}
              {currentStep === 'finish' && isPaid && (
                <ButtonWrapper
                  ref={this.downloadWrapper}
                  direction="column"
                  onMouseOver={this.onMouseEnter}
                  onClick={this.toggleDropdown}
                  onMouseLeave={this.toggleOffDropdown}
                >
                  <ButtonInner justifyContent="center" alignItems="center">
                    <IconDownload />
                  </ButtonInner>
                  <DownloadOptionsDropdown show={this.state.show} source={resume} />
                </ButtonWrapper>
              )}
              {(!isAgent || showIcons) && (
                <ButtonWrapper direction="column">
                  <ButtonInner justifyContent="center" alignItems="center" onClick={this.remove}>
                    <IconDelete />
                  </ButtonInner>
                  <ButtonText>{t('delete')}</ButtonText>
                </ButtonWrapper>
              )}
            </Flex>
          </ControlElements>
        </ResumePreviewWrapper>
        <ResumeTitleWrapper direction="column" fullWidth>
          <EditableTitle
            onSave={this.updateTitle}
            onClick={this.goTo}
            defaultvalue={name}
            visibleIcon={1}
            isShortTitle
          />
          <ControlDescription>
            {t('dashboard.updated')} {this.date}
          </ControlDescription>
        </ResumeTitleWrapper>
      </Wrapper>
    );
  }
}

const Template = styled.div`
  ${({ template }) =>
    template &&
    css`
      background-image: url(${cdnFile(`resumes/${template}.jpg`)});
    `}
  width: 100%;
  padding-top: 140.03868472%;
  position: relative;
  background-position: center;
  background-size: contain;
`;

const StyledButtonInner = styled.button`
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 8px 24px 0 rgba(29, 44, 67, 0.12);
  background-color: var(--light-values-white);
  &:hover {
    background-color: var(--light-values-light-medium);
    #svg-dynamic-fill {
      fill: var(--dark-values-black);
    }
  }
  #svg-dynamic-fill {
    fill: var(--dark-values-dark-regular);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const ButtonText = styled.p`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--light-values-white)', '1.54')};
  text-align: center;
  margin: 0 auto;
  display: none;
  position: absolute;
  top: 48px;
  left: 50%;
  transform: translate(-50%, 0);
`;

const ButtonWrapper = styled(Flex)`
  margin: 0 6px;
  position: relative;
  &:hover {
    ${ButtonText} {
      display: block;
    }
    .styled-stroke {
      stroke: #1d2c43;
    }
    .styled-fill {
      fill: #1d2c43;
    }
  }
`;

const ButtonInner = styled(Flex)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 8px 24px 0 rgba(29, 44, 67, 0.12);
  background-color: var(--light-values-white);
  &:hover {
    background-color: var(--light-values-light-medium);
    #svg-dynamic-fill {
      fill: var(--dark-values-black);
    }
  }
  #svg-dynamic-fill {
    fill: var(--dark-values-dark-regular);
  }
`;

const ControlDescription = styled.p`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--mid-values-gray-light)', '1.54')};
  font-weight: 500;
  margin: 0;
`;

const ResumeTitleWrapper = styled(Flex)`
  padding: 16px;
`;

const ControlElements = styled(Flex)`
  position: absolute;
  inset: 0 0 0 0;
  background-color: var(--transparents-opacity-48);
  border: solid 1px var(--transparents-opacity-12);
  display: none;
  z-index: 1000;
`;

const ResumePreviewWrapper = styled(Flex)`
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  width: 100%;
  height: ${(p) => A4_HEIGHT * p.scale}px;
  position: relative;
  border: solid 1px var(--transparents-opacity-12);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: var(--light-values-white);
  ${({ disableEdit }) =>
    disableEdit &&
    css`
      cursor: default;
    `}
`;

const Wrapper = styled(Flex)`
  overflow: hidden;
  min-width: 288px;
  max-width: 288px;
  height: 498px;
  cursor: pointer;
  border-radius: 12px;
  background-color: var(--light-values-light-extra);
  &:hover {
    box-shadow: 0 4px 16px 0 var(--transparents-opacity-8);
    background-color: var(--light-values-white);
    ${ControlElements} {
      display: flex;
    }
  }
  @media (min-width: 768px) {
    min-width: 314px;
    max-width: 314px;
  }
  @media (min-width: 992px) {
    min-width: 201px;
    max-width: 201px;
    height: 364px;
  }
  @media (min-width: 1200px) {
    min-width: 206px;
    max-width: 206px;
  }
  @media (min-width: 1440px) {
    min-width: 232px;
    max-width: 232px;
    height: 360px;
  }
`;

export const EditableTitle = styled(EditableTitleAtom)`
  position: relative;
  margin: 0 0 0 -5px;
  font-size: 19px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '1.47')};
  button {
    opacity: 1;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export default ResumeItem;
