import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const NotificationDot = styled(Flex)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  flex-shrink: 0;
  margin-left: 8px;
  background-image: radial-gradient(
    circle at 8% 97%,
    #fac05e,
    #fac05e 23%,
    var(--primary) 23%,
    var(--primary) 55%,
    var(--primary) 66%,
    var(--additional-colors-pink) 66%,
    var(--additional-colors-pink) 100%
  );
`;

export default NotificationDot;
