import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const Title = styled.div`
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
`;

const Details = styled.div`
  margin-top: 10px;
`;

const SkeletonComponent = () => {
  return (
    <Wrapper>
      {/* Title Skeleton */}
      <Title>
        <Skeleton width={300} height={24} />
      </Title>

      {/* Subtitle Skeleton */}
      <Skeleton width={200} height={16} />

      {/* Buttons Skeleton */}
      <ButtonWrapper>
        <Skeleton width={120} height={40} />
        <Skeleton width={100} height={40} />
      </ButtonWrapper>

      {/* Content Skeleton */}
      <Details>
        <Skeleton width={180} height={18} />
        <Skeleton width={350} height={16} style={{ margin: '10px 0' }} />
        <Skeleton width="100%" height={400} />
      </Details>
    </Wrapper>
  );
};

export default SkeletonComponent;
