import React from 'react';
import styled, { css } from 'styled-components';

const MoreOptionsIcon = ({ color, vertical = false, className = '' }) => {
  return (
    <Svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      vertical={vertical}
      className={className}
    >
      <path
        d="M8 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM13.5 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM2.5 6.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3z"
        fill="#4C4C55"
      />
    </Svg>
  );
};

export default MoreOptionsIcon;

const Svg = styled.svg`
  ${({ color }) =>
    color &&
    css`
      path {
        fill: ${color} !important;
      }
    `}
  ${({ vertical }) =>
    vertical &&
    css`
      transform: rotate(90deg);
    `}
`;
