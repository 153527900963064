import Flex from 'imports/core/ui/atoms/Flex';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import styled, { css } from 'styled-components';
import { PDF_LOADER } from '/imports/generator/api/constants';

const SavedJobSkeleton = () => {
  return (
    <SkeletonTheme highlightColor="#dbdbdb">
      <UpperSection $fullWidth>
        <Skeleton />
      </UpperSection>
      <DataDisplaySection $fullWidth direction="column">
        <TopFlex $fullWidth justifyContent="space-between">
          <Block $size={300}>
            <Skeleton />
          </Block>
          <Block $size={100}>
            <Skeleton />
          </Block>
        </TopFlex>
        <ResumeSection $fullWidth>
          {[...Array(4)].map((_, i) => (
            <ResumeListWrapper direction="column" key={i}>
              <ResumeHero src={PDF_LOADER} />
            </ResumeListWrapper>
          ))}
        </ResumeSection>
        {[...Array(4)].map((_, index) => (
          <ExtraBlock $fullWidth justifyContent="space-between" key={index}>
            <Block $size={300}>
              <Skeleton />
            </Block>
            <Block $size={100}>
              <Skeleton />
            </Block>
          </ExtraBlock>
        ))}
      </DataDisplaySection>
    </SkeletonTheme>
  );
};

export default SavedJobSkeleton;

const ExtraBlock = styled(Flex)`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-values-gray-light);
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ResumeSection = styled(Flex)`
  padding: 16px 0;
  margin-top: 30px;
  overflow-x: hidden;
`;

const TopFlex = styled(Flex)`
  border-bottom: 1px solid var(--grayscale-n200);
  padding-bottom: 16px;
`;

const Block = styled.div`
  ${({ $size }) =>
    $size &&
    css`
      min-width: ${$size}px;
    `}
`;

const DataDisplaySection = styled(Flex)`
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid var(--neutral-values-gray-light);
  padding: 16px;
`;

const UpperSection = styled(Flex)`
  max-width: 300px;
  width: 100%;
  line-height: 24px;
  font-size: 24px;
  margin-top: 10px;

  > span {
    width: 100%;
  }
`;

const ResumeListWrapper = styled(Flex)`
  box-shadow: 0 3px 16px 0 rgba(29, 44, 67, 0.04);
  border: 1px solid #dbdbdb;
  min-width: 100%;
  max-width: 100%;
  margin-right: 25px;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 768px) {
    min-width: 236.8px;
    max-width: 236.8px;
  }
`;

const ResumeHero = styled.img`
  height: 335.20277078085644px;
  width: 100%;
  background-color: #f5f8fc;
`;
