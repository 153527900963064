import Flex from 'imports/core/ui/atoms/Flex';
import qs from 'qs';
import Container from '/imports/job-tracking/ui/atoms/Container';
import React from 'react';
import styled from 'styled-components';
import ResumeListItem from '/imports/job-tracking/ui/components/ResumeListItem';
import ResumeListTitle from '/imports/job-tracking/ui/atoms/ResumeListTitle';
import { useQuery } from 'react-apollo';
import { LIST_RESUMES } from 'imports/dashboard/api/apollo/client/queries';
import JobTrackListsSkeleton from '/imports/job-tracking/ui/skeleton/JobTrackListsSkeleton';
import ErrorOccured from '/imports/core/ui/components/ErrorOccured';
import BackArrorw from 'imports/carrer/atmos/BackArrorw';
import { Push } from 'components/Link';
import intlHook from '/imports/core/api/useIntl';

const JobSearch = ({ id }) => {
  const { locale } = intlHook();
  const { data, error, loading } = useQuery(LIST_RESUMES);
  const groupResumes = (resumes) => {
    const sortedResume = resumes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    const jobSearchTemplates = sortedResume?.filter((k) => k.agentSearchId === id && k.agentJobId);
    const searchTemplateName = sortedResume?.filter((k) => k.agentSearchId === id && !k.agentJobId)[0]?.name;

    return { jobSearchTemplates, searchTemplateName };
  };

  const loadingComp = <JobTrackListsSkeleton />;
  if (error) return <ErrorOccured error={error} />;
  if (loading) return loadingComp;

  const resumes = groupResumes(data?.listResumes);

  const handleBack = () => {
    const queryString = {
      template: 'dashboard',
    };
    Push(`/app?${qs.stringify(queryString)}`, locale, '/app/dashboard');
  };

  return (
    <Inner fullWidth>
      <Wrapper fullWidth>
        <SvgWrap onClick={handleBack}>
          <BackArrorw />
        </SvgWrap>
        <ResumeListTitle
          title={'Job Template Resumes'}
          subTitle={`Search Template Name: ${resumes?.searchTemplateName}`}
        />
        <GapFlex fullWidth>
          {resumes?.jobSearchTemplates?.map((resume) => (
            <ResumeWrap key={resume.id} direction="column">
              <Title>Resume Language: {resume.settings?.language.toUpperCase()}</Title>
              <ResumeListItem key={resume.id} resume={resume} />
            </ResumeWrap>
          ))}
        </GapFlex>
      </Wrapper>
    </Inner>
  );
};

export default JobSearch;

const Title = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 24px;
`;

const SvgWrap = styled(Flex)`
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const ResumeWrap = styled(Flex)`
  gap: 8px;
`;

const GapFlex = styled(Flex)`
  gap: 32px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

const Wrapper = styled(Container)`
  flex-direction: column;
`;

const Inner = styled(Flex)`
  padding: 32px 0;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    padding-top: 48px;
  }
  @media (min-width: 992px) {
    padding-left: 25px;
  }
`;
