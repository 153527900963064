import React from 'react';

const FavIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_557_1637)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.65518 1.46593C7.2054 0.351062 8.79517 0.351056 9.34539 1.46593L10.9388 4.69451L14.5017 5.21223C15.7321 5.39101 16.2233 6.90297 15.3331 7.77077L12.7549 10.2839L13.3635 13.8324C13.5737 15.0578 12.2875 15.9922 11.1871 15.4137L8.00028 13.7383L4.81349 15.4137C3.71305 15.9922 2.4269 15.0578 2.63706 13.8324L3.24569 10.2839L0.667512 7.77077C-0.222761 6.90297 0.268497 5.39101 1.49883 5.21223L5.06178 4.69451L6.65518 1.46593ZM8.00028 3.25955L6.62258 6.05108C6.47692 6.34622 6.19535 6.55079 5.86964 6.59812L2.78901 7.04576L5.01818 9.21867C5.25386 9.4484 5.36141 9.7794 5.30577 10.1038L4.77954 13.172L7.53494 11.7234C7.82627 11.5702 8.1743 11.5702 8.46562 11.7234L11.221 13.172L10.6948 10.1038C10.6392 9.7794 10.7467 9.4484 10.9824 9.21867L13.2116 7.04576L10.1309 6.59812C9.80521 6.55079 9.52365 6.34622 9.37799 6.05108L8.00028 3.25955Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_557_1637">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FavIcon;
