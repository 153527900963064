import styled, { css } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const DropdownOption = styled(Flex)`
  padding: 8px 12px;
  border-radius: 8px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-dark-regular)', '1.54')};
  transition: background-color 0.15s linear;
  svg {
    margin-left: auto;
    ${({ noMargin }) =>
      noMargin &&
      css`
        margin: 0;
      `}
  }
  &:hover {
    background-color: var(--light-values-light-extra);
    color: var(--dark-values-dark-regular);
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--light-values-light-extra);
      color: var(--dark-values-black);
    `}
  ${({ searchPageDropDown, theme }) =>
    searchPageDropDown &&
    css`
      ${theme.jobTrack.renderFonts('Bold', '12', 'var(--dark-values-dark-regular)', '1.5')};
    `}

  ${({ withBackground }) =>
    withBackground &&
    css`
      background-color: var(--light-values-white);
      border-radius: 0;
      border-top: 1px solid var(--light-values-light-medium);
    `}
  ${({ searchDropDown }) =>
    searchDropDown &&
    css`
      gap: 9px;
      align-items: center;
    `}
  ${({ leftText }) =>
    leftText &&
    css`
      text-align: left;
    `}
`;

export default DropdownOption;
