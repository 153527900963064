import PromptLeftSide from '../components/prompt/PromptLeftSide';
import PromptRightSide from '../components/prompt/PromptRightSide';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Flex from '/imports/core/ui/atoms/Flex';
import { getTokenCostPerModel } from 'imports/job-tracking/api/helper';

import { executePrompt, retrieveTaskProcessorData, executePromptWithoutSave } from 'imports/job-tracking/api/api';

const PromptTesting = () => {
  const [currentTask, setCurrentTask] = useState({ taskId: '', firstRetryAfter: 0 });
  const [output, setOutput] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let interval;
    const fetchTaskProcessorData = async (taskId) => {
      try {
        const data = await retrieveTaskProcessorData(taskId);
        if (data?.status == 'processing') {
          return;
        }
        setLoading(false);
        clearInterval(interval);
        setOutput({ ...data, ...getTokenCostPerModel(data) });
      } catch (e) {
        setLoading(false);
        clearInterval(interval);
        console.log(e);
        setOutput(e.message);
      }
    };
    if (currentTask?.taskId) {
      setLoading(true);
      fetchTaskProcessorData(currentTask.taskId);
      interval = setInterval(() => fetchTaskProcessorData(currentTask.taskId), 5000);
    }
    return () => clearInterval(interval);
  }, [currentTask]);

  const execPrompt = async (data) => {
    try {
      if (!data?.promptId && !data?.model) return;
      setLoading(true);
      let resp = data.promptId
        ? await executePrompt(data.promptId)
        : data.model
          ? await executePromptWithoutSave(data)
          : null;
      if (resp.data) {
        setCurrentTask(resp.data);
      } else {
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Wrapper $fullWidth>
      <Side>
        <PromptLeftSide executePrompt={execPrompt} />
      </Side>
      <Side>
        <PromptRightSide output={output} loading={loading} />
      </Side>
    </Wrapper>
  );
};

export default PromptTesting;

const Wrapper = styled(Flex)`
  max-width: 100%;
  padding: 16px;
  height: 100vh;
`;

const Side = styled.div`
  width: 50%;
  border: 1px black solid;
  padding: 16px;
  height: 100%;
`;
