import parse from 'html-react-parser';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';

import searchApi from 'imports/job-tracking/api/searchApi';
import { useRouter } from 'next/router';
import { useSearchStore } from 'zustand/SearchStore';
import env from '/env';
import useIntl from '/imports/core/api/useIntl';
import Flex from '/imports/core/ui/atoms/Flex';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import {
  capitalizeAllFirstLetter,
  getCityCountry,
  isFoundJob,
  processJobDataForPortal,
} from '/imports/job-tracking/api/helper';
import { DeleteIconStyled } from '/imports/job-tracking/ui/assets/DeleteIcon';
import WishlistHeartIcon from '/imports/job-tracking/ui/assets/WishlistHeartIcon';
import Chip from '/imports/job-tracking/ui/atoms/Chip';
import DropdownInner from '/imports/job-tracking/ui/atoms/DropdownInner';
import DropdownMenuItemWrapper from '/imports/job-tracking/ui/atoms/DropdownMenuItemWrapper';
import DropdownMenuItmes from '/imports/job-tracking/ui/atoms/DropdownMenuItems';
import DropdownOption from '/imports/job-tracking/ui/atoms/DropdownOption';
import { compareStrings, filteredRenderedJobType, getPortalName } from '/lib/helpers';
import CardSkeleton from '/imports/job-tracking/ui/skeleton/CardSkeleton';

function getDescriptionPreview(description) {
  const hasHTMLEntity = (text) => /(&[#A-Za-z0-9]+;)|(<\/?[a-z][\s\S]*>)/i.test(text);
  while (true) {
    const parsed = parse(description);
    if (typeof parsed === 'string' && hasHTMLEntity(parsed)) {
      description = parsed;
    } else {
      break;
    }
  }
  const array = description.split(/(<\/?\s*(p|li|ol)\s*>|<br\s*\/?>|\n)/i);
  let extractedPart1 = '';
  let extractedPart2 = '';
  for (let i = 0; i < array.length; i++) {
    const htmlEl = document.createElement('div');
    htmlEl.innerHTML = array[i];
    const textContent = htmlEl.textContent.replace(/^[^a-zA-Z0-9]*/, ''); //remove only leading characters that are not alphanumeric
    if (textContent !== '' && textContent.length >= 40) {
      if (extractedPart1 === '') {
        extractedPart1 = textContent;
      } else {
        extractedPart2 = textContent;
        break;
      }
    }
  }
  if (!extractedPart1) return [description];
  if (!extractedPart2) return [extractedPart1];
  return [extractedPart1, extractedPart2];
}
const JobCard = ({
  job,
  onClickCard,
  isSkeleton = false,
  isDropDown,
  handleDeleteConfirm,
  isPlain = false,
  selected = false,
  border,
  searchPage,
  isReadOnly,
  jobTrack,
  isSaved,
  handleDirectJobAdd,
  parentsavedPodioJob,
}) => {
  const { searchTextData, cityTextData, candidateSearchs, searchfeedId } = useSearchStore();
  const data = withBoards();
  const { locale, t } = useIntl();
  const isProd = env.NODE_ENV === 'production';
  const { query } = useRouter();
  const isFeed = query?.template === 'feeds';
  const { usesavedJobsOnPodio } = searchApi();
  const { mutate } = usesavedJobsOnPodio();

  moment.locale(locale);
  if (isSkeleton) return <CardSkeleton isPlain={isPlain} isReadOnly={isReadOnly} />;
  let show = true;
  const actionRef = useRef(null);
  const jobType = useMemo(() => job?.contract || job?.jobType, [job]);
  const req = useMemo(() => job?.requirements && job?.requirements.slice(0, 2), [job]);
  const handleClickCard = (e) => {
    if (actionRef?.current && actionRef.current.contains(e.target)) {
      return;
    }
    onClickCard(job)(e);
  };

  const showOptions = data?.state?.boards?.length > 1;

  const handleJobSaved = async () => {
    const isFound = isFoundJob(candidateSearchs, searchTextData, cityTextData);
    let obj = {
      title: job.title,
      location: job.location,
      jobLink: job.reDirectionUrl ? job.reDirectionUrl : job.url,
      jobId: job.id,
      jobProvider: job.portal,
      companyName: job?.company,
      jobDescription: job?.description,
      salaryRange: job?.salaryRange,
      employmentType: job?.employmentType,
      searchName: job?.searchName,
    };
    if (searchfeedId) {
      obj.jobSearchId = searchfeedId;
    }
    if (isFound) {
      obj.podioItemId = isFound.podioItemId;
    }
    obj = processJobDataForPortal(obj, job?.portals || []);

    mutate(obj);
    // if (typeof setPodioJobs !== 'undefined') setPodioJobs([...podioJobs, response]);
  };
  return (
    <Card
      direction="column"
      onClick={handleClickCard}
      isPlain={isPlain}
      selected={selected}
      border={border}
      id={job.ctsId}
      searchPage={searchPage}
      jobTrack={jobTrack}
    >
      <Flex direction="column" fullWidth>
        <TitleWrpper alignItems="flex-start" justifyContent="space-between" fullWidth>
          <StyledFlex alignItems="center">
            <CardTextSecondary>{capitalizeAllFirstLetter(job?.title)}</CardTextSecondary>
          </StyledFlex>
          {isDropDown && (
            <ActionWrapper ref={actionRef} showOptions={showOptions && !isSaved}>
              {!parentsavedPodioJob && isFeed ? (
                <HeartWrapper notSaved onClick={handleJobSaved}>
                  <WishlistHeartIcon />
                </HeartWrapper>
              ) : (
                isFeed && (
                  <HeartWrapper>
                    <WishlistHeartIcon />
                  </HeartWrapper>
                )
              )}
              <Dropdown>
                <DropdownMenuItmes>
                  {showOptions ? (
                    <DropdownInner>
                      {searchPage ? (
                        <>
                          {!isSaved &&
                            data?.state?.boards?.map((board, k) => (
                              <DropdownOption
                                searchDropDown
                                onClick={handleDirectJobAdd(job, board.boardId)}
                                key={k}
                                justifyContent="flex-start"
                              >
                                <SvgWrapper>
                                  <WishlistHeartIcon />
                                </SvgWrapper>
                                {board.boardName}
                              </DropdownOption>
                            ))}
                        </>
                      ) : (
                        <>
                          <DropdownOption onClick={handleDeleteConfirm(job)}>
                            {t('jobtrack_job_card_delete_option_text')}
                          </DropdownOption>
                        </>
                      )}
                    </DropdownInner>
                  ) : (
                    <DropdownInner>
                      {searchPage ? (
                        <>
                          {!parentsavedPodioJob ? (
                            <DropdownOption searchDropDown onClick={handleJobSaved}>
                              <SvgWrapper>
                                <WishlistHeartIcon />
                              </SvgWrapper>
                              {t('jobtrack_job_card_save_option_text')}
                            </DropdownOption>
                          ) : (
                            <DropdownOption searchDropDown>
                              <SvgWrapper noStroke>
                                <DeleteIconStyled dark />
                              </SvgWrapper>
                              {t('jobtrack_job_card_remove_option_text')}
                            </DropdownOption>
                          )}
                        </>
                      ) : (
                        <HeartWrapper notSaved>
                          <WishlistHeartIcon />
                        </HeartWrapper>
                      )}
                    </DropdownInner>
                  )}
                </DropdownMenuItmes>
              </Dropdown>
            </ActionWrapper>
          )}
        </TitleWrpper>
        <CardTextPrimary>{job.company}</CardTextPrimary>
        {(job.city || job.country) && <CardTextPrimary>{getCityCountry(job.city, job.country)}</CardTextPrimary>}
        {job?.location && <CardTextPrimary>{job?.location}</CardTextPrimary>}
        <TagsWrapper wrap="wrap" direction="column" fullWidth>
          <Flex fullWidth wrap="wrap">
            <ReqFlex wrap="wrap" fullWidth>
              {job?.salary && (
                <Chip
                  colorCode={3}
                  alignItems="center"
                  value={isProd ? job.salary : `OS: ${job.salary}`}
                  fullWidth
                  withMarginBottom
                  withSalary
                />
              )}
              {job?.providerSalary && !isProd && (
                <Chip
                  colorCode={3}
                  alignItems="center"
                  value={`${getPortalName(job.portal)}: ${job?.providerSalary}`}
                  fullWidth
                  withMarginBottom
                  withSalary
                />
              )}
              {filteredRenderedJobType(jobType)?.map((k, i) => {
                if (job?.contractCategory && compareStrings(k, job?.contractCategory)) show = false;
                return (
                  <Chip
                    colorCode={3}
                    alignItems="center"
                    value={isProd ? k : `OS: ${k}`}
                    fullWidth
                    withMarginBottom
                    key={i}
                    withJob
                  />
                );
              })}
              {job?.contractCategory && show && (
                <Chip
                  colorCode={3}
                  alignItems="center"
                  value={`${
                    !isProd ? `${getPortalName(job.portal)}: ${job?.contractCategory}` : job?.contractCategory
                  }`}
                  fullWidth
                  withMarginBottom
                  withJob
                />
              )}
              {job?.shift?.map((k, i) => (
                <Chip colorCode={3} alignItems="center" value={k} fullWidth withMarginBottom key={i} withShift />
              ))}
              {job.isRemote && <Chip colorCode={4} alignItems="center" value="Remote" fullWidth withMarginBottom />}
            </ReqFlex>
            <ReqFlex>
              {job?.requirements && (
                <Flex fullWidth Wrap="flex-wrap" direction="column">
                  <Styledul>
                    {req?.map((k, i) => (
                      <ReuirementsList key={i}>{k}</ReuirementsList>
                    ))}
                  </Styledul>
                </Flex>
              )}
            </ReqFlex>
          </Flex>
          <Flex direction="column" fullWidth>
            {job?.description && (
              <Flex direction="column" fullWidth>
                <StyledUl>
                  {getDescriptionPreview(job?.description || '').map((e, i) => (
                    <Styledli key={i}>
                      <DescriptionPreview
                        dangerouslySetInnerHTML={{
                          __html: e,
                        }}
                      />
                    </Styledli>
                  ))}
                </StyledUl>
              </Flex>
            )}
          </Flex>
        </TagsWrapper>
      </Flex>
      <CardFooter fullWidth alignItems="center">
        <Period>
          {(isSaved
            ? `${t('jobtrack_job_card_period_saved_text')} `
            : `${t('jobtrack_job_card_period_posted_text')} `) + job.datePosted}
        </Period>
        {job?.isEasyApply && (
          <EasyApplyTag textAlign="center" display="inline-flex" alignItems="center">
            {t('jobtrack_easyapply_button')}
          </EasyApplyTag>
        )}
      </CardFooter>
    </Card>
  );
};

export default JobCard;

const HeartWrapper = styled.div`
  width: 16px;
  height: 16px;
  svg > path {
    stroke: red;
    fill: red;
  }
  ${({ notSaved }) =>
    notSaved &&
    css`
      cursor: pointer;
      svg > path {
        stroke: var(--dark-values-black);
        fill: var(--light-values-white);
      }
    `}
`;

const Styledul = styled.ul`
  margin: 0;
  padding-left: 17px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;
const StyledUl = styled.ul`
  margin: 0;
  padding-left: 17px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
  max-width: 100%;
`;

const ReuirementsList = styled.li`
  ${({ theme }) => theme.jobTrack.renderFonts('Regular', '13', 'var(--grayscale-n500)', '18px')};
`;
const Styledli = styled.li`
  max-width: 100%;
`;
const DescriptionPreview = styled.span`
  max-width: 100%;
  ${OneLineCss}
  ${({ theme }) => theme.jobTrack.renderFonts('Regular', '13', '#14141F', '18px')};
`;

const ReqFlex = styled(Flex)`
  gap: 4px;
`;

const SvgWrapper = styled.div`
  width: 16px;
  height: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  ${(p) =>
    !p.noStroke &&
    css`
      svg > path {
        stroke: var(--dark-values-black);
      }
    `}
`;

const Dropdown = styled(DropdownMenuItemWrapper)`
  top: 16px;
  padding-top: 4px;
  right: -4px;
  display: none;
`;

const ActionWrapper = styled(Flex)`
  margin-top: 3px;
  flex-shrink: 0;
  display: flex;
  margin-left: 18px;
  position: relative;
  &:hover {
    ${HeartWrapper} {
      svg > path {
        stroke: red;
      }
    }
  }
  ${({ showOptions }) =>
    showOptions &&
    css`
      &:hover {
        ${DropdownMenuItmes} {
          display: flex;
        }
        ${Dropdown} {
          display: flex;
        }
        & > .option {
          path {
            fill: #1688fe !important;
          }
        }
      }
    `}
`;

const Period = styled(Flex)`
  padding-right: 8px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n400)', '1.54')};
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 10%;
    `}
`;

const EasyApplyTag = styled(Flex)`
  padding-left: 5px;
  padding-right: 5px;
  color: #1688fe;
  border: 1px solid #1688fe;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  margin-left: auto;
  cursor: pointer;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 10%;
    `}
`;

const CardFooter = styled(Flex)`
  margin-top: auto;
`;

const TagsWrapper = styled(Flex)`
  margin: 4px 0px 8px 0;
`;

const CardTextSecondary = styled.div`
  user-select: none !important;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '18', 'var(--dark-values-black)', '1.47')};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 100%;
    `}
`;

const MoreOptions = styled(Flex)`
  position: absoulte;
  right: 12px;
  display: none;
`;

const CardTextPrimary = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n500)', '1.54')};
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      width: 100%;
    `}
`;

const StyledFlex = styled(Flex)`
  gap: 8px;
`;

const TitleWrpper = styled(Flex)`
  position: relative;
`;

const Card = styled(Flex)`
  position: relative;
  width: 100%;
  direction: ltr;
  padding: 12px;
  min-height: auto;
  border-radius: 8px;
  border: 1px solid var(--grayscale-n300);
  ${({ isPlain }) =>
    isPlain &&
    css`
      margin-bottom: 1px;
    `}
  background-color: var(--light-values-white);
  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #1688fe;
    `}
  cursor: pointer;
  &:hover {
    background-color: var(--light-values-white);
    ${MoreOptions} {
      display: flex;
    }
  }
  ${({ isSkeleton }) =>
    isSkeleton &&
    css`
      pointer-events: none;
      width: 370px;
    `}
  ${({ searchPage }) =>
    searchPage &&
    css`
      margin-bottom: 8px;
    `}
`;
