import React from 'react';
import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const AddNewResume = ({ createNew }) => {
  return (
    <Wrapper onClick={createNew}>
      <AddButton justifyContent="center" alignItems="center">
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none">
          <g clipPath="url(#a)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.375 1.5a1 1 0 1 0-2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6v-6z"
              fill="#4c4c55"
              className="styled-fill"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" transform="translate(.375 .5)" d="M0 0h16v16H0z" />
            </clipPath>
          </defs>
        </svg>
      </AddButton>
    </Wrapper>
  );
};

export default AddNewResume;

const AddButton = styled(Flex)`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--light-values-light-extra);
  &:hover {
    .styled-fill {
      fill: var(--dark-values-black);
    }
  }
`;

const Wrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 3px 16px 0 rgba(29, 44, 67, 0.04);
  border: 2px dashed var(--grayscale-n200);
  height: 108px;
  min-width: 288px;
  max-width: 288px;
  cursor: pointer;
  &:hover {
    ${AddButton} {
      background-color: var(--light-values-light-medium);
    }
  }
  @media (min-width: 768px) {
    max-width: 314px;
    min-width: 314px;
    height: 498px;
  }
  @media (min-width: 992px) {
    max-width: 201px;
    min-width: 201px;
    height: 364px;
  }
  @media (min-width: 1200px) {
    min-width: 206px;
    max-width: 206px;
  }
  @media (min-width: 1440px) {
    min-width: 232px;
    max-width: 232px;
    height: 360px;
  }
`;
