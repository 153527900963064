import React, { useState, useCallback, useRef } from 'react';
import CrossIcon from '../atoms/CrossIcon';
import Select from 'react-select';
import styled, { css, keyframes } from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';
import Modal from '/imports/core/ui/atoms/Modal';
import useWindowSize from '/imports/core/api/useWindowSize';

const AddMainTitleModal = ({
  open,
  onClose,
  loading,
  AddMainTitle,
  suggestions,
  selectedOption,
  setSelectedOption,
}) => {
  const { width } = useWindowSize();
  const modalStyles = {
    modalContainer: {
      display: 'flex',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(52, 60, 73, 0.8)',
    },
    modalBody: {
      width: width <= 655 ? '90%' : '610px',
      minHeight: '180px',
      maxHeight: '90%',
      height: 'auto',
      margin: 'auto',
      backgroundColor: 'var(--light-values-white)',
      borderRadius: '12px',
      boxShadow: '0 12px 48px 0 rgba(20, 20, 31, 0.24)',
      flex: 'unset',
      overflow: 'visible',
    },
  };
  const handleClose = () => {
    onClose();
  };
  const AddTitle = () => {
    AddMainTitle(selectedOption);
  };
  return (
    <Modal
      onClose={handleClose}
      fullScreen
      styles={modalStyles}
      animation="empty"
      openStateBase
      open={open}
      timeout={0}
    >
      <Wrapper direction="column">
        <ModalBody direction="column">
          <CrossIconWrapper onClick={handleClose}>
            <CrossIcon />
          </CrossIconWrapper>
          <Header direction="column">
            <HeadText>Select The Main Title</HeadText>
            <HeadSubText>Select the main title that you want to assign to this job</HeadSubText>
          </Header>
          <ScrollWrapper direction="column">
            {loading ? (
              <Spinner />
            ) : (
              <SelectWrapper>
                <Select
                  className="react-select__menu"
                  classNamePrefix="react-select"
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={suggestions}
                  isSearchable
                />
              </SelectWrapper>
            )}
          </ScrollWrapper>
        </ModalBody>
      </Wrapper>
      <ActionWrapper fullWidth justifyContent="flex-end">
        <ButtonAction cancel onClick={handleClose}>
          Close
        </ButtonAction>
        <ButtonAction onClick={AddTitle} isDisabled={!selectedOption || loading}>
          Add Title
        </ButtonAction>
      </ActionWrapper>
    </Modal>
  );
};

export default AddMainTitleModal;

const ScrollWrapper = styled(Flex)`
  gap: 24px;
  width: 100%;
  overflow-y: auto;
  height: 350px;
  overflow-x: hidden;
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 10px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${(p) => p.theme.colors.gray.regular};
  }
`;

const CrossIconWrapper = styled(Flex)`
  position: absolute;
  top: -16px;
  right: -16px;
  flex-shrink: 0;
  cursor: pointer;
`;
const Header = styled(Flex)`
  gap: 12px;
`;
const HeadText = styled.span`
  color: #14141f;
  font-family: Gilroy SemiBold;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;
const HeadSubText = styled.span`
  color: #595964;
  font-family: Gilroy Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 146.667% */
`;
const SkillTypeSection = styled(Flex)`
  gap: 16px;
`;
const SkillTypeText = styled.span`
  color: #595964;
  font-family: Gilroy SemiBold;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
const SkillsWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 12px;
`;
const SkillItem = styled(Flex)`
  flex-shrink: 0;
  gap: 8px;
  padding: 2px 8px;
  align-items: center;
  height: 24px;

  border-radius: 4px;
  background: #f6f6f6;
  cursor: pointer;

  color: #14141f;
  font-family: Gilroy Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${({ selected }) =>
    selected &&
    css`
      background: #edf4ff;
      color: #0062c7;
    `}
`;
const ActionWrapper = styled(Flex)`
  padding: 20px;
  border-top: 1px solid #ecf1f7;
  gap: 8px;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
`;
const ButtonAction = styled.button`
  padding: 16px;
  justify-content: center;
  align-items: center;
  color: var(--light-values-white);
  font-family: Gilroy SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #1688fe;
  &:hover {
    background-color: #0d68b4;
  }
  &:active {
    background-color: #0e5eab;
  }
  ${({ cancel }) =>
    cancel &&
    css`
      color: #4c4c55;
      font-size: 15px;
      line-height: 22px;
      background-color: white;
      &:hover {
        color: var(--dark-values-black);
        background-color: var(--grayscale-n100);
      }
      &:active {
        background-color: var(--grayscale-n200);
      }
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: #4c4c55;
      pointer-events: none;
      background-color: #f6f6f6;
    `}
`;
const ModalBody = styled(Flex)`
  gap: 24px;
  position: relative;
  padding-bottom: 24px;
  width: 100%;
  min-height: 350px;
`;
const Wrapper = styled(Flex)`
  padding: 32px;
`;

const SelectWrapper = styled.div`
  width: 100%;
`;

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const sweep = keyframes`
  0% {
  clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
}
  50% {
  clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
}
  100% {
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
}
`;

const Spinner = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  animation:
    ${sweep} 1s linear alternate infinite,
    ${rotate} 0.8s linear infinite;
  width: 46px;
  height: 46px;
  border-color: var(--dark-values-black);
  border-style: solid;
  border-width: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
