import styled, { css } from 'styled-components';

import Button from '/imports/core/ui/atoms/Button';
import Theme from '/imports/job-tracking/api/theme';

const ModalButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
  margin-left: 10px;
  background-color: var(--primary-default-b400);
  border: none;
  padding: 0 20px;
  ${Theme.renderFonts('SemiBold', '15', 'var(--light-values-white)', '1.47')};
  &:hover {
    background-color: #0072e8;
    color: var(--light-values-white);
  }
  &:active {
    background-color: #0062c7;
  }
  &:disabled {
    background-color: var(--grayscale-n300);
    pointer-events: none;
    color: var(--grayscale-n100);
  }
  ${({ cancel }) =>
    cancel &&
    css`
      background-color: #f6f6f6;
      color: #4c4c55;
      &:hover {
        color: var(--dark-values-black);
        background-color: var(--grayscale-n100);
      }
      &:active {
        background-color: var(--grayscale-n200);
      }
    `}
`;

export default ModalButton;
