import useTracking from 'imports/core/hooks/useTracking';
import useFeedStore from 'zustand/feedStore';
import { useSearchStore } from 'zustand/SearchStore';
import { Push } from '/components/Link';
import nProgress from 'nprogress';
import useIntl from '/imports/core/api/useIntl';
import { getRandomEmoji } from 'lib/helpers';
import qs from 'qs';
import { useEffect, useState, useCallback } from 'react';
import searchApi from 'imports/job-tracking/api/searchApi';

export const useSavedFeed = (token) => {
  const { searchTextData, cityTextData, filter } = useSearchStore();
  const { addFeeds } = useFeedStore();
  const { trackEvent } = useTracking();
  const { locale } = useIntl();
  const [completed, setCompleted] = useState();
  const { usesSaveFeedData } = searchApi();
  const { mutate } = usesSaveFeedData();

  const saveFeedCallback = useCallback(
    async (name) => {
      let data = {
        feedName: name || 'Total Jobs',
        icon: getRandomEmoji(),
      };
      if (searchTextData) data.searchText = searchTextData;
      if (cityTextData) data.where = cityTextData;
      if (filter) {
        const {
          datePost = '',
          remote = '',
          educationLevel = [],
          salary = '',
          jobType = [],
          skills = [],
          location = [],
          company = [],
          contractType = '',
          distance,
          apiProvider = '',
        } = filter;
        if (datePost) data.postedDate = datePost;
        if (remote) data.remote = remote;
        if (educationLevel.length != 0) data.educationLevels = educationLevel;
        if (salary) data.salary = salary;
        if (jobType.length != 0) data.jobType = jobType;
        if (skills.length != 0) data.skills = skills;
        if (location.length != 0) data.locations = location;
        if (company.length != 0) data.employers = company;
        if (contractType) data.contractType = contractType;
        if (distance) data.distance = distance;
        if (apiProvider) data.apiProvider = apiProvider;
        let ageInDay = 30;
        switch (filter.postedDate) {
          case '3days':
            ageInDay = 3;
            break;
          case 'today':
            ageInDay = 1;
            break;
          case 'week':
            ageInDay = 7;
            break;
          case 'month':
            ageInDay = 30;
            break;
          default:
            ageInDay = 30;
            break;
        }
        data.mantiksData = {
          job_age_in_days: ageInDay,
          job_location_ids: 2988507,
          job_title: filter?.title,
          job_title_include_all: '',
          job_title_excluded: '',
          job_description: '',
          job_description_include_all: true,
          job_description_excluded: '',
          job_board: filter.allowedJobProviders?.length > 0 ? filter.allowedJobProviders.join(',') : 'indeed',
          nb_min_job_posted: 2,
          nb_max_job_posted: 100,
          min_company_size: 5,
          max_company_size: 1000,
          company_industry: filter?.industries?.join(','),
          company_industry_excluded: 'education / training / recruitment',
          company_funding: '',
          limit: 10,
        };
      }
      nProgress.start();
      mutate(data);
      setCompleted(true);
      nProgress.done();
    },
    [cityTextData, filter, locale, searchTextData, token],
  );

  useEffect(() => {
    if (completed && addFeeds) {
      trackEvent('jobtrack_newfeed_created');
      const queryString = {
        template: 'feeds',
        id: addFeeds.feedId,
      };
      Push(`/app?${qs.stringify(queryString)}`, locale, `/app/feeds/${addFeeds.feedId}`);
    }
  }, [addFeeds, completed]);

  return { saveFeedCallback };
};
