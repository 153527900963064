import searchApi from 'imports/job-tracking/api/searchApi';
import { getRandomEmoji } from 'lib/helpers';
import { useCallback } from 'react';
import useFeedStore from 'zustand/feedStore';
import { useSearchStore } from 'zustand/SearchStore';

export const useUpdateFeed = (feedId, token) => {
  const { searchTextData, cityTextData, filter } = useSearchStore();
  const { updateToFeeds, feeds } = useFeedStore();
  const fetchActiveFeed = feeds?.find((k) => k.feedId === feedId, [feedId, feeds]);
  const { useUpdateFeedApi } = searchApi();
  const { mutate } = useUpdateFeedApi();

  const updateFeedCallBack = useCallback(
    async (name) => {
      let feedObj = {
        feedName: name || 'Total Jobs',
        icon: getRandomEmoji(),
        bySearch: false,
      };
      feedObj.searchText = searchTextData || null;
      feedObj.where = cityTextData || null;
      if (filter) {
        const {
          datePost = '',
          remote = '',
          educationLevel = [],
          salary = '',
          jobType = [],
          skills = [],
          location = [],
          company = [],
          contractType = '',
          allowedJobProviders = [],
        } = filter;
        if (datePost) feedObj.postedDate = datePost;
        if (remote) feedObj.remote = remote;
        if (educationLevel.length != 0) feedObj.educationLevels = educationLevel;
        if (salary) feedObj.salary = salary;
        if (jobType.length != 0) feedObj.jobType = jobType;
        if (skills.length != 0) feedObj.skills = skills;
        if (location.length != 0) feedObj.locations = location;
        if (company.length != 0) feedObj.employers = company;
        if (contractType) feedObj.contractType = contractType;
        if (allowedJobProviders.length !== 0) feedObj.allowedJobProviders = allowedJobProviders;
      }
      let payload = {
        feedObj,
        feedId: fetchActiveFeed.feedId,
      };
      mutate(payload);
    },
    [getRandomEmoji, fetchActiveFeed, token, updateToFeeds, feeds, searchTextData, cityTextData, filter],
  );

  return { updateFeedCallBack };
};
