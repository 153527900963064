import moment from 'moment';
import { useRouter } from 'next/router';
import nProgress from 'nprogress';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import WishlistToaster from '/imports/job-tracking/ui/atoms/WishlistToaster';
import Loading from '/imports/core/ui/components/Loading';
import searchApi from 'imports/job-tracking/api/searchApi';
import { useGeneralStore } from 'zustand/generalStore';
import { useSearchStore } from 'zustand/SearchStore';
import useIntl from '/imports/core/api/useIntl';
import Flex from '/imports/core/ui/atoms/Flex';
import { replaceString } from '/imports/core/ui/helpers';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import { withSearch } from '/imports/job-tracking/api/context/search.context';
import { alreadyApplied, isFoundJob, isSavedPodioJob, savedData } from '/imports/job-tracking/api/helper';
import WishlistHeartIcon from '/imports/job-tracking/ui/assets/WishlistHeartIcon';
import DropdownMenuItmes from '/imports/job-tracking/ui/atoms/DropdownMenuItems';
import DropdownMenuItemWrapper from '/imports/job-tracking/ui/atoms/DropdownMenuItemWrapper';
import RoundedButton from '/imports/job-tracking/ui/atoms/RoundedButton';
import DetailBrief from '/imports/job-tracking/ui/components/details/DetailBrief';
import DetailCompany from '/imports/job-tracking/ui/components/details/DetailCompany';

nProgress.configure({ showSpinner: false });

const MobileCompnayDetail = ({ data, isSearchJob = false, handleDirectJobAdd, toasterTitle, toasterLink, show }) => {
  const { t } = useIntl();
  const {
    state: { boards },
  } = withBoards();
  const {
    query: { template },
  } = useRouter();
  const { handleUpdateState } = withSearch();
  const { searchTextData, cityTextData, podioJobsData, setPodioJobsData, candidateSearchs } = useSearchStore();
  const savedPodioJob = useMemo(() => {
    return isSavedPodioJob(podioJobsData, data);
  }, [podioJobsData, data]);
  const [isApplied, setisAlreadyAppliedJob] = useState();
  const isFeed = template === 'feeds';
  useEffect(() => {
    const isApplied = alreadyApplied(podioJobsData, data);
    setisAlreadyAppliedJob(isApplied);
  }, [data]);
  const { usesavedJobsOnPodio } = searchApi();
  const { setIsMobileCompanyDetail } = useGeneralStore();
  const scrollElement = useRef();
  const [small, setSmall] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const hasSalary = useMemo(() => (data?.salary ? true : false), [data]);
  const { mutate } = usesavedJobsOnPodio(setSaveLoading);

  const onScroll = () => {
    const test = scrollElement.current?.getBoundingClientRect();
    setSmall(test.y < -125);
  };
  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll, true);
    return () => {
      window.removeEventListener('scroll', onScroll, true);
    };
  }, []);

  const handleBack = () => {
    setIsMobileCompanyDetail(false);
  };
  const isBoarded = !!savedData(boards, data);

  const handleRedirect = () => {
    window.open(data.url, '_blank');
    !isApplied && handleUpdateState('openJobStatusModal', true);
  };

  const goToIndeedLink = async () => {
    const baseUrl = 'https://fr.indeed.com/';
    window.open(`${baseUrl}${data?.jobUrl}`, '_blank');
  };

  const goToJobLink = async () => {
    const url = data?.reDirectionUrl ? data?.reDirectionUrl : data?.externalLink;
    window.open(`${url}`, '_blank');
  };

  const handleJobSaved = async () => {
    const isFound = isFoundJob(candidateSearchs, searchTextData, cityTextData);
    setSaveLoading(true);
    let obj = {
      title: data.title,
      jobLink: data.externalLink ? data.externalLink : data.url,
      location: data.location,
      jobId: data.id,
      jobSearchId: data.id,
      jobProvider: data.portal,
      companyName: data?.company,
      jobDescription: data?.description,
      salaryRange: data?.salaryRange,
      employmentType: data?.employmentType,
      searchName: data?.searchName,
    };
    if (isFound) {
      obj.podioItemId = isFound.podioItemId;
    }
    mutate(obj);
  };

  return (
    <>
      <MainFlex direction="column" fullWidth ref={scrollElement}>
        <StyledFlex alignItems="center" onClick={handleBack}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.6 14.8a1 1 0 0 1-1.4-.2L.25 8 5.2 1.4a1 1 0 0 1 1.6 1.2L3.5 7H15a1 1 0 1 1 0 2H3.5l3.3 4.4a1 1 0 0 1-.2 1.4z"
              fill="#14141f"
              id="fill-target"
            />
          </svg>
          <Text>{template === 'search' ? t('jobtrack_back_to_search_text') : t('jobtrack_back_to_board_text')}</Text>
        </StyledFlex>
        <DetailCompany
          data={data}
          isBoarded={isBoarded}
          handleDirectJobAdd={handleDirectJobAdd}
          isSearchJob={isSearchJob}
          isMobileCompnayDetail
        />
        <DetailBrief
          data={!!savedData(boards, data) ? savedData(boards, data) : data}
          isBoarded={isBoarded}
          $noPadding
          isMobileCompnayDetail
        />
      </MainFlex>
      {small && (
        <SmallCompanyWrapper fullWidth>
          <SmallBox fullWidth>
            <Box direction="column">
              <SmallTitle>{data.title}</SmallTitle>
              <SmallTitle small alignItems="flex-start" direction="column">
                <CompanyFlex full>
                  {data.company +
                    '. ' +
                    (data.city ? data.city : '') +
                    '  (' +
                    (data.isRemote ? t('jobtrack_remote_filter_text') : t('jobtrack_on_site_text')) +
                    ') '}
                </CompanyFlex>
                {data?.location && <CompanyFlex full>{data?.location}</CompanyFlex>}
                <PostedDate small fullWidth>
                  {data.datePosted}
                </PostedDate>
              </SmallTitle>
            </Box>
            <Box full>
              <ActionWrapper isButton fullWidth>
                {!savedPodioJob && isFeed ? (
                  <StyledRoundedButtonContainer>
                    <StyleRoundedButton onClick={handleJobSaved} $loading={saveLoading}>
                      <HeartFlex alignItems="center">
                        {saveLoading ? (
                          <LoadingContainer>
                            <Loading />
                          </LoadingContainer>
                        ) : (
                          <WishlistHeartIcon />
                        )}
                        Save Job
                      </HeartFlex>
                    </StyleRoundedButton>
                    <StyleRoundedButton onClick={goToIndeedLink} nobackground={true}>
                      <HeartFlex alignItems="center">Indeed</HeartFlex>
                    </StyleRoundedButton>
                    {!data?.isEasyApply ? (
                      <StyleRoundedButton onClick={goToJobLink} nobackground={true}>
                        <HeartFlex alignItems="center">{t('jobtrack_link_button')}</HeartFlex>
                      </StyleRoundedButton>
                    ) : (
                      <StyleRoundedButton nobackground={true} disabled={true}>
                        <HeartFlex alignItems="center">{t('jobtrack_easyapply_button')}</HeartFlex>
                      </StyleRoundedButton>
                    )}
                  </StyledRoundedButtonContainer>
                ) : (
                  isFeed && <PodioSavedJob>{t('saved_podio')}</PodioSavedJob>
                )}
                {isApplied && isFeed && <PodioSavedJob>{t('applied_job')}</PodioSavedJob>}
                {/*** hide the job apply button because of the new ui
                isFeed && (
                  <StyleRoundedButton onClick={handleRedirect} borderOnlyBtn id="job_apply_cta">
                    {t('jobtrack_apply_button')}
                  </StyleRoundedButton>
                )*/}
              </ActionWrapper>
            </Box>
          </SmallBox>
        </SmallCompanyWrapper>
      )}
      {small && isBoarded && (
        <SmallCompanyWrapper fullWidth>
          <SmallBox fullWidth isBoarded>
            <Box direction="column" isBoarded full>
              <SmallTitle>{data.title}</SmallTitle>
              <Wrapper alignItems="center" direction="column" isBoarded>
                <StarFlex>
                  <SmallTitle small isBoarded wrap="wrap" date>
                    <CompanyFlex full>
                      {data.company + '. ' + (data.city ? data.city : '')}{' '}
                      {' (' + (data.isRemote ? t('jobtrack_remote_filter_text') : t('jobtrack_on_site_text')) + ') '}
                    </CompanyFlex>
                    <PostedDate small>{moment(data.createdAt).fromNow()}</PostedDate>
                  </SmallTitle>
                </StarFlex>
                {hasSalary && (
                  <TagWrapper small hasSalary={hasSalary}>
                    {data.salary}
                  </TagWrapper>
                )}
              </Wrapper>
            </Box>
          </SmallBox>
        </SmallCompanyWrapper>
      )}
      <WishlistToaster
        text={replaceString(t('jobtrack_wishlist_toaster_text'), {
          TITLE_V: toasterTitle,
        })}
        link={toasterLink}
        show={show}
      />
    </>
  );
};

export default MobileCompnayDetail;

const PodioSavedJob = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '20', 'var(--dark-values-black)', '30px')};
`;

const LoadingContainer = styled(Flex)`
  & > div {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    background-color: transparent !important;
    position: relative !important;
    margin-top: 5px;
  }
  & picture img,
  & picture source {
    margin: 0px !important;
    width: 20px !important;
    height: 20px !important;
  }
  & > * {
    filter: brightness(0) invert(1) !important;
  }
`;

const Dropdown = styled(DropdownMenuItemWrapper)`
  top: 24px;
  right: -22px;
  left: unset;
  padding-top: 4px;
  display: none;
  ${({ addToWishlist }) =>
    addToWishlist &&
    css`
      right: unset;
      top: 41px;
      left: 0;
    `}
  ${({ isApplyed }) =>
    isApplyed &&
    css`
      top: 90px;
      right: 0px;
      left: 0px;
    `}@media (max-width: 767px) {
    width: 100%;
  }
`;

const ActionWrapper = styled(Flex)`
  flex-shrink: 0;
  display: flex;
  position: relative;
  ${({ isButton }) =>
    isButton &&
    css`
      flex-direction: column;
      gap: 12px;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      > * {
        margin-left: 0;
        &:first-child {
          margin-left: 0;
        }
      }
    `}
`;

const TagWrapper = styled(Flex)`
  position: relative;
  cursor: pointer;
  overflow: visible;
  max-width: 418px;
  width: 100%;
  margin-top: 0px;
  gap: 10px;
  ${({ small }) =>
    small &&
    css`
      cursor: default;
    `}
`;

const StyledRoundedButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StyleRoundedButton = styled(RoundedButton)`
  justify-content: center;
  @media (max-width: 767px) {
    width: 100%;
    justify-content: center;
  }
  &:hover {
    ${DropdownMenuItmes} {
      display: flex !important;
    }
    ${Dropdown} {
      display: flex !important;
    }
  }
  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ nobackground }) =>
    nobackground &&
    css`
      background-color: transparent;
      color: #1688fe;
      border: 1px solid #1688fe;
      width: 40% !important;
      &:hover {
        background-color: #f0f8ff;
      }
      &:active {
        background-color: #e6f2ff;
      }
    `}
  &:disabled {
    background-color: #f0f0f0;
    color: #b0b0b0;
    border-color: #d0d0d0;
    pointer-events: none;
  }
`;

const StarFlex = styled(Flex)`
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const Wrapper = styled(Flex)`
  gap: 16px;
  ${({ isBoarded }) =>
    isBoarded &&
    css`
      align-items: start;
    `}
`;

const HeartFlex = styled(Flex)`
  gap: 10px;
`;

const PostedDate = styled(Flex)`
  gap: 8px;
  margin-left: 5px;
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--grayscale-n400)', '22px')};
  ${({ small }) =>
    small &&
    css`
      margin: 0;
    `}
`;

const CompanyFlex = styled(Flex)`
  flex-shrink: 0;
  max-width: 50%;
  ${(p) =>
    p.full &&
    css`
      max-width: 100%;
    `}
`;

const SmallTitle = styled(Flex)`
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 17px;
  color: var(--dark-values-black);
  line-height: 24px;
  ${({ small, theme }) =>
    small &&
    css`
      display: flex;
      flex-direction: column;
      ${theme.jobTrack.renderFonts('Medium', '13', 'var(--dark-values-black)', '20px')};
    `}
  ${({ date }) =>
    date &&
    css`
      gap: 8px;
    `}
`;

const SmallCompanyWrapper = styled(Flex)`
  position: absolute;
  inset: 0;
`;
const Box = styled(Flex)`
  flex: 1;
  gap: 8px;
  ${(p) =>
    p.full &&
    css`
      width: 100%;
    `}
`;

const SmallBox = styled(Flex)`
  position: fixed;
  z-index: 99999;
  min-height: 70px;
  top: 0;
  width: 100%;
  padding: 16px 24px;
  gap: 8px;
  border-bottom: 1px solid #ececed;
  background-color: #fff;
  flex-direction: column;
`;

const MainFlex = styled(Flex)`
  overflow-y: auto;
`;

const StyledFlex = styled(Flex)`
  gap: 16px;
  margin: 0 0 16px 0;
  cursor: pointer;
`;

const Text = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '15', 'var(--dark-values-black)', '22px')};
`;
