import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import MiniCrossIcon from '../assets/MiniCrossIcon';

import searchApi from 'imports/job-tracking/api/searchApi';
import { useSearchStore } from 'zustand/SearchStore';
import env from '/env';
import useIntl from '/imports/core/api/useIntl';
import Flex from '/imports/core/ui/atoms/Flex';
import Modal from '/imports/core/ui/atoms/Modal';
import CloseLinkedinModalIcon from '/imports/generator/ui/assets/CloseLinkedinModalIcon';
import { AGENT_SUGGESTION_TAGS, HIDE_IN_FRENCH, ONLY_STAGING_FILTERS } from '/imports/job-tracking/api/constants';
import useGeoLocation from '/imports/job-tracking/api/useGeoLocation';
import SmallDownArrowIcon from '/imports/job-tracking/ui/assets/SmallDownArrowIcon';
import { CheckBoxLabel } from '/imports/job-tracking/ui/atoms/CheckBox';
import RoundedButton from '/imports/job-tracking/ui/atoms/RoundedButton';

const modalStyles = {
  modalContainer: {
    backgroundColor: 'transparent',
    display: 'flex',
  },
  modalBackdrop: {
    backgroundColor: 'rgba(52, 60, 73, 0.8)',
    zIndex: '9999',
  },
  modalBody: {
    maxWidth: '712px',
    background: '#fff',
    margin: 'auto',
    width: '456px',
    position: 'relative',
    overflow: 'hidden',
    flexGrow: '0',
    borderRadius: '16px',
  },
};

const isRadio = [
  'datePost',
  'remote',
  'salary',
  'openAI',
  'partner',
  'contractType',
  'isManualCheck',
  'language',
  'apiProvider',
];

const FilterTags = ({ handleData }) => {
  const { t, locale } = useIntl();
  const isProd = env.NODE_ENV === 'production';
  const [filterTag, setFilterTag] = useState([]);
  const SUGGSTION_TAGS = AGENT_SUGGESTION_TAGS;
  const [tags, setTags] = useState(SUGGSTION_TAGS);
  const [show, setShow] = useState(false);
  const location = useGeoLocation();
  const { useCityDefaultSuggestion } = searchApi();
  const { mutate } = useCityDefaultSuggestion();

  useEffect(() => {
    show ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'unset');
  }, [show]);
  const { searchTextData, cityTextData, filter, setFilter, cityDefaultOption } = useSearchStore();
  const fetchDefaultCitySuggestion = async () => {
    if (location) {
      const { latitude, longitude } = location;
      mutate({ latitude, longitude });
    }
  };

  useEffect(() => {
    fetchDefaultCitySuggestion();
  }, [location]);

  const handleSuggestion = (filterName, slug) => () => {
    setShow(!show);
    let data = {
      slug,
      name: filterName,
      value: [],
    };
    switch (filterName) {
      case 'Remote':
        data = {
          ...data,
          id: 'remote',
          value: SUGGSTION_TAGS.find((g) => g.name === 'Remote').value,
        };
        return setFilterTag(data);
      case 'Job Type':
        data = {
          ...data,
          id: 'jobType',
          value: SUGGSTION_TAGS.find((s) => s.name === 'Job Type').value,
        };
        return setFilterTag(data);
      case 'Location':
        data = {
          ...data,
          id: 'location',
          value: cityDefaultOption?.map((k) => k) ?? [],
        };
        return setFilterTag(data);
      case 'Easy Apply':
        data = {
          ...data,
          id: 'isEasyApply',
          value: SUGGSTION_TAGS.find((s) => s.name === 'Easy Apply').value,
        };
        return setFilterTag(data);
      case 'Date Post':
        data = {
          ...data,
          id: 'datePost',
          value: SUGGSTION_TAGS.find((s) => s.name === 'Date Post').value,
        };
        return setFilterTag(data);
      case 'Api Provider':
        data = {
          ...data,
          id: 'apiProvider',
          value: SUGGSTION_TAGS.find((s) => s.name === 'Api Provider').value,
        };
        return setFilterTag(data);
      default:
        return setFilterTag(SUGGSTION_TAGS.find((g) => g.name === filterName));
    }
  };
  const onClose = (e, filterTagId) => {
    handleClear(filterTagId)(e);
    setShow(false);
  };

  const radioBoxClick = (name, Filter) => setFilter({ ...filter, [name]: Filter });
  const selectFilterTag = (name, Filter) => {
    const isSelected = filter[name].includes(Filter);
    if (!isSelected) {
      const selectFilter = { ...filter, [name]: [...filter[name], Filter] };
      setFilter(selectFilter);
    }
  };

  const clearAllFilterTag = (name, isUpdate) => {
    if (!filter[name]) filter[name] = [];
    const clearFilter = { ...filter, [name]: [] };
    setFilter(clearFilter);
    if (isUpdate) {
      handleData({ ...filter, [name]: [] });
      setShow(false);
    }
  };

  const clearTag = (name, isUpdate = false) => {
    const ClearTagInfo = { ...filter, [name]: '' };
    setFilter(ClearTagInfo);
    if (isUpdate) {
      handleData({ ...filter, [name]: '' });
      setShow(false);
    }
  };

  const getSelectedValueSlug = (tagItemId, valueId) => {
    return tags.find((tag) => tag.id === tagItemId)?.value?.find((v) => v.value === valueId)?.slug;
  };

  const renderSelected = (id, name) => {
    const isSelected = filter[id];
    if (!isSelected) return name.toString();
    const isMultiple = Array.isArray(filter[id]) ? filter[id][0] : filter[id];
    const selectedSlug = getSelectedValueSlug(id, filter[id]);
    return isSelected.length != 0 ? t(selectedSlug) || isMultiple.toString() : name.toString();
  };

  const onUpdate = () => {
    if (!searchTextData && !cityTextData) return setShow(false);
    handleData(filter);
    setShow(false);
  };

  const handleClick = (id, Filter) => () => {
    if (isRadio.includes(id)) {
      radioBoxClick(id, Filter);
    } else {
      if (!filter[id]) filter[id] = [];
      selectFilterTag(id, Filter);
    }
  };
  const handleClear =
    (id, isUpdate = false) =>
    (e) => {
      e.stopPropagation();
      if (isRadio.includes(id)) clearTag(id, isUpdate);
      else clearAllFilterTag(id, isUpdate);
    };
  const showTags = (tagName, tag) => {
    if (Array.isArray(tag?.apiProvider)) {
      return !tag?.apiProvider.includes(filter.apiProvider);
    }
    if (isProd && ONLY_STAGING_FILTERS.includes(tagName)) return true;
  };

  const hideInFrench = (tagId) => {
    if (locale === 'fr' && HIDE_IN_FRENCH.includes(tagId)) return true;
  };

  const onSliderChange = (value) => {
    const SliderChange = { ...filter, distance: value };
    setFilter(SliderChange);
  };

  const handleCompleteChange = () => {
    setTimeout(() => {
      handleData(filter);
    }, 500);
  };
  const filterRender = () => {
    const selected = (k) => filter[k.id] && filter[k.id].length != 0;

    return (
      <GapFlex fullWidth alignItems="center">
        {tags.map(
          (k, i) =>
            k.value.length > 0 &&
            !showTags(k.id, k) &&
            !hideInFrench(k.id) && (
              <Main
                alignItems="center"
                key={i}
                onClick={selected(k) ? handleClear(k.id, true) : handleSuggestion(k.name, k.slug)}
                selected={selected(k)}
              >
                <Tags selected={selected(k)}>{renderSelected(k.id, k?.slug ? t(k.slug) : k.name.toString())}</Tags>
                {selected(k) ? (
                  <SvgWrapper alignItems="center" selected={selected(k)} onClick={handleClear(k.id, true)}>
                    <MiniCrossIcon />
                  </SvgWrapper>
                ) : (
                  <SvgWrapper alignItems="center" selected={selected(k)}>
                    <SmallDownArrowIcon />
                  </SvgWrapper>
                )}
              </Main>
            ),
        )}
        <SliderWrap direction="column">
          <Tags>Distance: {filter?.distance || 10}km</Tags>
          <Slider
            min={1}
            max={50}
            defaultValue={10}
            value={filter?.distance}
            onChange={(value) => onSliderChange(value)}
            onChangeComplete={handleCompleteChange}
          />
        </SliderWrap>
      </GapFlex>
    );
  };
  return (
    <Wrapper>
      {filterRender()}
      {show && (
        <Modal
          onClose={(e) => onClose(e, filterTag?.id)}
          fullScreen
          styles={modalStyles}
          animation="empty"
          openStateBase
          open={show}
          timeout={0}
        >
          <ModalWrapper direction="column" fullWidth>
            <Flex alignItems="center" justifyContent="space-between" fullWidth>
              <Title>{filterTag?.slug ? t(filterTag?.slug) : filterTag.name}</Title>
              <SvgWrapper onClick={(e) => onClose(e, filterTag?.id)}>
                <CloseLinkedinModalIcon />
              </SvgWrapper>
            </Flex>
            <Content direction="column" justifyContent="flex-start" fullWidth>
              <Options alignItems="center" direction="column">
                {filterTag?.value?.map((k, i) => (
                  <StyledFlex
                    key={i}
                    alignItems="center"
                    fullWidth
                    onClick={handleClick(filterTag.id, k.value ? k.value : k)}
                    disabled={filterTag?.count && filterTag?.count[i] === '0'}
                  >
                    {filterTag?.checkBox ? (
                      <Input
                        type="checkbox"
                        value={k.value}
                        checked={filter[filterTag.id]?.includes(k.value ? k.value : k)}
                        readOnly
                      />
                    ) : (
                      <Input
                        type="radio"
                        name="date"
                        checked={
                          isRadio.includes(filterTag.id)
                            ? filter[filterTag.id] === (k.value ? k.value : k)
                            : filter[filterTag.id]?.includes(k.value ? k.value : k)
                        }
                        readOnly
                      />
                    )}
                    <Flex alignItems="center" fullWidth>
                      <CheckBoxLabel
                        label={t(k?.slug) ? t(k.slug) : k.value ? k.value.toString() : k.toString()}
                        searchCheckLabel
                      />
                      {filterTag?.count?.[i] && <Count>{`(${filterTag?.count[i]})`}</Count>}
                    </Flex>
                  </StyledFlex>
                ))}
              </Options>
              <ButtonWrap fullWidth justifyContent="center">
                <StyledRoundedButton borderOnlyBtn padded onClick={handleClear(filterTag.id)}>
                  {t('jobtrack_clear_all_filter_text')}
                </StyledRoundedButton>
                <StyledRoundedButton padded onClick={onUpdate}>
                  {t('jobtrack_update_filter_text')}
                </StyledRoundedButton>
              </ButtonWrap>
            </Content>
          </ModalWrapper>
        </Modal>
      )}
    </Wrapper>
  );
};

export default FilterTags;

const SliderWrap = styled(Flex)`
  margin-left: 10px;
  .rc-slider {
    width: 250px;
  }
`;

const StyledRoundedButton = styled(RoundedButton)`
  ${({ theme }) => theme.max('xs')`
    width:100%;
    display:flex;
    justify-content:center;
  `}
`;
const Count = styled.span`
  margin-left: 5px;
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '13', 'var(--dark-values-black)', '0px')};
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;

const Input = styled.input`
  cursor: pointer;
`;

const ButtonWrap = styled(Flex)`
  gap: 12px;
  ${({ theme }) => theme.max('xs')`
    flex-direction:column;
  `}
`;

const Options = styled(Flex)`
  gap: 16px;
  max-height: 500px;
  width: 100%;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }
  overflow-y: scroll;
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(20, 20, 31, 0.12);
    border-radius: 2px;
  }
`;

const Content = styled(Flex)`
  gap: 32px;
`;

const Title = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '24', '#33334F', '30px')};
`;

const ModalWrapper = styled(Flex)`
  background-color: var(--light-values-white);
  box-shadow: 0px 12px 48px rgba(20, 20, 31, 0.24);
  border-radius: 12px;
  padding: 24px;
  gap: 21px;
`;

const SvgWrapper = styled(Flex)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  svg > path {
    fill: var(--dark-values-dark-regular);
  }
  ${({ selected }) =>
    selected &&
    css`
      svg > path {
        fill: var(--light-values-white);
      }
    `}
`;

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 8px;
`;

const GapFlex = styled(Flex)`
  gap: 4px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

const Main = styled(Flex)`
  padding: 9px 12px;
  flex-shrink: 0;
  background-color: var(--grayscale-n50);
  border: 1px solid var(--grayscale-n200);
  cursor: pointer;
  gap: 4px;
  border-radius: 8px;
  &:hover {
    background-color: var(--grayscale-n100);
    border-color: var(--grayscale-n300);
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: var(--grayscale-n500);
      &:hover {
        background-color: var(--dark-values-dark-extra);
      }
    `}
`;

const Tags = styled(Flex)`
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '15', 'var(--dark-values-black)', '22px')};
  ${({ selected }) =>
    selected &&
    css`
      color: var(--light-values-white);
    `}
`;
