import nookies from 'nookies';
import { replaceString } from 'imports/core/ui/helpers';
import styled, { css } from 'styled-components';

import AddNewBoardButton from '/imports/job-tracking/ui/components/AddNewBoardButton';
import BoardItem from '/imports/job-tracking/ui/components/BoardItem';
import { deleteBoard } from '/imports/job-tracking/api/api';
import useIntl from '/imports/core/api/useIntl';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import { withConfirm } from '/imports/job-tracking/api/confirm';

const BoardList = ({ boards, disableAdd = false, agence = false, confirm, onClose }) => {
  const { t } = useIntl();
  const { token } = nookies.get({});
  const { dispatch } = withBoards();
  const deleteBoardCallback = async (current) => {
    const resp = await deleteBoard(current, token);
    if (resp === 'OK') {
      dispatch({ type: 'DELETE_BOARD', payload: current });
      onClose();
    }
  };
  const deleteBoardConfirm = (id, title) => (e) => {
    e.stopPropagation();
    confirm({
      title: replaceString(t('jobtrack_board_delete_modal_head_text'), { TITLE_V: title }),
      text: replaceString(t('jobtrack_board_delete_modal_text'), {
        TITLE_V: title,
      }),
      confirmText: t('jobtrack_job_card_delete_option_text'),
      enableLoaders: true,
      onConfirm() {
        deleteBoardCallback(id);
      },
    });
  };
  return (
    <ItemsGrid>
      {!disableAdd && <AddNewBoardButton addBoard={() => dispatch({ type: 'OPEN_NEW_BOARD_MODAL' })} />}
      {boards.map((board, i) => (
        <BoardItem
          board={board}
          key={i}
          disableAdd={disableAdd}
          agence={agence}
          boardsDispatch={dispatch}
          deleteBoardFunc={deleteBoardConfirm}
        />
      ))}
    </ItemsGrid>
  );
};

export default withConfirm(BoardList);

const ItemsGrid = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  align-items: center;
  margin-top: 24px;
  flex-direction: column;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
  overflow-y: auto;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
  ${({ addBtn }) =>
    addBtn &&
    css`
      width: fit-content;
    `}
  @media (max-width: 480px) {
    gap: 8px;
    padding-bottom: 24px;
  }
  @media (min-width: 768px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  @media (min-width: 992px) {
    flex-wrap: nowrap;
    flex-direction: row;
    padding-bottom: 24px;
  }
`;
