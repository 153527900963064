import React from 'react';

const SmallDownArrowIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.20006 6.4C3.53143 5.95817 4.15823 5.86863 4.60006 6.2L8.00009 8.75L11.4001 6.2C11.8419 5.86863 12.4687 5.95817 12.8001 6.4C13.1315 6.84183 13.0419 7.46863 12.6001 7.8L8.00009 11.25L3.40006 7.8C2.95823 7.46863 2.86869 6.84183 3.20006 6.4Z"
        fill="black"
      />
    </svg>
  );
};

export default SmallDownArrowIcon;
