import React, { useContext, createContext, useReducer, useEffect } from 'react';

import Reducer from '/imports/job-tracking/api/reducers/feeds.reducer';

const FeedsContext = createContext();
export const Provider = FeedsContext.Provider;
export const BoardConsumer = FeedsContext.Consumer;

const FeedsProvider = ({ children, feeds = [], feedList = [], activeFeed = {}, otherProps }) => {
  const [state, dispatch] = useReducer(Reducer, { feeds, feedList, activeFeed });

  useEffect(() => {
    if (feedList && feedList.length > 0) dispatch({ type: 'SET_FEEDS', payload: feedList });
  }, [feedList]);

  return (
    <Provider
      value={{
        state,
        dispatch,
        ...otherProps,
      }}
    >
      {children}
    </Provider>
  );
};

export const withFeeds = () => useContext(FeedsContext);

export default FeedsProvider;
