import RoundedButton from '../../atoms/RoundedButton';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import nookies from 'nookies';
import nProgress from 'nprogress';
import { cloneDeep } from 'lodash';
import {
  alreadyApplied,
  getTotalBoardJobs,
  isFoundJob,
  isSavedPodioJob,
  processJobDataForPortal,
  savedData,
} from 'imports/job-tracking/api/helper';
import { renderColumnName } from 'lib/helpers';
import styled, { css } from 'styled-components';
import Loading from '/imports/core/ui/components/Loading';
import CheckIcon from '/imports/job-tracking/ui/assets/CheckIcon';
import CompanyLayout from '/imports/job-tracking/ui/layout/CompanyLayout';
import { deleteJobTrackingData, updateJobTrackPosition } from '/imports/job-tracking/api/api';
import DropdownInner from '/imports/job-tracking/ui/atoms/DropdownInner';
import DropdownMenuItemWrapper from '/imports/job-tracking/ui/atoms/DropdownMenuItemWrapper';
import DropdownMenuItmes from '/imports/job-tracking/ui/atoms/DropdownMenuItems';
import DropdownOption from '/imports/job-tracking/ui/atoms/DropdownOption';
import Flex from '/imports/core/ui/atoms/Flex';
import IconDelete from '/imports/job-tracking/ui/assets/IconDelete';
import MoreOptionsIcon from '/imports/job-tracking/ui/assets/MoreOptionsIcon';
import useIntl from '/imports/core/api/useIntl';
import useTracking from '/imports/core/hooks/useTracking';
import useWindowSize from '/imports/core/api/useWindowSize';
import WishlistHeartIcon from '/imports/job-tracking/ui/assets/WishlistHeartIcon';
import { withBoards } from '/imports/job-tracking/api/context/boards.context';
import { withConfirm } from '/imports/job-tracking/api/confirm';
import { withSearch } from '/imports/job-tracking/api/context/search.context';
import { getIsAgent } from '/imports/generator/api/helpers';
import useToaster from '/imports/core/api/useToaster';
import { useRouter } from 'next/router';
import { useGeneralStore } from 'zustand/generalStore';
import { useSearchStore } from 'zustand/SearchStore';
import searchApi from 'imports/job-tracking/api/searchApi';

nProgress.configure({ showSpinner: false });

const DetailCompany = ({
  isBoarded = false,
  confirm,
  onClose,
  isSearchJob = false,
  handleBack,
  small,
  handleDirectJobAdd,
}) => {
  const { t } = useIntl();
  const { trackEvent } = useTracking();
  const { toast } = useToaster();
  const {
    state: { boards },
    dispatch,
  } = withBoards();
  const { handleUpdateState } = withSearch();
  const { query } = useRouter();
  const isFeed = query?.template === 'feeds';
  const { searchTextData, cityTextData, podioJobsData, candidateSearchs, searchfeedId, activeCardData } =
    useSearchStore();
  const data = !!savedData(boards, activeCardData) ? savedData(boards, activeCardData) : activeCardData;
  const savedPodioJob = isSavedPodioJob(podioJobsData, data);
  const { isMobileCompany } = useGeneralStore();
  const isAgent = getIsAgent();
  const { token } = nookies.get({});
  const activeBoard = useMemo(() => {
    return (data) => {
      if (!data || !boards) return null;
      return boards.find((k) => k.boardId === data.boardId);
    };
  }, [boards]);
  const { width } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const dropRef = useRef();
  const [isApplied, setisAlreadyAppliedJob] = useState();
  const { usesavedJobsOnPodio } = searchApi();
  const { mutate } = usesavedJobsOnPodio(setSaveLoading);

  const checkIfAlreadyApplied = useCallback(() => {
    if (!data || !podioJobsData) return false;
    alreadyApplied(podioJobsData, data);
  }, [podioJobsData, data]);

  useEffect(() => {
    const isApplied = checkIfAlreadyApplied();
    setisAlreadyAppliedJob(isApplied);
  }, [checkIfAlreadyApplied]);

  const onClickDeleteCard = (data) => {
    dispatch({
      type: 'BOARD_COLUMN_JOB_DELETE',
      payload: {
        boardId: data.boardId,
        data,
      },
    });
    onClose();
  };

  const handleDeleteConfirm = (data) => (e) => {
    e.stopPropagation();
    trackEvent('delete_job_modal');
    confirm({
      title: t('jobtrack_modal_delete_card_title'),
      text: t('jobtrack_modal_delete_card_text'),
      confirmText: t('jobtrack_job_card_delete_option_text'),
      enableLoaders: true,
      onConfirm() {
        handleDelete(data);
      },
    });
  };

  const handleDelete = async (data) => {
    nProgress.start();
    const response = await deleteJobTrackingData(data.jobId, data.boardId, token);
    if (response) {
      onClickDeleteCard(data);
      trackEvent('job_deleted');
      nProgress.done();
    }
  };

  const handleClick = (e) => {
    if (open) {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    }
  };
  useEffect(() => {
    if (open) {
      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [open]);
  const handleTab = () => {
    if (isMobileCompany) {
      if (width <= 459) return 2;
      else if (width <= 574) return 3;
      else if (width <= 656) return 4;
      else return 5;
    }
    if (isSearchJob) {
      if (width <= 459) return 2;
      else if (width <= 574) return 3;
      else if (width <= 656) return 4;
      else if (width <= 767) return 5;
      else if (width <= 849) return 2;
      else if (width <= 963) return 3;
      else if (width <= 991) return 4;
      else if (width <= 1069) return 2;
      else if (width <= 1439) return 3;
      else if (width < 1920) return 4;
      else return 7;
    } else {
      if (width <= 326) return 1;
      else if (width <= 519) return 2;
      else if (width <= 634) return 3;
      else if (width <= 716) return 4;
      else if (width <= 768) return 5;
      else if (width <= 827) return 2;
      else if (width <= 930) return 3;
      else if (width <= 991) return 4;
      else if (width <= 1027) return 2;
      else if (width <= 1131) return 3;
      else if (width <= 1199) return 4;
      else if (width <= 1225) return 3;
      else if (width <= 1298) return 4;
      else if (width <= 1454) return 5;
      else if (width <= 1580) return 6;
      else return 7;
    }
  };
  const goTo = async (newPosition) => {
    setLoading(true);
    const boardsData = cloneDeep(boards);
    let board = useMemo(() => {
      if (!data || !boardsData) return null;
      boardsData.find((k) => k.boardId === data.boardId);
    }, [boardsData, data]);
    let jobs = getTotalBoardJobs(board);
    let count = jobs.filter((k) => k.position[0] === newPosition).length;
    dispatch({
      type: 'DRAG_JOB_BY_INDEX',
      payload: {
        boardId: data.boardId,
        c1I: data.position[0],
        c2I: newPosition,
        sI: data.position[1],
        dI: count,
        jobId: data.jobId,
      },
    });
    nProgress.start();
    const res = await updateJobTrackPosition(token, data.jobId, data.boardId, [newPosition, count]);
    trackEvent('job_status_change_header');
    nProgress.done();
    dispatch({ type: 'UPDATE_JOB', payload: res });
    setLoading(false);
  };

  const handleRedirect = (isApplied) => async () => {
    const { agent_client_email, agent_client_id } = nookies.get({});
    let savedPodioJob = isSavedPodioJob(podioJobsData, data);
    if (!savedPodioJob) {
      const newPodioJob = await handleJobSaved();
      if (newPodioJob) {
        const updatedPodioJobs = [...podioJobsData, newPodioJob];
        savedPodioJob = isSavedPodioJob(updatedPodioJobs, data);
      }
    }

    let url = data.url;
    if (savedPodioJob) {
      url += `&saJobId=${savedPodioJob.jobId}`;
    }
    if (url) {
      url += `&saemail=${encodeURIComponent(agent_client_email)}&agentClientUserId=${encodeURIComponent(agent_client_id)}`;
    }
    window.open(url, '_blank');
    !isApplied && handleUpdateState('openJobStatusModal', true);
  };

  const goToIndeedLink = async () => {
    const baseUrl = 'https://fr.indeed.com/';
    window.open(`${baseUrl}${data?.jobUrl}`, '_blank');
  };

  const goToJobLink = async () => {
    const url = data?.reDirectionUrl ? data?.reDirectionUrl : data?.externalLink;
    window.open(`${url}`, '_blank');
  };

  const handleJobSaved = async () => {
    const isFound = isFoundJob(candidateSearchs, searchTextData, cityTextData);
    setSaveLoading(true);
    try {
      let obj = {
        title: data.title,
        jobLink: data?.externalLink ? data.externalLink : data.url,
        location: data.location,
        jobId: data.id,
        jobSearchId: data.id,
        jobProvider: data.portal,
        companyName: data?.company,
        jobDescription: data?.description,
        salaryRange: data?.salaryRange,
        employmentType: data?.employmentType,
        isHunter: true,
        searchName: data?.searchName,
      };
      if (searchfeedId) {
        obj.jobSearchId = searchfeedId;
      }
      if (isFound) {
        obj.podioItemId = isFound.podioItemId;
      }

      obj = processJobDataForPortal(obj, data?.portals || []);
      mutate(obj);
    } catch (e) {
      console.log(e);
      toast({
        text: e.message,
        error: true,
      });
      if (e.message === 'User is not an agent') {
        toast({
          text: 'Only Agents can perform this action. Please log in or reconnect as an agent to proceed.',
          error: true,
        });
      }
    }
    return null;
  };

  return (
    <CompanyLayout
      isBoarded={isBoarded}
      isSearchJob={isSearchJob}
      handleBack={handleBack}
      small={small}
      searchPageWishlist
      handleDirectJobAdd={handleDirectJobAdd}
    >
      <ActionWrapper isButton alignItems="center">
        {!isFeed && <PodioSavedJob>Save the search to save the job</PodioSavedJob>}
        {!savedPodioJob && isFeed ? (
          <StyledRoundedButtonContainer>
            <StyledRoundedButton onClick={handleJobSaved} $loading={saveLoading}>
              <HeartFlex alignItems="center">
                {saveLoading ? (
                  <LoadingContainer>
                    <Loading />
                  </LoadingContainer>
                ) : (
                  <WishlistHeartIcon />
                )}
                Save Job
              </HeartFlex>
            </StyledRoundedButton>
            <StyledRoundedButton onClick={goToIndeedLink} nobackground={true}>
              <HeartFlex alignItems="center">Indeed</HeartFlex>
            </StyledRoundedButton>
            {!data?.isEasyApply ? (
              <StyledRoundedButton onClick={goToJobLink} nobackground={true}>
                <HeartFlex alignItems="center">{t('jobtrack_link_button')}</HeartFlex>
              </StyledRoundedButton>
            ) : (
              <StyledRoundedButton nobackground={true} disabled={true}>
                <HeartFlex alignItems="center">{t('jobtrack_easyapply_button')}</HeartFlex>
              </StyledRoundedButton>
            )}
          </StyledRoundedButtonContainer>
        ) : (
          isFeed && <PodioSavedJob>{t('saved_podio')}</PodioSavedJob>
        )}
        {isApplied && isFeed && <PodioSavedJob>{t('applied_job')}</PodioSavedJob>}
        {/*** hide the job apply button because of the new ui
        isFeed && (
          <StyledRoundedButton
            onClick={handleRedirect(isApplied)}
            borderOnlyBtn
            id="job_apply_cta"
            $loading={saveLoading}
          >
            {isApplied ? 'Check Job' : t('jobtrack_apply_button')}
          </StyledRoundedButton>
        )*/}
      </ActionWrapper>

      {isBoarded && !isAgent && (
        <>
          <TabsWrapper>
            {activeBoard(data).columns.map((k, i) => {
              if (i >= handleTab()) return;
              return (
                <TabBox
                  key={i}
                  alignItems="center"
                  selected={i <= data.position[0]}
                  active={i === data.position[0]}
                  {...(i !== data.position[0] && !loading && { onClick: () => goTo(i), clickable: true })}
                >
                  {i < data.position[0] && <CheckIcon color="var(--light-values-white)" />}
                  {renderColumnName(k.column, t)}
                </TabBox>
              );
            })}
            <TabBox
              MoreOptions
              alignItems="center"
              justifyContent="center"
              open={open}
              onClick={(e) => {
                if (open) {
                  if (dropRef.current && !dropRef.current.contains(e.target)) {
                    setOpen(false);
                  }
                } else {
                  setOpen(true);
                }
              }}
              ref={ref}
            >
              <ActionWrapper>
                <MoreOptionsIcon color=" var(--dark-values-black)" />
                <Dropdown ref={dropRef}>
                  <DropdownMenuItmes>
                    <StyledDropdownInner searchPageDropDown>
                      {activeBoard(data).columns.map((k, i) => {
                        if (i < handleTab()) return;
                        return (
                          <StyledDropdownOption
                            key={i}
                            selected={i <= data.position[0]}
                            searchPageDropDown
                            {...(i !== data.position[0] && !loading && { onClick: () => goTo(i), clickable: true })}
                          >
                            {renderColumnName(k.column, t)}
                          </StyledDropdownOption>
                        );
                      })}
                    </StyledDropdownInner>
                    <DeleteWrapper alignItems="center" onClick={handleDeleteConfirm(data)}>
                      <Delete alignItems="center">
                        <IconDelete />
                      </Delete>
                      <Flex>
                        <Text>{t('jobtrack_job_card_delete_option_text')}</Text>
                      </Flex>
                    </DeleteWrapper>
                  </DropdownMenuItmes>
                </Dropdown>
              </ActionWrapper>
            </TabBox>
          </TabsWrapper>
        </>
      )}
    </CompanyLayout>
  );
};

export default withConfirm(DetailCompany);

const PodioSavedJob = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('SemiBold', '20', 'var(--dark-values-black)', '30px')};
`;

const LoadingContainer = styled(Flex)`
  & > div {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    background-color: transparent !important;
    position: relative !important;
    margin-top: 5px;
  }
  & picture img,
  & picture source {
    margin: 0px !important;
    width: 20px !important;
    height: 20px !important;
  }
  & > * {
    filter: brightness(0) invert(1) !important;
  }
`;

const StyledDropdownOption = styled(DropdownOption)`
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

const HeartFlex = styled(Flex)`
  gap: 10px;
`;

const Text = styled.div`
  ${({ theme }) => theme.jobTrack.renderFonts('Bold', '12', '#4c4c55', '1.5')};
`;

const Delete = styled(Flex)`
  height: 40px;
  padding-left: 12px;
`;

const DeleteWrapper = styled(Flex)`
  padding: 8px;
  border-top: 1px solid var(--grayscale-n100);
  gap: 8px;
  &:hover {
    background-color: var(--light-values-light-extra);
    color: var(--dark-values-black);
  }
  @media (min-width: 1920px) {
    border-top: none;
  }
`;

const Dropdown = styled(DropdownMenuItemWrapper)`
  top: 24px;
  right: -22px;
  left: unset;
  padding-top: 4px;
  display: none;
  ${({ addToWishlist }) =>
    addToWishlist &&
    css`
      right: unset;
      top: 41px;
      left: 0;
      @media (max-width: 767px) {
        width: 100%;
      }
    `}
  ${({ isApplyed, isMobileCompany }) =>
    isApplyed &&
    css`
      top: ${isMobileCompany ? '90px' : '41px'};
      right: 0px;
      left: ${isMobileCompany ? '0px' : 'unset'};
      @media (max-width: 767px) {
        width: 100%;
      }
    `}
`;

const StyledDropdownInner = styled(DropdownInner)`
  cursor: default;
  @media (min-width: 1920px) {
    display: none;
  }
`;

const StyledRoundedButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const StyledRoundedButton = styled(RoundedButton)`
  width: 100%;
  justify-content: center;
  @media (min-width: 768px) {
    width: fit-content;
  }
  &:hover {
    ${DropdownMenuItmes} {
      display: flex;
    }
    ${Dropdown} {
      display: flex;
    }
  }
  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  ${({ nobackground }) =>
    nobackground &&
    css`
      background-color: transparent;
      color: #1688fe;
      border: 1px solid #1688fe;
      width: 40% !important;
      &:hover {
        background-color: #f0f8ff;
      }
      &:active {
        background-color: #e6f2ff;
      }
    `}
  &:disabled {
    background-color: #f0f0f0;
    color: #b0b0b0;
    border-color: #d0d0d0;
    pointer-events: none;
  }
`;

const ActionWrapper = styled(Flex)`
  flex-shrink: 0;
  display: flex;
  position: relative;
  ${({ isButton }) =>
    isButton &&
    css`
      flex-wrap: wrap;
      margin-top: 8px;
      flex-direction: column;
      gap: 12px;
      @media (min-width: 768px) {
        flex-direction: row;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      > * {
        margin-left: 0;
        &:first-child {
          margin-left: 0;
        }
      }
    `}
`;

const TabsWrapper = styled(Flex)`
  margin: 16px 0 12px;
  flex-wrap: wrap;
  @media (max-width: 370px) {
    width: 100%;
  }
`;

const TabBox = styled(Flex)`
  height: 40px;
  padding: 10px 12px;
  background-color: var(--grayscale-n50);
  svg {
    margin-right: 4px;
  }
  ${({ theme }) => theme.jobTrack.renderFonts('Medium', '13', 'var(--grayscale-n500)', '1.54')};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
  ${({ loading }) =>
    loading &&
    css`
      cursor: wait;
    `}
  ${({ selected }) =>
    selected &&
    css`
      background-color: #0050c7;
      color: var(--light-values-white);
    `}
  ${({ active }) =>
    active &&
    css`
      background-color: #0050c7;
      color: var(--light-values-white);
      position: relative;
      ~ div {
        padding-left: 24px;
      }
      &::after {
        content: '';
        position: absolute;
        display: block;
        right: -12px;
        background-color: transparent;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 21px 0 21px 12px;
        border-color: transparent transparent transparent #0050c7;
      }
    `}
  ${({ MoreOptions, open }) =>
    MoreOptions &&
    css`
      cursor: pointer;
      padding-left: 20px !important;
      padding: 0 20px;
      background-color: var(--light-values-light-extra);
      &:hover {
        background-color: var(--grayscale-n100);
      }
      ${open &&
      css`
        ${DropdownMenuItmes} {
          display: flex;
        }
        ${Dropdown} {
          display: flex;
        }
      `}
      @media (max-width: 370px) {
        width: 60px !important;
        padding: 0 !important;
        flex-grow: 0 !important;
      }
    `}
  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  @media (max-width: 370px) {
    flex-grow: 1;
  }
`;
