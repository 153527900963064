import { isEmpty } from 'lodash';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Flex from 'imports/core/ui/atoms/Flex';
import { generateJobSearchTemplate, markResumeFinish } from 'imports/job-tracking/api/api';
import { useRouter } from 'next/router';
import useFeedStore from 'zustand/feedStore';
import useToaster from '/imports/core/api/useToaster';
import AddNewResume from '/imports/job-tracking/ui/components/AddNewResume';
import ResumeListItem from '/imports/job-tracking/ui/components/ResumeListItem';
import { getSearchTemplateName } from 'lib/helpers';

const removedResumes = ['berlin'];

const ResumesList = ({ variant, createNew, data, refetch }) => {
  const [markedAsFinished, setMarkedAsFinished] = useState([]);
  const { toast } = useToaster();
  const { token, builder_id } = nookies.get({});
  const [showDefault, setShowDefault] = useState(false);
  const [disableSendResume, setDisableSendResume] = useState(false);
  const { feeds } = useFeedStore();
  const [openAccordionId, setOpenAccordionId] = useState();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      refetch();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, refetch]);

  const groupResumes = (resumes) => {
    const grouped = {
      templates: {},
      defaults: [],
    };

    const sortedResume = resumes
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .filter((r) => !removedResumes.includes(r.settings.template));

    sortedResume.forEach((resume) => {
      if (resume.agentSearchId) {
        if (!grouped.templates[resume.agentSearchId]) {
          grouped.templates[resume.agentSearchId] = {
            searchTemplate: [],
            jobTemplate: [],
          };
        }
        if (resume.agentJobId) {
          grouped.templates[resume.agentSearchId].jobTemplate.push(resume);
        } else {
          grouped.templates[resume.agentSearchId].searchTemplate.push(resume);
        }
      } else {
        if (!resume?.agentJobId && !resume?.agentSearchId) grouped.defaults.push(resume);
      }
    });

    return grouped;
  };

  const handleAccordion = (resumeId) => () => {
    if (openAccordionId !== resumeId) setOpenAccordionId(resumeId);
    else setOpenAccordionId((prev) => (prev === resumeId ? null : resumeId));
  };

  const handleGenerateSAResume = (feedName) => async () => {
    setDisableSendResume(true);
    const baseFeed = feeds?.find((k) => k.feedName === feedName);
    const payload = {
      builderId: builder_id,
      searchId: baseFeed?.feedId,
      templateName: feedName,
    };
    try {
      await generateJobSearchTemplate(payload, token);
      refetch();
      toast({ text: 'Resume Generated SuccessFully' });
    } catch (e) {
      toast({ text: e.message || 'Something went wrong, please try again later', error: true });
    } finally {
      setDisableSendResume(false);
    }
  };

  const handleShowDefaultTemplates = () => setShowDefault(true);

  const groupedResumes = groupResumes(data.listResumes) || {};
  const v1Resumes = data?.listResumes?.filter((data) => data?.saVersion === 'v1');
  const v2Resumes = data?.listResumes?.filter((data) => data?.saVersion === 'v2');

  useEffect(() => {
    let markedResumesArray = [];
    if (v1Resumes?.length > 0) {
      const v1MarkedResumes = v1Resumes?.find((h) => h.saDetails?.isMarkFinished);
      if (!!v1MarkedResumes) {
        markedResumesArray.push('v1');
      }
    }
    if (v2Resumes?.length > 0) {
      const v2MarkedResumes = v2Resumes?.find((h) => h.saDetails?.isMarkFinished);
      if (!!v2MarkedResumes) {
        markedResumesArray.push('v2');
      }
    }
    setMarkedAsFinished(markedResumesArray);
  }, []);

  const handleMarkResumes = (version) => async () => {
    if (markedAsFinished.includes(version)) return;
    const { agent_client_id } = nookies.get({});
    setDisableSendResume(true);
    const resumes = version === 'v1' ? v1Resumes : v2Resumes;
    const extractResumesId = resumes?.map((resume) => resume.id) || [];
    const payload = {
      resumeIds: extractResumesId,
      builderId: agent_client_id,
      version: version,
    };
    await markResumeFinish(payload, token);
    setMarkedAsFinished([...markedAsFinished, version]);
    setDisableSendResume(false);
  };

  const getAllResumesLanguage = useCallback((resumes) => {
    let languages = [];
    resumes.forEach((resume) => {
      languages.push(resume.settings.language);
    });
    return languages;
  }, []);

  return (
    <Fragment>
      {v1Resumes?.length > 0 && !markedAsFinished?.includes('v2') && (
        <GapFlex fullWidth direction="column" $withMarginTop>
          <Title>Resume V1 </Title>
          <Wrapper fullWidth direction="column" $noMarginTop>
            <Flex fullWidth justifyContent="space-between">
              <Title onClick={handleAccordion('resumev1')} $withCursor $open={openAccordionId === 'resumev1'}>
                Resumes V1
              </Title>
              <Title
                $withCursor
                $diffHover
                $disable={disableSendResume}
                onClick={handleMarkResumes('v1')}
                $finished={markedAsFinished?.includes('v1')}
              >
                {markedAsFinished?.includes('v1')
                  ? v2Resumes?.length === 0
                    ? 'Finished (Available for the extension)'
                    : 'Finished'
                  : 'Mark as finished'}
              </Title>
            </Flex>
            <Accordion open={openAccordionId === 'resumev1'}>
              <Flex fullWidth direction="column">
                <ItemsGrid>
                  {v1Resumes?.length > 0 ? (
                    v1Resumes?.map((resume) => (
                      <GapFlex key={resume.id} $fullwidth direction="column">
                        <Title>Resume Language: {resume.settings?.language.toUpperCase()}</Title>
                        <ResumeListItem
                          resume={resume}
                          variant={variant}
                          disableEdit={v2Resumes?.length > 0}
                          langauges={getAllResumesLanguage(v1Resumes)}
                        />
                      </GapFlex>
                    ))
                  ) : (
                    <Title>No Resumes Created</Title>
                  )}
                </ItemsGrid>
              </Flex>
            </Accordion>
          </Wrapper>
        </GapFlex>
      )}
      {v2Resumes?.length > 0 && (
        <GapFlex fullWidth direction="column" $withMarginTop>
          <Title>Resume V2 </Title>
          <Wrapper fullWidth direction="column" $noMarginTop>
            <Flex fullWidth justifyContent="space-between">
              <Title onClick={handleAccordion('resumev2')} $withCursor $open={openAccordionId === 'resumev2'}>
                Resumes V2
              </Title>
              <Title
                $withCursor={!markedAsFinished?.includes('v2')}
                $diffHover
                $disable={disableSendResume}
                onClick={handleMarkResumes('v2')}
                $finished={markedAsFinished?.includes('v2')}
              >
                {markedAsFinished?.includes('v2') ? 'Finished (Available for the extension)' : 'Mark as finished'}
              </Title>
            </Flex>
            <Accordion open={openAccordionId === 'resumev2'}>
              <Flex fullWidth direction="column">
                <ItemsGrid>
                  {v2Resumes?.length > 0 ? (
                    v2Resumes?.map((resume) => (
                      <GapFlex key={resume.id} $fullwidth direction="column">
                        <Title>Resume Language: {resume.settings?.language.toUpperCase()}</Title>
                        <ResumeListItem resume={resume} variant={variant} />
                      </GapFlex>
                    ))
                  ) : (
                    <Title>No resumes created</Title>
                  )}
                </ItemsGrid>
              </Flex>
            </Accordion>
          </Wrapper>
        </GapFlex>
      )}
      {!isEmpty(groupedResumes?.templates) && (
        <GapFlex fullWidth direction="column" $withMarginTop>
          <Title>Job Search Templates (CTB integration soon)</Title>
          <Wrapper fullWidth direction="column" $noMarginTop $noBorder>
            {Object.keys(groupedResumes.templates).map((templateId) => (
              <Wrapper key={templateId} fullWidth direction="column" $noMarginTop>
                <Flex fullWidth justifyContent="space-between">
                  <Title $open={openAccordionId === templateId} $withCursor onClick={handleAccordion(templateId)}>
                    SEARCH RESUMES: {getSearchTemplateName(groupedResumes.templates[templateId].searchTemplate)}
                  </Title>
                  {/* {(v1Resumes?.length !== 0 || v2Resumes?.length !== 0) && (
                    <GapFlex>
                      <Title
                        $diffHover
                        $withCursor
                        onClick={handleGenerateSAResume(
                          getSearchTemplateName(groupedResumes.templates[templateId].searchTemplate),
                        )}
                        $disable={disableSendResume || (v1Resumes?.length === 0 && v2Resumes?.length === 0)}
                      >
                        {v2Resumes?.length === 0
                          ? 'Generate Search Template From V1'
                          : 'Generate Search Template From V2'}
                      </Title>
                    </GapFlex>
                  )} */}
                </Flex>
                <Accordion open={openAccordionId === templateId}>
                  <Flex fullWidth direction="column">
                    <ItemsGrid>
                      {groupedResumes.templates[templateId].searchTemplate.map((resume) => (
                        <GapFlex key={resume.id} $fullwidth direction="column">
                          <Title>Resume Language: {resume.settings?.language.toUpperCase()}</Title>
                          <ResumeListItem resume={resume} variant={variant} />
                        </GapFlex>
                      ))}
                    </ItemsGrid>
                  </Flex>
                </Accordion>
              </Wrapper>
            ))}
          </Wrapper>
        </GapFlex>
      )}
      {!showDefault && <CTA onClick={handleShowDefaultTemplates}>Show Admin QA Template</CTA>}
      {showDefault && (
        <>
          <Title $withMarginTop={isEmpty(groupedResumes?.templates)}>Admin QA</Title>
          <Wrapper fullWidth direction="column">
            <Title onClick={handleAccordion('defaults')} $open={openAccordionId === 'defaults'} $withCursor>
              Admin QA Templates
            </Title>
            <Accordion open={openAccordionId === 'defaults'}>
              <Flex>
                <ItemsGrid>
                  <AddNewResume createNew={createNew} />
                  {groupedResumes &&
                    groupedResumes['defaults']
                      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                      .map((resume) => <ResumeListItem key={resume.id} resume={resume} variant={variant} showIcons />)}
                </ItemsGrid>
              </Flex>
            </Accordion>
          </Wrapper>
        </>
      )}
    </Fragment>
  );
};

const Accordion = ({ children, open }) => {
  return (
    <AccordionBody $open={open} direction="column" $fullwidth>
      {children}
    </AccordionBody>
  );
};

ResumesList.propTypes = {
  createNew: PropTypes.func,
};

ResumesList.displayName = 'ResumesList';

const CTA = styled(Flex)`
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  background-color: #0099da;
  text-transform: uppercase;
  color: var(--light-values-white);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 15px;
  line-height: 24px;
  margin: 25px 0;
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      background-color: var(--grayscale-n300);
    `}
  &:hover {
    background-color: #007eb7;
  }

  @media (max-width: 450px) {
    max-width: unset;
  }
`;

const GapFlex = styled(Flex)`
  gap: 8px;
  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 16px;
    `}
  ${({ $withBorderTop }) =>
    $withBorderTop &&
    css`
      border-top: 1px solid var(--grayscale-n200);
      padding-top: 24px;
    `}
  ${({ $withMarginBottom }) =>
    $withMarginBottom &&
    css`
      margin-bottom: 24px;
    `}
`;

const Title = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 24px;
  ${({ $open }) =>
    $open &&
    css`
      padding-bottom: 24px;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
  ${({ $jobTemplate }) =>
    $jobTemplate &&
    css`
      color: #019ade;
    `}
  ${({ $diffHover }) =>
    $diffHover &&
    css`
      &:hover {
        color: #019ade;
      }
    `}
  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      color: var(--grayscale-n400);
    `}
    ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 16px;
    `}
  ${({ $red }) =>
    $red &&
    css`
      color: var(--additional-colors-red);
    `}
  ${({ $finished }) =>
    $finished &&
    css`
      color: var(--additional-colors-green);
      pointer-events: none;
    `}
  ${({ $noVisible }) =>
    $noVisible &&
    css`
      visibility: hidden;
    `}
`;

const Wrapper = styled(Flex)`
  background-color: var(--light-values-white);
  border: 1px solid var(--neutral-values-gray-light);
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      margin-top: 0;
    `}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
      padding: 0;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
`;

const AccordionBody = styled(Flex)`
  overflow-x: auto;
  width: 100%;
  max-height: ${({ $open }) => ($open ? '2000px' : '0')};
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease,
    padding 0.3s ease,
    border-top 0.3s ease;
  gap: 24px;
  padding: ${({ $open }) => ($open ? '24px 0' : '0')};
  border-top: ${({ $open }) => ($open ? '1px solid var(--grayscale-n200)' : 'none')};
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
`;

const ItemsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
  overflow-y: auto;
  flex-wrap: noWrap;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export default ResumesList;
