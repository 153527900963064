import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';

import { CloseIcon } from '/imports/generator/ui/assets';
import Flex from '/imports/core/ui/atoms/Flex';
import HeadingAtom from '/imports/core/ui/atoms/Heading';
import ModalButton from '/imports/job-tracking/ui/atoms/ModalButton';
import OneLineCss from '/imports/job-tracking/ui/atoms/OneLineCss';
import TextAtom from '/imports/core/ui/atoms/Text';
import useIntl from '/imports/core/api/useIntl';

const Confirm = ({
  className,
  confirmText,
  cancelText,
  onCancel,
  onConfirm,
  title,
  text,
  onClose,
  isCanceling,
  cancellingText,
  isConfirming,
  confirmingText,
  showClose = false,
}) => {
  const { t } = useIntl();

  if (!document.getElementById('modal')) return <p>Error creating modal</p>;
  return ReactDOM.createPortal(
    <Fragment>
      <ModalBackdrop onClick={onClose} />
      <CSSTransition appear in classNames="modal" timeout={600}>
        <ModalContainer className={className}>
          <BodyWrapper direction="column">
            {showClose && (
              <HeaderButton onClick={onClose}>
                {t('cancel')}
                <CloseIcon color="#0199db" />
              </HeaderButton>
            )}
            {title && <Heading>{title}</Heading>}
            {text && <Text>{text}</Text>}
          </BodyWrapper>
          <ActionWrapper>
            <ModalButton onClick={onCancel} cancel disabled={isCanceling || isConfirming}>
              {isCanceling ? cancellingText || t('cancelling') : cancelText || t('cancel')}
            </ModalButton>
            <ModalButton outline onClick={onConfirm} disabled={isConfirming} isConfirm>
              {isConfirming ? confirmingText || t('confirming') : confirmText || t('confirm')}
            </ModalButton>
          </ActionWrapper>
        </ModalContainer>
      </CSSTransition>
    </Fragment>,
    document.getElementById('modal'),
  );
};

const ModalBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: ${(p) => p.theme.zIndex.confirmBg};
  background: var(--transparents-opacity-12);
  box-shadow: 0 12px 48px 0 rgba(20, 20, 31, 0.24);
`;

const BodyWrapper = styled(Flex)`
  padding: 32px;
`;

const Heading = styled((p) => <HeadingAtom as="h2" {...p} />)`
  font-family: Gilroy SemiBold;
  font-size: 22px;
  line-height: 1.45;
  color: var(--black);
  text-transform: capitalize;
  margin: 0;
  ${OneLineCss}
  word-break: break-word;
  display: inline-block;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const Text = styled(TextAtom)`
  font-family: Gilroy Medium;
  font-size: 15px;
  line-height: 1.47;
  color: #1d2c43;
  margin: 10px 0 0 0;
  ${OneLineCss}
  -webkit-line-clamp: 3;
  word-break: break-word;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ActionWrapper = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  padding: 20px 32px;
  border-top: 1px solid #ecf1f7;
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

const ModalContainer = styled.div`
  font-family: ${({ theme }) => theme.font.family.websiteMedium};
  position: fixed;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0 16px 48px 0 rgba(29, 44, 67, 0.2);
  background: var(--light-values-white);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(p) => p.theme.zIndex.confirm};
  width: 90%;
  @media (min-width: 768px) {
    width: 420px;
  }
  &.modal-appear {
    transform: translate(-50%, -150%);
  }

  &.modal-appear-active {
    transform: translate(-50%, -50%);
  }

  &.modal-exit {
    transform: translate(-50%, -50%);
  }

  &.modal-exit-active {
    transform: translate(-50%, -150%);
  }
`;

const HeaderButton = styled.a`
  font-family: TTCommons;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  color: #0199db;
  cursor: pointer;
  margin-left: auto;
  letter-spacing: -0.5px;
  position: fixed;
  top: 12px;
  right: 16px;

  svg {
    transform: scale(1);
    margin-left: 10px;
    top: 2px;
    position: relative;
  }
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
      left: 16px;
      right: auto;

      svg {
        margin-left: 0;
        margin-right: 10px;
      }
    `}
`;

export default Confirm;
