import React from 'react';

const CloseLinkedinModalIcon = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292894C13.3166 -0.0976312 12.6834 -0.0976313 12.2929 0.292894L7 5.58579L1.70711 0.292914C1.31658 -0.0976094 0.683417 -0.0976089 0.292893 0.292915C-0.0976319 0.68344 -0.0976306 1.31661 0.292895 1.70713L5.58579 7.00001L0.292897 12.2929C-0.0976259 12.6834 -0.0976256 13.3166 0.292898 13.7071C0.683423 14.0977 1.31659 14.0977 1.70711 13.7071L7 8.41422L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
        fill="black"
      />
    </svg>
  );
};

export default CloseLinkedinModalIcon;
