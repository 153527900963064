import React from 'react';
import styled, { css } from 'styled-components';

const ToggleSwitch = ({ toggle, handleToggle, templateId }) => {
  const isChecked = Array.isArray(toggle) ? toggle.includes(templateId) : toggle;

  const onChange = () => {
    if (Array.isArray(toggle)) {
      handleToggle(templateId);
    } else {
      handleToggle();
    }
  };

  return (
    <Div>
      <Input
        id={`react-switch-${templateId || 'checkedOnly'}`}
        type="checkbox"
        checked={isChecked}
        onChange={onChange}
      />
      <Label htmlFor={`react-switch-${templateId || 'checkedOnly'}`} checked={isChecked}>
        <Span toggle={isChecked} />
      </Label>
    </Div>
  );
};

export default ToggleSwitch;
const Div = styled.div`
  padding: 10px;
`;
const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 29px;
  background: var(--grayscale-n400);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  ${({ checked }) =>
    checked &&
    css`
      background-color: #019ade;
    `}
`;

const Span = styled.span`
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 21px;
  height: 21px;
  border-radius: 45px;
  transition: 0.2s;
  background: var(--light-values-white);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${({ toggle }) =>
    toggle &&
    css`
      left: calc(100% - 2px);
      transform: translateX(-100%);
    `}
`;
