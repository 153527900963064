import React from 'react';

const IconDelete = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0a1 1 0 0 0-1 1h-.172C5.018 1 3.281 1.72 2 3h12a6.828 6.828 0 0 0-4.828-2H9a1 1 0 0 0-1-1z"
        fill="#4C4C55"
        id="svg-dynamic-fill"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 4H2l.847 10.166A2 2 0 0 0 4.84 16h6.32a2 2 0 0 0 1.993-1.834L14 4zm-2.174 2H4.174l.666 8h6.32l.666-8z"
        fill="#4C4C55"
        id="svg-dynamic-fill"
      />
    </svg>
  );
};

export default IconDelete;
