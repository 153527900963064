import React, { useState, Fragment, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import nookies from 'nookies';
import styled, { css } from 'styled-components';

import ResumeListItem from '/imports/job-tracking/ui/components/ResumeListItem';
import Flex from 'imports/core/ui/atoms/Flex';
import { getJobTemplateName } from 'lib/helpers';
import { useRouter } from 'next/router';
import { getJobTemplateIds } from 'imports/job-tracking/api/helper';
import { markResumeAsChecked } from 'imports/job-tracking/api/api';
import ToggleSwitch from '/imports/job-tracking/ui/components/ToggleSwitch';
import SavedJobSkeleton from 'imports/job-tracking/ui/skeleton/SavedJobSkeleton';

const removedResumes = ['berlin'];

const SavedJobsList = ({ variant, data, refetch }) => {
  const [toggle, setToggle] = useState([]);
  const [notCheckOnlyToggle, setNotCheckOnlyToggle] = useState(false);
  const { agent_client_id, token } = nookies.get({});
  const [openAccordionId, setOpenAccordionId] = useState();
  const [groupedResumes, setGroupedResumes] = useState();
  const [isReady, setIsReady] = useState(false);
  const router = useRouter();

  const handleUpdatingData = () => {
    const { grouped: serverGroupedResumes } = data ? groupResumes(data.listResumes, false) : { grouped: {} };
    setGroupedResumes(serverGroupedResumes);
  };

  useEffect(() => {
    const handleRouteChange = async () => {
      await refetch();
      handleUpdatingData();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, refetch]);

  const groupResumes = (resumes, notCheckedOnly = false) => {
    const grouped = {
      templates: {},
    };
    const checkedResumes = [];
    const sortedResume = resumes
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      .filter((r) => !removedResumes.includes(r.settings.template));

    sortedResume.forEach((resume) => {
      if (resume.agentJobId) {
        if (!grouped.templates[resume.agentJobId]) {
          grouped.templates[resume.agentJobId] = {
            jobTemplate: [],
          };
        }
        if (notCheckedOnly) {
          if (!resume?.isJobReviewed) {
            grouped.templates[resume.agentJobId].jobTemplate.push(resume);
          }
        } else {
          grouped.templates[resume.agentJobId].jobTemplate.push(resume);
        }
        if (resume?.isJobReviewed) {
          checkedResumes.push(resume?.agentJobId);
        }
      }
    });
    return { grouped, checkedResumes };
  };

  useEffect(() => {
    if (toggle?.length === 0 && !isReady && data) {
      const { grouped: serverGroupedResumes, checkedResumes } = data
        ? groupResumes(data.listResumes, false)
        : { grouped: {}, checkedResumes: [] };
      setGroupedResumes(serverGroupedResumes);
      setToggle(checkedResumes);

      const templateKeys = Object.keys(serverGroupedResumes?.templates);
      if (templateKeys && templateKeys?.length > 0) {
        setOpenAccordionId(templateKeys[0]);
      }
      setIsReady(true);
    }
  }, [data]);

  const handleAccordion = (resumeId) => () => {
    if (openAccordionId !== resumeId) setOpenAccordionId(resumeId);
    else setOpenAccordionId((prev) => (prev === resumeId ? null : resumeId));
  };

  const handleToggle = async (templateId) => {
    const isToggledOn = !toggle.includes(templateId);
    setToggle((prevToggle) => {
      if (prevToggle.includes(templateId)) {
        return prevToggle.filter((id) => id !== templateId);
      } else {
        return [...prevToggle, templateId];
      }
    });
    const data = groupedResumes?.templates[templateId];
    const allResumesId = getJobTemplateIds(data);
    const payload = {
      agentClientId: agent_client_id,
      resumeList: allResumesId,
      updateStatus: isToggledOn,
    };
    await markResumeAsChecked(payload, token);
    await refetch();
  };

  if (!isReady || !data) {
    return <SavedJobSkeleton />;
  }

  const handleNotCheckedOnly = () => {
    setNotCheckOnlyToggle(!notCheckOnlyToggle);
    const { grouped: checkedOnlyResumes } = data
      ? groupResumes(data.listResumes, !notCheckOnlyToggle)
      : { grouped: {}, checkedResumes: [] };
    setGroupedResumes(checkedOnlyResumes);
  };

  return (
    <Fragment>
      <Flex alignItems="center">
        <Title $big>Not Checked Only</Title>
        <ToggleSwitch toggle={notCheckOnlyToggle} handleToggle={handleNotCheckedOnly} />
      </Flex>
      <GapFlex fullWidth direction="column" $withMarginTop>
        <Wrapper fullWidth direction="column" $noMarginTop $noBorder>
          {Object.keys(groupedResumes.templates).map((templateId) => (
            <Wrapper key={templateId} fullWidth direction="column" $noMarginTop>
              <Flex fullWidth justifyContent="space-between" alignItems="center">
                <Title $open={openAccordionId === templateId} $withCursor onClick={handleAccordion(templateId)}>
                  {getJobTemplateName(groupedResumes.templates[templateId].jobTemplate)}
                </Title>
                <Flex alignItems="center">
                  <Title>Resume Checked</Title>
                  <ToggleSwitch templateId={templateId} toggle={toggle} handleToggle={handleToggle} />
                </Flex>
              </Flex>
              <Accordion open={openAccordionId === templateId}>
                <Flex fullWidth direction="column">
                  <ItemsGrid>
                    {groupedResumes.templates[templateId].jobTemplate.map((resume) => (
                      <GapFlex key={resume.id} $fullwidth direction="column">
                        <Title>Resume Language: {resume.settings?.language.toUpperCase()}</Title>
                        <ResumeListItem resume={resume} variant={variant} />
                      </GapFlex>
                    ))}
                  </ItemsGrid>
                </Flex>
              </Accordion>
            </Wrapper>
          ))}
        </Wrapper>
      </GapFlex>
    </Fragment>
  );
};

const Accordion = ({ children, open }) => {
  return (
    <AccordionBody $open={open} direction="column" $fullwidth>
      {children}
    </AccordionBody>
  );
};

SavedJobsList.propTypes = {
  createNew: PropTypes.func,
};

SavedJobsList.displayName = 'SavedJobsList';
const GapFlex = styled(Flex)`
  gap: 8px;
  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 16px;
    `}
  ${({ $withBorderTop }) =>
    $withBorderTop &&
    css`
      border-top: 1px solid var(--grayscale-n200);
      padding-top: 24px;
    `}
  ${({ $withMarginBottom }) =>
    $withMarginBottom &&
    css`
      margin-bottom: 24px;
    `}
`;

const Title = styled.div`
  color: var(--dark-values-black);
  font-family: ${({ theme }) => theme.font.family.websiteSemiBold};
  font-size: 16px;
  line-height: 24px;
  ${({ $open }) =>
    $open &&
    css`
      padding-bottom: 24px;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
  ${({ $jobTemplate }) =>
    $jobTemplate &&
    css`
      color: #019ade;
    `}
  ${({ $diffHover }) =>
    $diffHover &&
    css`
      &:hover {
        color: #019ade;
      }
    `}
  ${({ $disable }) =>
    $disable &&
    css`
      pointer-events: none;
      color: var(--grayscale-n400);
    `}
  ${({ $big }) =>
    $big &&
    css`
      font-size: 24px;
    `}
`;

const Wrapper = styled(Flex)`
  background-color: var(--light-values-white);
  border: 1px solid var(--neutral-values-gray-light);
  padding: 16px;
  margin: 16px 0;
  border-radius: 8px;
  ${({ $noMarginTop }) =>
    $noMarginTop &&
    css`
      margin-top: 0;
    `}
  ${({ $noBorder }) =>
    $noBorder &&
    css`
      border: none;
      padding: 0;
    `}
  ${({ $withCursor }) =>
    $withCursor &&
    css`
      cursor: pointer;
    `}
`;

const AccordionBody = styled(Flex)`
  overflow-x: auto;
  width: 100%;
  max-height: ${({ $open }) => ($open ? '2000px' : '0')};
  opacity: ${({ $open }) => ($open ? '1' : '0')};
  transition:
    max-height 0.3s ease,
    opacity 0.3s ease,
    padding 0.3s ease,
    border-top 0.3s ease;
  gap: 24px;
  padding: ${({ $open }) => ($open ? '24px 0' : '0')};
  border-top: ${({ $open }) => ($open ? '1px solid var(--grayscale-n200)' : 'none')};
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
`;

const ItemsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
  overflow-y: auto;
  flex-wrap: noWrap;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 10px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 4px;
  }
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 992px) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`;

export default SavedJobsList;
