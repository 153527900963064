import cloneDeep from 'lodash/cloneDeep';
import { findIndex } from 'lodash';

export const setFeeds = (state, action) => {
  return { ...state, feedList: action.payload };
};

export const addToFeeds = (state, action) => {
  const feedList = cloneDeep(state.feedList);
  feedList.unshift(action.payload);
  return { ...state, feedList };
};

export const updateToFeeds = (state, action) => {
  const data = cloneDeep(state);
  const activeFeedIndex = data.feedList.findIndex((k) => k.feedId === action.payload.feedId);
  if (activeFeedIndex !== -1) data.feedList[activeFeedIndex] = action.payload;
  return { ...data };
};

export const deleteFeed = (state, action) => {
  let feedList = cloneDeep(state.feedList);
  feedList = feedList.filter((k) => k.feedId !== action.payload);
  return { ...state, feedList };
};

export const renameFeedName = (state, action) => {
  const data = cloneDeep(state);
  data.activeFeed.feedName = action.payload.feedName;
  let activeFeed = data.feedList.find((k) => k.feedId === action.payload.feedId);
  activeFeed.feedName = action.payload.feedName;
  return { ...data };
};

export const setIcon = (state, action) => {
  const feedList = cloneDeep(state.feedList);
  feedList.find((k) => k.feedId === action.payload.feedId).icon = action.payload.icon;
  return { ...state, feedList };
};

export const setActiveFeed = (state, action) => {
  state.activeFeed = action.payload;
  return { ...state };
};
