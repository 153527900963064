import { withSearch } from 'imports/job-tracking/api/context/search.context';
import { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import useTracking from 'imports/core/hooks/useTracking';
import { useSearchStore } from 'zustand/SearchStore';
import { useRouter } from 'next/router';
import { getCountry } from '/imports/checkout/api/utils';
import searchApi from 'imports/job-tracking/api/searchApi';
import { cloneDeep, filter } from 'lodash';
import useFeedStore from 'zustand/feedStore';
import { darken } from 'polished';

export const useFilterData = (token, locale) => {
  const { setactiveCard, activeCardData, jobsData, setLoading } = useSearchStore();
  const {
    state: { pageToken },
  } = withSearch();
  const { trackEvent } = useTracking();
  const router = useRouter();
  const isSearchPage = useMemo(() => router.query.template === 'search', [router.query.template]);
  const { useGetSearchJob, useGetIndeedJobDetails } = searchApi();
  const { mutate } = useGetSearchJob();
  const { mutate: IndeedJobDataMutate } = useGetIndeedJobDetails();
  const [isNextTriggerParam, setIsNextTriggerParam] = useState(false);
  const [firstTime, setFirstTime] = useState();
  const [mutateSuccess, setMutateSuccess] = useState(false);
  const lastJobId = useRef(null);
  const { activeFeeds } = useFeedStore();
  const getFilterData = useCallback(
    async (
      isNextTrigger = false,
      disableLoad,
      isFirstTime,
      searchText,
      cityText,
      filterData,
      orderBy,
      regionCode,
      savedFeedName = '',
      index,
    ) => {
      if (isNextTrigger !== isNextTriggerParam) {
        setIsNextTriggerParam(isNextTrigger);
      }
      if (isFirstTime !== firstTime) {
        setFirstTime(isFirstTime);
      }
      if (isSearchPage && !searchText) {
        searchText = savedFeedName ? savedFeedName : '';
      }
      if (!regionCode) {
        regionCode = getCountry();
      }

      if (!disableLoad) setLoading(true);
      const data = {
        title: searchText,
        where: cityText,
        languageCode: locale,
        regionCode: regionCode,
      };
      if (isNextTrigger) {
        data.title = searchText ? searchText : savedFeedName;
        data.pageToken = pageToken;
      }
      if (orderBy) {
        data.orderBy = orderBy;
      }
      const feedId = activeFeeds?.feedId || router.query.id;
      if (feedId) {
        data.feedId = feedId;
      }
      if (filterData) {
        const {
          datePost = '',
          remote = '',
          educationLevel = [],
          salary = '',
          jobType = [],
          skills = [],
          location = [],
          company = [],
          partner = '',
          contractType = '',
          isManualCheck = false,
          allowedJobProviders = [],
          language = '',
          distance,
          apiProvider = 'search-v3',
          industries = [],
          isEasyApply = '',
        } = filterData;
        if (datePost) data.postedDate = datePost;
        if (language) data.language = language;
        if (remote) data.remote = remote;
        if (educationLevel.length != 0) data.education = educationLevel;
        if (salary) data.salary = salary;
        if (jobType.length != 0) data.jobType = jobType;
        if (skills.length != 0) data.skills = skills;
        if (location.length != 0) data.location = location;
        if (company.length != 0) data.company = company;
        if (partner) data.partner = partner;
        if (contractType) data.contractType = contractType;
        if (isManualCheck) data.isManualCheck = true;
        if (allowedJobProviders.length !== 0) data.allowedJobProviders = allowedJobProviders;
        if (distance > 0) data.distance = distance;
        if (apiProvider) data.apiProvider = apiProvider;
        if (industries.length > 0) data.industries = industries.join(',');
        if (isEasyApply.length > 0) data.isEasyApply = isEasyApply[0];
      }
      router.query.template === 'search' && trackEvent('job_search', data);
      const v2Payload = cloneDeep(data);
      v2Payload.nextPage = isFirstTime ? null : true;

      if (data.title !== '' && data.where !== '')
        mutate({
          data: v2Payload,
          index,
          isFirstTime,
        });
      setMutateSuccess(true);
    },
    [locale, token, trackEvent, mutate],
  );

  useEffect(() => {
    let activeData1;
    if (isNextTriggerParam && !firstTime) {
      activeData1 = activeCardData;
    } else {
      if (mutateSuccess && jobsData?.[0]?.apiProvider === 'search-v3' && firstTime) {
        activeData1 = { ...jobsData?.[0] };
        IndeedJobDataMutate({
          jobId: activeData1?.id,
          isActiveCardDetail: true,
          company: activeData1.company,
          jobData: activeData1,
        });
      } else {
        activeData1 = jobsData?.[0];
      }
    }
    setactiveCard(activeData1);
  }, [jobsData, isNextTriggerParam, firstTime, mutateSuccess]);

  return { getFilterData };
};
