import styled from 'styled-components';

import Flex from '/imports/core/ui/atoms/Flex';

const AddNewBoardButton = ({ addBoard }) => (
  <Wrapper justifyContent="center" alignItems="center" onClick={addBoard}>
    <AddButton justifyContent="center" alignItems="center">
      <svg width="14" height="14" viewBox="0 0 17 17" fill="none">
        <g clipPath="url(#a)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.375 1.5a1 1 0 1 0-2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0v-6h6a1 1 0 1 0 0-2h-6v-6z"
            fill="#4F5E75"
            className="styled-fill"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" transform="translate(.375 .5)" d="M0 0h16v16H0z" />
          </clipPath>
        </defs>
      </svg>
    </AddButton>
  </Wrapper>
);

export default AddNewBoardButton;

const Wrapper = styled(Flex)`
  border-radius: 12px;
  box-shadow: 0 3px 16px 0 rgba(29, 44, 67, 0.04);
  border: dashed 2px rgba(29, 44, 67, 0.16);
  height: 108px;
  min-width: 100%;
  max-width: 100%;
  cursor: pointer;
  .styled-fill {
    fill: var(--dark-values-dark-regular);
  }
  &:hover {
    .styled-fill {
      fill: var(--dark-values-black);
    }
  }
  @media (min-width: 768px) {
    min-width: 314px;
    max-width: 314px;
    height: 228px;
  }
  @media (min-width: 992px) {
    min-width: 201px;
    max-width: 201px;
  }
  @media (min-width: 1200px) {
    min-width: 206px;
    max-width: 206px;
  }
  @media (min-width: 1440px) {
    min-width: 232px;
    max-width: 232px;
  }
`;

const AddButton = styled(Flex)`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--light-values-light-extra);
  &:hover {
    background-color: var(--light-values-light-medium);
  }
`;
