import styled from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import AddNewResume from '/imports/job-tracking/ui/components/AddNewResume';
import Flex from '/imports/core/ui/atoms/Flex';
import { PDF_LOADER } from '/imports/generator/api/constants';

const JobTrackListsSkeleton = () => (
  <SkeletonTheme color="var(--light-values-white)" highlightColor="#f0f0f2">
    <ItemsGrid>
      <AddNewResume />
      {[...Array(4)].map((el, i) => (
        <ResumeListWrapper direction="column" key={i}>
          <ResumeHero src={PDF_LOADER} />
          <Footer />
        </ResumeListWrapper>
      ))}
    </ItemsGrid>
  </SkeletonTheme>
);

export default JobTrackListsSkeleton;

const Footer = styled.div`
  height: 61.79px;
  background: var(--light-values-white);
  width: 100%;
`;

const ItemsGrid = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    justify-content: space-around;
  }
  margin-top: 32px;
  flex-wrap: noWrap;
  justify-content: flex-start;
`;

const ResumeListWrapper = styled(Flex)`
  box-shadow: 0 3px 16px 0 rgba(29, 44, 67, 0.04);
  background-color: #f5f8fc;
  height: 397px;
  min-width: 100%;
  max-width: 100%;
  margin-right: 25px;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 768px) {
    min-width: 236.8px;
    max-width: 236.8px;
  }
`;

const ResumeHero = styled.img`
  height: 335.20277078085644px;
  width: 100%;
`;
