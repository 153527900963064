import React from 'react';
import styled from 'styled-components';

import Cards from '/imports/job-tracking/ui/components/Cards';
import Flex from '/imports/core/ui/atoms/Flex';

const SearchSekeleton = () => {
  return (
    <StyledFlex fullWidth>
      <Cards isSkeleton />
      <Box fullWidth />
    </StyledFlex>
  );
};

export default SearchSekeleton;

const Box = styled(Flex)`
  min-height: 100vh;
  height: 100%;
  border: 1px solid var(--grayscale-n300);
  border-radius: 8px;
  display: block;
`;

const StyledFlex = styled(Flex)`
  gap: 8px;
  @media (min-width: 992px) {
    gap: 16px;
  }
  @media (min-width: 1200px) {
    gap: 24px;
  }
`;
