import styled from 'styled-components';

const DropdownMenuItmes = styled.div`
  overflow: hidden auto;
  display: flex;
  z-index: 2;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(29, 44, 67, 0.12);
  width: 100%;
  background-color: var(--light-values-white);
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgb(229, 228, 234);
    border-radius: 1px;
  }
`;

export default DropdownMenuItmes;
