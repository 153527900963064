import { SkeletonTheme } from 'react-loading-skeleton';
import { PDF_LOADER } from '/imports/generator/api/constants';
import styled from 'styled-components';
import Flex from 'imports/core/ui/atoms/Flex';

const SkeletonJobs = () => {
  return (
    <SkeletonTheme highlightColor="#dbdbdb">
      <ResumeSection $fullWidth>
        {[...Array(3)].map((_, i) => (
          <ResumeListWrapper direction="column" key={i}>
            <ResumeHero src={PDF_LOADER} />
          </ResumeListWrapper>
        ))}
      </ResumeSection>
    </SkeletonTheme>
  );
};

export default SkeletonJobs;

const ResumeSection = styled(Flex)`
  padding: 16px 0;
  margin-top: 30px;
  overflow-x: hidden;
`;

const ResumeListWrapper = styled(Flex)`
  box-shadow: 0 3px 16px 0 rgba(29, 44, 67, 0.04);
  border: 1px solid #dbdbdb;
  min-width: 100%;
  max-width: 100%;
  margin-right: 25px;
  border-radius: 8px;
  overflow: hidden;
  @media (min-width: 768px) {
    min-width: 236.8px;
    max-width: 236.8px;
  }
`;

const ResumeHero = styled.img`
  height: 335.20277078085644px;
  width: 100%;
  background-color: #f5f8fc;
`;
